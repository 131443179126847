const actions = {
	UPDATE_CREDIT: 'UPDATE_CREDIT',
	SET_PAYMENT_URL: 'SET_PAYMENT_URL',

	FETCH_PAYMENT_HISTORY : 'FETCH_PAYMENT_HISTORY',
	SET_PAYMENT_HISTORY : 'SET_PAYMENT_HISTORY',

	FETCH_CREDIT : 'FETCH_CREDIT',
	SET_CREDIT : 'SET_CREDIT',
	FAILURE : 'FAILURE',
	failure : () => ({
		type : actions.FAILURE,
	}),

	updateCredit: (payload) => ({
	    type: actions.UPDATE_CREDIT,
	    payload: payload
	}),

	setPaymentHistory: (payload) => ({
		type: actions.SET_PAYMENT_HISTORY,
		payload: payload
	}),

	setCredit: (payload) => ({
		type: actions.SET_CREDIT,
		payload: payload
	})
};

export default actions;
