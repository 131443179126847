import FirebaseHelper from '../../helpers/firebase';

const {  firebase } = FirebaseHelper;

const routeMap = {
	allowed_creatable_user_types : {
		0 : firebase.firestore().doc('user_types/1'),
		1 : firebase.firestore().doc('user_types/4'),
	},
	can_change_quotum: 1,
	menu : [
		{
			heading: "Main navigation",
			translate: "sidebar.heading.HEADER"
		},
		{
			icon: "icon-speedometer",
			name: "Dashboard",
			path: "/dashboard",
			translate: "sidebar.nav.DASHBOARD"
		},
		{
			heading: "Gebruikers",
			translate: "sidebar.heading.USERS"
		},
		{
			icon : "icon-people",
			name: "Gebruikers",
			submenu: [
				{
					name: "Klant toevoegen",
					path: "/add_user"
				},
				{
					name: "Gebruikers overzicht",
					path: "/user_overview"
				}
			]
		},
		
		{
			heading: "Financieel",
			translate: "sidebar.heading.FINANCIAL",
		},
		{
			icon : "icon-wallet",
			name : "Financieel",
			translate : "sidebar.nav.FINANCIAL",
			submenu : [
				{
					name : "Saldo toevoegen",
					path : "/finance",
					usageType : "small"
				},
				{
					name : "Betalingen",
					path : "/payment_history",
					usageType : "small"
				},
				{
					name : "Facturatie",
					path : "/invoice",
					usageType : "large"
				}
			]
		},
		{
			heading: "Tanken",
			translate: "sidebar.heading.FUELING"
		},
		{
			icon : "icon-speedometer",
			name : "Tanken",
			translate : "sidebar.nav.FUELING",
			submenu : [
				{
					name : "Tankbeurt starten",
					path : "/fueling_locations_overview"
				},
				{
					name : "Mijn afname",
					path : "/fueling_history"
				},
				{
					name : "Afname gebruikers",
					path : "/fueling_history/sub"
				}
			]
		}
	],
	routes : [
		{
			component : "Dashboard",
			pageKey : "Base",
			route : "/dashboard"
		},
		{
			component : "PrivacyStatement",
			pageKey : "Base",
			route : "/privacy-verklaring"
		},
		{
			component : "TermsAndConditions",
			pageKey : "Base",
			route : "/algemene-voorwaarden"
		},
		{
			component : "User",
			pageKey : "Base",
			route : "/user"
		},
		{
			component : "AddUser",
			pageKey : "Base",
			route : "/add_user"
		},
		{
			component : "UserOverview",
			pageKey : "Base",
			route : "/user_overview"
		},
		{
			component : "Saldo",
			pageKey : "Base",
			route : "/finance",
			usageType : "small"
		},
		{
			component : "PaymentHistory",
			pageKey : "Base",
			route : "/payment_history",
			usageType : "small"
		},
		{
			component : "AddUser",
			pageKey : "Base",
			route : "/edit_user/:user_index"
		},
		{
			component : "FuelingHistory",
			pageKey : "Base",
			route : "/fueling_history"
		},
		{
			component : "FuelingHistory",
			pageKey : "Base",
			route : "/fueling_history/:type"
		},
		{
			component : "FuelingLocationOverview",
			pageKey : "Base",
			route : "/fueling_locations_overview"
		},
		{
			component : "Fueling",
			pageKey : "Base",
			route : "/fueling/:location_id"
		},
				{
			component : "Invoice",
			pageKey : "Base",
			route : "/invoice"
		} 
	]
}
export default routeMap