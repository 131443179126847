export const errorCodes = {
	'auth/user-not-found': {
		type: 'error',
		message: 'Gebruiker niet gevonden'
	},
	'auth/app-deleted': {
		type: 'error',
		message: 'De applicatie bestaat niet'
	},
	'auth/app-not-authorized': {
		type: 'error',
		message: 'U heeft te weinig rechten'
	},
	'auth/argument-error': {
		type: 'error',
		message: 'U dient alle gegevens in te vullen'
	},
	'auth/invalid-api-key': {
		type: 'error',
		message: 'De applicatie heeft geen verbinding met de database'
	},
	'auth/invalid-user-token': {
		type: 'error',
		message: 'Uw login is ongeldig'
	},
	'auth/network-request-failed': {
		type: 'error',
		message: 'U heeft geen verbinding'
	},
	'auth/operation-not-allowed': {
		type: 'error',
		message: 'Dit is niet toegestaan'
	},
	'auth/requires-recent-login': {
		type: 'error',
		message: 'U dient opnieuw in te loggen'
	},
	'auth/too-many-requests': {
		type: 'error',
		message: 'U heeft te vaak geprobeerd in te loggen'
	},
	'auth/unauthorized-domain': {
		type: 'error',
		message: 'Domein niet geautoriseerd'
	},
	'auth/user-disabled': {
		type: 'error',
		message: 'Deze gebruiker is uitgeschakeld'
	},
	'auth/user-token-expired': {
		type: 'error',
		message: 'Uw login is verlopen'
	},
	'auth/web-storage-unsupported': {
		type: 'error',
		message: 'Uw browser ondersteund geen lokale opslag'
	},
	'auth/wrong-password': {
		type: 'error',
		message: 'Dit wachtwoord is niet geldig'
	},
	'auth/weak-password': {
		type: 'error',
		message: 'Dit wachtwoord is te zwak'
	},
	'auth/invalid-email': {
		type: 'error',
		message: 'Dit is een ongeldig e-mailadres'
	},
	'The email address is invalid.' : {
		type: 'error',
		message: 'Dit is een ongeldig e-mailadres'
	},
	'Invalid phone number: The string supplied did not seem to be a phone number.': {
		type : 'error',
		message: 'Dit is geen geldig telefoonnummer'
	},
	'Invalid phone number: Missing or invalid default region.' : {
		type : 'error',
		message: 'Dit is geen geldig telefoonnummer'
	},
	'A password must be a string with at least 6 characters.': {
		type: 'error',
		message: 'Dit wachtwoord is te zwak'
	},
	'The email address is already in use by another account.': {
		type: 'error',
		message: 'Dit e-mailadres is al in gebruik'
	},
	'The phone number is already in use by another account.' : {
		type : 'error',
		message : 'Dit telefoonnummer is al in gebruik'
	},
	'INVALID_PHONE_NUMBER : TOO_SHORT' : {
		type : 'error',
		message : 'Er is geen geldig telefoonnummer opgegeven, mist u een cijfer?'
	}
};