import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import userSagas from './user/saga';
import userTypeSagas from './userType/saga';
import creditSagas from './credit/saga';
import locationSagas from './location/saga';
import fuelingSagas from './fueling/saga';
export default function* rootSaga(getState) {
	yield all([
		authSagas(),
		userSagas(),
		userTypeSagas(),
		creditSagas(),
		locationSagas(),
		fuelingSagas()
	]);
}
