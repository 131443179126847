import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ContentWrapper from '../../Layout/ContentWrapper';
import {
    Card,
    CardBody,
    FormGroup,
    Input,TabContent, TabPane,  Nav, NavItem, Label
     } from 'reactstrap';
import classnames from 'classnames';
import { connect } from 'react-redux';
import userAction from '../../../redux/user/actions';
import { toast } from 'react-toastify';
import Dropzone from 'react-dropzone';

const { updateUser, removeLogo } = userAction;
 
class User extends Component {

	componentWillMount() {
		this.setState({
			user_profile: {
				...this.props.user,
				password: false,
				password_repeat: false,
                files: [],
                readOnly: true
            },
            activeTab: '1'
		})


    }
    
    toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		if(prevProps.user !== this.props.user) {
			this.setState({
				user_profile: {
					password: false,
					password_repeat: false,
					...this.props.user
				},
			})
        }
        
        if(prevState.activeTab !== this.state.activeTab) {
            if(this.state.activeTab == '1') {
                this.setState({
                    user_profile: {
                        password: false,
                        password_repeat: false,
                        ...this.props.user
                    }
                });
            }
        }
	}

    onSubmit = e => {
        
        e.preventDefault();	

        const hasErrors = this._findAllErrors();

        if(!hasErrors) {
        	this.props.updateUser(this.state.user_profile);
        }else{
        	toast('Let alstublieft op de foutmeldingen.', {
		        type: 'error',
		        position: 'top-right'
		    })
        }        
    }

    _findAllErrors = () => {
    	
    	if(
    		!this._hasError('firstname', false, true) && 
    		!this._hasError('lastname', false, true) && 
    		!this._hasError('email', false, true) && 
    		!this._hasError('phone_number', false, true) && 
    		!this._hasError('displayName', false, true) && 
    		!this._hasError('password', 'password_repeat', true) 
    	) {
            
            if(this.state.user_profile.tancode) {
                if(this.state.user_profile.is_valid_type == '') {
                    toast('U bent vergeten de geldigheid van de code aan te geven.', {
                        type: 'error',
                        position: 'top-right'
                    })
                    return true;
                }
            }




    	}else{
    		return true;
        }
        
        

    }

    _updateField = (field, event) => {

        let value = event.target.value;

        if (event.target.type === 'tancode') {
			if (value.length != 6) {
				value = false;
				toast('Tancode wordt genegeerd, veld heeft een incorrecte waarde.', {
					type: 'error',
					position: 'top-right'
				})
			}
        }
        
    	this.setState({
    		user_profile: {
    			...this.state.user_profile,
    			[field] : value
    		}
    	})
    }

    _hasError = (fieldName, sameAs = false, returnBool = false) => {


        if(sameAs !== false) {
        	
            let thisField = this.state.user_profile[fieldName];
            let matchField = this.state.user_profile[sameAs];

            if(thisField === false && matchField === false) {

            	if(!returnBool) {
            		return {
						display: 'none'
					}
            	}else{
            		return false;
            	}
            	
            }
            if(thisField && matchField) {
   				
                if(thisField !== matchField) {
                    if(!returnBool) {
	            		return {
							display: 'block'
						}
	            	}else{
	            		return true;
	            	}
                }
            }else{
                if(thisField === '' && matchField === '') {
              
                }else{
                    if(!returnBool) {
	            		return {
							display: 'block'
						}
	            	}else{
	            		return true;
	            	}
                }
               
            }
        }else{
        	if(!this.state.user_profile[fieldName]) {
        		if(!returnBool) {
            		return {
						display: 'block'
					}
            	}else{
            		return true;
            	}
        	}
        }

        if(!returnBool) {
    		return {
				display: 'none'
			}
    	}else{
    		return false;
    	}

    }

    onDrop = (files) => {


        let file = files[0];
        const reader = new FileReader();

        // -- register onload event
        reader.onload = (event) => {        
        
       		const tempUrl = URL.createObjectURL( file )
       		
          this.setState({
                user_profile: {
                    ...this.state.user_profile,
                    logoBlob : event.target.result,
                    logoName : file.name,
                    logoType : file.type,
                    tempUrl
                }
            })
        };

        // -- call onload event and get base64 string
        reader.readAsArrayBuffer(file);

 
    }

    _removeImage = (event) => {
        event.preventDefault();
        this.props.removeLogo();
    }

    _removeReadOnly() {
        if(this.state.readOnly)
            this.setState({readOnly: false})
    }

    render() {
    
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Gebruiker updaten
                        <small>{this.props.user ? this.props.user.displayName ? this.props.user.displayName : this.props.user.email : null}</small>
                        <small>{this.props.user.user_type_id}</small>
                    </div>
                </div>
                
                <div className="row">
                <div className="col-md-8">
                    <Card className="card-default">                       
                        <CardBody>                                    
                            <form onSubmit={this.onSubmit}  autoComplete="off">                                
                                <FormGroup>
                                    <label>Gebruikersnaam</label>
                                    <Input onChange={(event) => this._updateField('displayName', event)} type="text" placeholder="Gebruikersnaam" value={this.state.user_profile.displayName}/>
                                    <span style={this._hasError('displayName')} className="invalid-feedback">Veld is verplicht</span> 
                                </FormGroup>
                                <FormGroup>
                                    <label>Voornaam</label>
                                    <Input onChange={(event) => this._updateField('firstname', event)} type="text" placeholder="Voornaam" value={this.state.user_profile.firstname ? this.state.user_profile.firstname : ''}/>
                                    <span style={this._hasError('firstname')} className="invalid-feedback">Veld is verplicht</span> 
                                </FormGroup>
                                <FormGroup>
                                    <label>Achternaam</label>
                                    <Input  onChange={(event) => this._updateField('lastname', event)} type="text" placeholder="Achternaam" value={this.state.user_profile.lastname ? this.state.user_profile.lastname : ''}/>
                                    <span style={this._hasError('lastname')} className="invalid-feedback">Veld is verplicht</span> 
                                </FormGroup>
                                <FormGroup>
                                    <label>Bedrijf</label>
                                    <Input  onChange={(event) => this._updateField('company', event)} type="text" placeholder="Bedrijf" value={this.state.user_profile.company ? this.state.user_profile.company : ''}/>
                                </FormGroup>
                                <FormGroup>
                                    <label>Postcode</label>
                                    <Input  onChange={(event) => this._updateField('zipcode', event)} type="text" placeholder="Postcode" value={this.state.user_profile.zipcode ? this.state.user_profile.zipcode : ''}/>
                                </FormGroup>
                                <FormGroup>
                                    <label>Plaats</label>
                                    <Input  onChange={(event) => this._updateField('city', event)} type="text" placeholder="Plaats" value={this.state.user_profile.city ? this.state.user_profile.city : ''}/>
                                </FormGroup>
                                <FormGroup>
                                    <label>Telefoonnummer</label>
                                    <Input  onChange={(event) => this._updateField('phone_number', event)} type="text" placeholder="Telefoonnummer" value={this.state.user_profile.phone_number ? this.state.user_profile.phone_number : ''}/>
                                    <span style={this._hasError('phone_number')} className="invalid-feedback">Veld is verplicht</span> 
                                </FormGroup>
                                
                                {/* {this.props.user.user_type_id === 5 || this.props.user.user_type_id === 2 ? 
                                    <FormGroup>
                                        <label>TAN Code</label>
                                        <Input onChange={(event) => this._updateField('tancode', event)} type="number" minLength="6" maxLength="6" placeholder="Tancode" value={this.state.user_profile.tancode ? this.state.user_profile.tancode : ''}/>
                                    </FormGroup>
                                : null}   */}
                                <FormGroup>                                        
                                    <label>E-mail adres</label>
                                    <Input  readOnly={this.state.readOnly} onChange={(event) => this._updateField('email', event)} type="email" autoComplete="off" placeholder="E-mailadres" value={this.state.user_profile.email}/>
                                    <span style={this._hasError('email')} className="invalid-feedback">Veld is verplicht</span> 
                                </FormGroup>    
                                    <span>
                                        <FormGroup check >
                                            <Label check>
                                                <Input  onChange={(event) => this._updateField('update_password', event)} type="checkbox" checked={this.state.user_profile.update_password ? this.state.user_profile.update_password : false}/>{' '}
                                                Wachtwoord wijzigen
                                            </Label>
                                        </FormGroup>
                                        <br /> 
                                        </span> 
                                        {this.state.user_profile.update_password ?                                     
                                        <span>
                                        <FormGroup>
                                            <label>Wachtwoord</label>
                                            <Input readOnly={this.state.readOnly} onChange={(event) => this._updateField('password', event)} autoComplete="off" type="password" placeholder="Wachtwoord"/>
                                            <span style={this._hasError('password', 'password_repeat')} className="invalid-feedback">Wachtwoorden moeten gelijk zijn aan elkaar</span> 
                                        </FormGroup>
                                        <FormGroup>
                                            <label>Wachtwoord herhalen</label>
                                            <Input readOnly={this.state.readOnly} onChange={(event) => this._updateField('password_repeat', event)} autoComplete="off" type="password"  placeholder="Wachtwoord herhalen"/>
                                            <span style={this._hasError('password_repeat', 'password')} className="invalid-feedback">Wachtwoorden moeten gelijk zijn aan elkaar</span> 
                                        </FormGroup>
                                        </span>
                                        : null }
                                   
                                    <button className="btn btn-sm btn-secondary" type="submit">Opslaan</button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>           
                                   
                    <div className="col-md-4">
                        <Card className="card-default">
                            <CardBody>
                                <FormGroup>
                                    <label>Logo</label>
                                    <Dropzone className="card p-3" ref="dropzone" onDrop={this.onDrop} >
                                        <div className="text-center box-placeholder m-0">Try dropping some files here, or click to select files to upload.</div>     
                                        {this.state.user_profile.tempUrl ? <img  alt="Avatar" style={{marginTop: '25px', width: '100%'}} src={this.state.user_profile.tempUrl} /> : null }                                   
                                    </Dropzone>
                                    <button className="btn btn-sm btn-secondary" onClick={this.onSubmit} type="submit">Opslaan</button>
                                    { this.props.user.logo ? 
                                        <div>
                                    <label style={{maxWidth: '100%', float: 'left', clear: 'both'}}>Huidig logo</label>
                                    <img style={{maxWidth: '100%', float: 'left', clear: 'both', marginTop: '25px', marginBottom: '25px'}} alt={"User logo"} src={this.props.user.logo} />
                                    <button style={{maxWidth: '100%', float: 'left', clear: 'both'}} onClick={(event) => this._removeImage(event)}>Verwijderen</button>
                                    </div>
                                    : '' }
                                </FormGroup>
                            </CardBody>
                        </Card>
                        {/*<div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-left">
                                <em className="icon-globe fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 bg-purple-dark rounded-right">
                                <div className="h2 mt-0">{this.props.user.quota}</div>
                                <div className="text-uppercase">Huidig quotum</div>
                            </div>
                        </div>*/}
                    </div> 
                </div>                      
               
            </ContentWrapper>
            );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.user_profile ? state.User.user_profile : false,
        dbProfile: state.User.db_profile,
        userType: state.UserType
        
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        updateUser: (data) => dispatch(updateUser(data)),
        removeLogo: () => dispatch(removeLogo()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, false, {pure: false})(User))