import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ContentWrapper from '../../Layout/ContentWrapper';
import { NavLink } from 'react-router-dom';
import {
    Card,
    CardBody,
    FormGroup,
    Input,
    Label
     } from 'reactstrap';

import { connect } from 'react-redux';
import locationAction from '../../../redux/location/actions';
import { toast } from 'react-toastify';
import {hasError} from '../../../helpers/formHelper';
import Select from 'react-select';


const { addLocation, updateLocation } = locationAction;

class LocationForm extends Component {

    constructor(props) {

        super(props)
        this.state = {location: {locationType : false}}
    }

    componentDidMount() {
    	if(this.props.match.params.location_id) {
    		if(this.props.locations) {
    			const location = this.props.locations.find(item => item.id === this.props.match.params.location_id);
	    		this.setState({
	    			location
	    		})
    		}
    	}
    }

    componentDidUpdate() {
    	if(this.props.match.params.location_id) {
    		if(!this.state.location && this.props.locations.length) {
    			const location = this.props.locations.find(item => item.id === this.props.match.params.location_id);
	    		this.setState({
	    			location
	    		})
    		}
    	}
    }

    onSubmit = e => {
        e.preventDefault();	

        const hasErrors = this._findAllErrors();
      
        
        if(!hasErrors) {
        	if(this.state.location.id) {
        		this.props.updateLocation(this.state.location);
        	}else{
        		this.props.addLocation(this.state.location);
        	}
        	
        }else{
        	toast('Let alstublieft op de foutmeldingen.', {
		        type: 'error',
		        position: 'top-right'
		    })
        }    
    }

    _findAllErrors = () => {

    	if ( this.state.location ) {
			if( this.state.location.locationType.value === 'small' ) {
				if( hasError('price_per_volume', this.state.location, false, true) ) {
					return true;
				}
			}
		}


    	if(
    		!hasError('latitude', this.state.location, false, true) && 
    		!hasError('name', this.state.location, false, true) &&
    		!hasError('api_address', this.state.location, false, true)
    	) {
    		return false;
    	}else{



    		return true;
    	}

    }

    _updateField = (field, event) => {
    	let value = event.target.value;
    	if(event.target.type === 'checkbox') {
    		if(event.target.checked) {
    			value = true;
    		}else{
    			value = false;
    		}
    	}
    	this.setState({
    		location: {
    			...this.state.location,
    			[field] : value
    		}
        })
        
       
    }

    _handleSelectChange = (event) => {
        this.setState({
            location: {
                ...this.state.location,
                'locationType' : event
            }
        })
    }

    render() {
    	
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Locatie {this.state.location ? this.state.location.location_id ? 'updaten' : 'aanmaken' : 'aanmaken'}</div>
                </div>
              
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-default">

                            <CardBody>
                            <NavLink className="btn btn-sm btn-secondary" style={{marginBottom: '25px'}} to="/location_overview"><i className="fa fa-chevron-left"></i>&nbsp;Terug naar overzicht</NavLink>

                                <form onSubmit={this.onSubmit}>
                                
                                    <FormGroup>
                                        <label>Naam</label>
                                        <Input onChange={(event) => this._updateField('name', event)} type="text" placeholder="Naam" value={this.state.location ? this.state.location.name ? this.state.location.name : '' : ''}/>
                                    	<span style={hasError('name', this.state.location)} className="invalid-feedback">Veld is verplicht</span> 
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Plaatsnaam</label>
                                        <Input onChange={(event) => this._updateField('city', event)} type="text" placeholder="Plaatsnaam" value={this.state.location ? this.state.location.city ? this.state.location.city : '' : ''}/>
                                    </FormGroup>
                                   
                                     <FormGroup>
                                        <label>Latitude</label>
                                        <Input  onChange={(event) => this._updateField('latitude', event)} type="text" placeholder="Latitude" value={this.state.location ? this.state.location.latitude ? this.state.location.latitude : '' : ''}/>
                                    	<span style={hasError('latitude', this.state.location)} className="invalid-feedback">Veld is verplicht</span> 
                                    </FormGroup>
                                    <FormGroup>
                                        <label>Longitude</label>
                                        <Input  onChange={(event) => this._updateField('longitude', event)} type="text" placeholder="Longitude" value={this.state.location ? this.state.location.longitude ? this.state.location.longitude : '' : ''}/>
                                    	<span style={hasError('longitude', this.state.location)} className="invalid-feedback">Veld is verplicht</span> 
                                    </FormGroup>
                                    <FormGroup>
                                        <label>API Adres</label>
                                        <Input  onChange={(event) => this._updateField('api_address', event)} type="text" placeholder="API Adres" value={this.state.location ? this.state.location.api_address ? this.state.location.api_address : '' : ''}/>
                                    	<span style={hasError('api_address', this.state.location)} className="invalid-feedback">Veld is verplicht</span> 
                                    </FormGroup>
                                    <FormGroup>
                                    	<label>Punt voor</label>
                                    	<Select
	                                        name="multi-select-name"                                            
	                                        simpleValue={true}                                            
	                                        value={this.state.location ? this.state.location.locationType ? this.state.location.locationType : null : null}
	                                        onChange={this._handleSelectChange}
	                                        options={
	                                        	[
	                                        		{
	                                        			value: 'large', 
	                                        			label : 'Grootverbruik'
	                                        		},
	                                        		{
	                                        			value: 'small', 
	                                        			label : 'Kleinverbruik'
	                                        		}
	                                        	]
	                                        }
	                                    />
                                    </FormGroup>
                                    {this.state.location ? this.state.location.locationType.value === 'small' ? 
	                                    <FormGroup>
	                                        <label>Volumeprijs</label>
	                                        <Input onChange={(event) => this._updateField('price_per_volume', event)} type="number" min="0" step="0.0000001" placeholder="Volumeprijs" value={this.state.location ? this.state.location.price_per_volume ? this.state.location.price_per_volume : '0.0225' : '0.0225'}/>
	                                        <span style={hasError('price_per_volume', this.state.location)} className="invalid-feedback">Veld is verplicht</span> 
	                                    </FormGroup>
	                                : null : null }

                                    <FormGroup>
                                        <label>Opmerkingen</label>
                                        <Input onChange={(event) => this._updateField('notes', event)} type="textarea" placeholder="Opmerkingen" value={this.state.location ? this.state.location.notes ? this.state.location.notes : '' : ''}/>
                                    </FormGroup>


                                     <FormGroup check>
						              <Label check>
						                <Input  onChange={(event) => this._updateField('active', event)} type="checkbox" id="active-checkbox" checked={this.state.location ? this.state.location.active ? this.state.location.active : false : false}/>{' '}
						                Actief
						              </Label>
						            </FormGroup>
						            <br />
                                    <button className="btn btn-sm btn-secondary" type="submit">Opslaan</button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        locations : state.Location.locations,
        creatableUserTypes: state.Auth.allowed_creatable_user_types,
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        addLocation: (data) => dispatch(addLocation(data)),
        updateLocation: (data) => dispatch(updateLocation(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationForm))