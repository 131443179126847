import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import { toast } from 'react-toastify';
import { fetchData } from '../../helpers/fetch';
import { getClientPath, getToken } from '../../helpers/utility';
const { rsfFirestore,firebase } = FirebaseHelper;


export function* fetchLocations() {
	yield takeEvery(actions.FETCH_LOCATIONS, function*(action) {

		const snapshot = yield call(rsfFirestore.getCollection, 'locations');

		yield put({
			type: actions.PROCESS_LOCATIONS,
			payload: snapshot
		})

	})
}

export function* addLocation() {
	yield takeEvery(actions.LOCATION_ADD, function*(action) {
		toast('Locatie toegevoegd', {
	        type: 'success',
	        position: 'top-right'
	    })

	  	let location = {...action.payload};

	  	if ( !location.price_per_volume ) {
	  		location.price_per_volume = 0;
	  	}

	 

		yield call(rsfFirestore.addDocument, 'locations', location)
	});
}

export function* syncLocation() {
	yield takeEvery(actions.SYNC_LOCATION, function*(action) {

		yield put({
			type: actions.SYNC_ACTION_PENDING,
			payload: true
		})

		const user =  FirebaseHelper.auth().currentUser;
		action.payload.append('token', user.uid);

		const response = yield call(
			fetchData,  
			getClientPath() + '/location/sync',
			action.payload
		)

		if (response) {

			toast(response.notification, {
		        type: response.type,
		        position: 'top-right'
		    })

			if (response.type === 'error') {
				console.error(response.notification);
			}

			if (response.type === 'success') {
				
			}
		}

		yield put({
			type: actions.SYNC_ACTION_PENDING,
			payload: false
		})

	});
}

export function* getLocationStatus() {
	yield takeEvery(actions.GET_LOCATION_STATUS, function*(action) {

		yield put({
			type: actions.STATUS_POPUP_OPEN,
			payload: false
		})

		const user =  FirebaseHelper.auth().currentUser;
		action.payload.append('token', user.uid);

		const response = yield call(
			fetchData,  
			getClientPath() + '/location/status',
			action.payload
		)

		if (response) {
			yield put({
				type: actions.SET_LOCATION_STATUS,
				payload: response
			})

			yield put({
				type: actions.STATUS_POPUP_OPEN,
				payload: true
			})
		}
	});
}

export function* updateLocation() {
	yield takeEvery(actions.LOCATION_UPDATE, function*(action) {
		
		toast('Locatie geupdated', {
	        type: 'success',
	        position: 'top-right'
	    })

	    let location = {...action.payload};

	    if ( location.locationType.value === 'large' ) {
	    	location.price_per_volume = '0';
	    }

	    let formData = new FormData();
	    formData.append('token', FirebaseHelper.auth().currentUser.uid);
	    formData.append('firebase_location_id', action.payload.id);
	    formData.append('price_per_volume', location.price_per_volume);
	    formData.append('location_type', location.locationType.value);


	    yield call(
			fetchData, 
			getClientPath() + '/location/updatelocation',
			formData
		)



	    let documentValues = {
	    	latitude : location.latitude,
	    	longitude : location.longitude,
	    	name : location.name,
	    	api_address : location.api_address,
	    	notes : location.notes ? location.notes : null,
	    	city : location.city ? location.city : null,
	    	active : location.active ? location.active : false,
	    	locationType : location.locationType,
	    	locationTypeValue : location.locationType.value,
	    	price_per_volume : location.price_per_volume ? location.price_per_volume : 0,
	    }

	    console.log(documentValues);
	    
		yield call(rsfFirestore.updateDocument, 'locations/'+action.payload.id, documentValues)
		
	});
}

export function* processLocations() {
	yield takeEvery(actions.PROCESS_LOCATIONS, function*(action) {
		const snapshot = action.payload;
		const processedCollection = FirebaseHelper.processFireStoreCollection(snapshot);
		let locationCollection = [];

		for (let key in processedCollection) {
		    if (processedCollection.hasOwnProperty(key)) {

		 
		    	let item = {
		    		id : key,
		    		latitude: processedCollection[key]['latitude'],
		    		longitude: processedCollection[key]['longitude'],
		    		name : processedCollection[key]['name'],
		    		notes : processedCollection[key]['notes'],
		    		city : processedCollection[key]['city'],
		    		active : processedCollection[key]['active'],
		    		api_address : processedCollection[key]['api_address'],
		    		price_per_volume : processedCollection[key]['price_per_volume'],
		    		locationType: processedCollection[key]['locationType'],
		    		locationTypeValue : processedCollection[key]['locationTypeValue'],
		    		usage_type : processedCollection[key]['locationTypeValue']
		    	}

		    	locationCollection.push(item);
		        //console.log(key + " -> " + p[key]);
		    }
		}

		yield put({
			type: actions.SET_LOCATIONS,
			payload: locationCollection
		})
	})
}

export function* syncLocations() {
	
	const localUserData = getToken();

	const user_id = localUserData.get('userId');

	if ( user_id ) {
		
			yield fork(
	  	rsfFirestore.syncCollection,
	  	firebase.firestore().collection('locations'),
		{ successActionCreator: (snap) => actions.processLocations(snap), failureActionCreator : () => actions.failure()}
	  )	
	}

}

export function* failure() {
	yield takeEvery(actions.FAILURE, function*(action) {


	
	})
}


export default function* rootSaga() {
	yield all([
		fork(fetchLocations),	
		fork(processLocations),
		fork(syncLocations),
		fork(getLocationStatus),
		fork(syncLocation),
		fork(addLocation),
		fork(updateLocation),
		fork(failure)
	]);
}
