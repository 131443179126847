import { all, takeEvery, takeLatest, put, fork, call } from 'redux-saga/effects';
import { push } from 'react-router-redux';
import { getToken, clearToken, clearStorage } from '../../helpers/utility';
import actions from './actions';
import userActions from '../user/actions';
import creditActions from '../credit/actions';
import FirebaseHelper from '../../helpers/firebase';
import locationActions from '../location/actions';
import fuelingActions from '../fueling/actions';

import { toast } from 'react-toastify';
import _ from 'lodash/collection';

import SuperAdmin from '../../config/permissions/SuperAdmin';
import Client from '../../config/permissions/Client';
import Driver from '../../config/permissions/Driver';

const { rsf, rsfFirestore } = FirebaseHelper;

export function* loginRequest() {
	yield takeEvery(actions.LOGIN_REQUEST, function*(action) {
		yield put({
			type: actions.LOGIN_SUCCESS,
			user_id: action.payload.user.uid,
			user: action.payload.user
		});
	});
}

export function* loginSuccess() {
	yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
		yield localStorage.setItem('user_id', payload.user_id);

		yield put({
			type: actions.FETCH_ALL_DATA,
			payload: {
				user: payload.user,
				user_id: payload.user_id
			}
		})

	});
}


export function* loginError() {
	yield takeEvery(actions.LOGIN_ERROR, function*() {

	});
}

export function* logout() {
	yield takeEvery(actions.LOGOUT, function*() {
		clearToken();
		clearStorage();
		yield put(push('/'));
	});
}

function onAuthStateChanged() {
	return new Promise((resolve, reject) => {
		FirebaseHelper.auth().onAuthStateChanged((user) => {
			if (user) {
				resolve(user);
			} else {
				toast('U dient opnieuw in te loggen.', {
			        type: 'error',
			        position: 'top-right'
			    })
				reject(new Error('User check failed!'));
			}
		});
	});
}


export function* checkAuthorization() {


	yield takeEvery(actions.CHECK_AUTHORIZATION, function*() {
		const localUserData = getToken();
		const user_id = localUserData.get('userId');
		const userProfile = localUserData.get('userProfile');
		let isValid = false;
		if(userProfile) {
			const userObject = FirebaseHelper.getProfile(userProfile);
			isValid = FirebaseHelper.checkExpired(userObject);
		}


		if (isValid) {
			yield put({
				type: actions.LOGIN_SUCCESS,
				user_id,
				user_token: userProfile.stsTokenManager.accessToken
			});


			yield put({
				type: actions.FETCH_ALL_DATA,
				payload: {
					user: userProfile,
					user_id: user_id
				}
			})
		}

		try {
			yield call(onAuthStateChanged);
		}
		catch(error) {
			yield put({
				type: actions.LOGOUT
			})

			if(userProfile) {
				toast('U dient opnieuw in te loggen.', {
			        type: 'error',
			        position: 'top-right'
			    })
			}
		}

	});
}

export function* fetchAllData() {
	yield takeLatest(actions.FETCH_ALL_DATA, function*(payload) {


		let user = payload.payload.user;
		const user_id = payload.payload.user_id;
		let startApp = true;
		let fbUser = false;
		let ownUser = false;
		try {
			let ownUserSnapshot = yield call(rsf.firestore.getDocument, 'users/'+user_id);
			ownUser = ownUserSnapshot.data();
		} catch (e) {
			startApp = false;
		}

  		if ( startApp ) {
  			if(fbUser.parent_user) {
	  			let parentUserSnapshot = yield call(rsfFirestore.getCollection, fbUser.parent_user);
				let parentUser = parentUserSnapshot.data();

				if(parentUser.logo) {
					if(user) {
						user.logo = parentUser.logo;
					}

				}
			  }
		
			  yield put({
  				type : userActions.GET_SUB_USERS,
  				payload: {userId : user_id, user_type : ownUser.user_type_id}
  			})

  			yield put({
				type: userActions.SET_USER_PROFILE,
				payload: user
			})

			yield put({
				type: userActions.FETCH_USER_PROPERTIES,
				payload: user_id
			})

			yield put({
				type: creditActions.FETCH_PAYMENT_HISTORY,
				payload: user_id
			})

			yield put({
				type:actions.FETCH_ROUTES,
				user_id: user_id
			})

			yield put({
				type:actions.FETCH_ALLOWED_CREATABLE_USER_TYPES,
				user_id: user_id
			})

			yield put({
				type:actions.FETCH_USER_QUOTUM_PERMISSIONS,
				user_id: user_id
			})

			yield put({
				type: creditActions.FETCH_CREDIT,
				payload: user_id
			})

			yield put({
				type: locationActions.FETCH_LOCATIONS
			})

			yield put({
				type: fuelingActions.FETCH_FUELING_HISTORY
			})



  		} else {
  			yield put({
				type: actions.LOGOUT
			})
  		}



	});
}

export function* fetchRoutes() {

	yield takeEvery(actions.FETCH_ROUTES, function*(payload) {

		const snapshot = yield call(rsf.firestore.getDocument, 'users/'+payload.user_id);
  		const user = snapshot.data();
  		const userTypePermissionsSnapshot =  yield call(rsf.firestore.getCollection, user.user_type_permissions);
  		const routes = userTypePermissionsSnapshot.data().routes;
  		const menu = userTypePermissionsSnapshot.data().menu;

  		yield put({type:actions.SET_ROUTES, payload: routes});
  		yield put({type:actions.SET_MENU, payload: menu});

	});
}

export function* fetchUserQuotumPermissions() {

	yield takeEvery(actions.FETCH_USER_QUOTUM_PERMISSIONS, function*(payload) {

		const snapshot = yield call(rsf.firestore.getDocument, 'users/'+payload.user_id);
  		const user = snapshot.data();
  		const userTypePermissionsSnapshot =  yield call(rsf.firestore.getCollection, user.user_type_permissions);
  		const userTypePermissions = userTypePermissionsSnapshot.data();

  		if(userTypePermissions.can_change_quotum) {
  			yield put({type:actions.SET_CAN_CHANGE_QUOTUM, payload: true});
  		}

	});
}

export function* fetchAllowedCreatableUserTypes() {

	yield takeEvery(actions.FETCH_ALLOWED_CREATABLE_USER_TYPES, function*(payload) {

		const snapshot = yield call(rsf.firestore.getDocument, 'users/'+payload.user_id);
  		const user = snapshot.data();


  		// -- Fetch user type permission snapshot
  		const userTypePermissionsSnapshot =  yield call(rsf.firestore.getCollection, user.user_type_permissions);



  		// -- Fetch the allowed creatable user types
  		const allowedCreatableUserTypes = userTypePermissionsSnapshot.data().allowed_creatable_user_types;

  		let allowedTypes = {};
  		for(let i in allowedCreatableUserTypes) {
  			if(allowedCreatableUserTypes.hasOwnProperty(i)) {
  				const userType =  yield call(rsf.firestore.getCollection, allowedCreatableUserTypes[i]);
  				allowedTypes = {
  					...allowedTypes,
  					[userType.data().id] : userType.data()
  				}
  			}
  		}

  		yield put({type:actions.SET_ALLOWED_CREATABLE_USER_TYPES, payload: allowedTypes});

	});
}

export function* checkUrl() {
	yield takeEvery(actions.CHECK_URL, function*(payload) {

		// -- fetch uid and get routes
		const localUserData = getToken();
		const user_id = localUserData.get('userId');

		const snapshot = yield call(rsf.firestore.getDocument, 'users/'+user_id);
  		const user = snapshot.data();


  		if ( user !== undefined ) {
  			const userTypePermissionsSnapshot =  yield call(rsf.firestore.getCollection, user.user_type_permissions);
	  		const routes = userTypePermissionsSnapshot.data().routes;


	  		// -- fetch route from array
	  		let validUrl = _.find(routes, function(r) { return r.route === payload.payload });

	  		if(payload.payload === '/fueling-success/:location_id/' || payload.payload === '/fueling-success/:location_id/:fueling_id') {
	  			validUrl = true;
	  		}


	  		if(payload.payload === '/algemene-voorwaarden' || payload.payload === 'privacy-verklaring') {
	  			validUrl = true;
	  		}

	  		if(!validUrl) {

	  			yield put({
					type: actions.LOGOUT
				})
				toast('De gekozen URL bestaat niet. U dient opnieuw in te loggen.', {
				        type: 'error',
				        position: 'top-right'
				    })
	  		}
  		}



  		// -- URL not in routes, then logout with nice message

	});
}

export function* resetPermissions() {
	yield takeEvery(actions.RESET_PERMISSIONS, function*(payload) {

	    let routeMap = false;
	    if(payload.payload === 1) {
	    	routeMap = Client;
	    }

	    if(payload.payload === 4) {
	    	routeMap = Driver;
	    }

	    if(payload.payload === 5) {
	    	routeMap = SuperAdmin;
	    }

		//yield call(rsfFirestore.setDocument, 'user_type_permissions/5', SuperAdmin)

	    if(routeMap) {
	    	yield call(rsfFirestore.setDocument, 'user_type_permissions/'+payload.payload, routeMap)
	    }


	})
}

export function* failure() {
	yield takeEvery(actions.FAILURE, function*(action) {



	})
}


export default function* rootSaga() {
	yield all([
		fork(checkAuthorization),
		fork(checkUrl),
		fork(fetchRoutes),
		fork(fetchAllowedCreatableUserTypes),
		fork(loginRequest),
		fork(loginSuccess),
		fork(loginError),
		fork(logout),
		fork(fetchAllData),
		fork(fetchUserQuotumPermissions),
		fork(resetPermissions),
		fork(failure)
	]);
}
