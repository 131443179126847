import actions from './actions';

const initState = { 
	fuelingLocations: [],
	fuelingActionPending: false,
	pinPopupVisible: false,
	invoiceWorking : false,
	invoice_file_link : false,
	fuelingHistory: {
		self : [],
		all : []
	}
};

export default function fuelingReducer(state = initState, action) {
	
	switch (action.type) {
		case actions.SET_FUELING_LOCATIONS:
			return { 
				...state, 
				fuelingLocations: action.payload
			};	
		case actions.SET_INVOICE_WORKING:
			return {
				...state,
				invoiceWorking : action.payload
			}
		case actions.SET_INVOICE_FILE_LINK : 
			return {
				...state,
				invoice_file_link : action.payload
			}
		case actions.SET_FUELING_ACTION_PENDING:
			return {
				...state,
				fuelingActionPending: action.payload
			}
		case actions.SET_PIN_POPUP_VISIBLE:
			return {
				...state,
				pinPopupVisible: action.payload
			}
		case actions.SET_FUELING_HISTORY:
			return {
				...state,
				fuelingHistory : {
					...state.fuelingHistory,
					[action.payload.type] : {
						...state.fuelingHistory[action.payload.type],
						...action.payload.data
					}
				}
			}
		case 'LOGOUT':
			return initState;
		default:
			return state;
	}
}
