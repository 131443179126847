import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import  actions  from '../../redux/auth/actions';

const { checkUrl } = actions;

class RouteWrap extends Component {
	
	
	componentDidUpdate(prevProps) {
		if(prevProps.isLoggedIn === true) {
			this.props._checkUrl(this.props.match.path);
		}
	}

	render() {
		const Component = this.props.component;
		return (
			<div className="route__wrapper">
				<Component />
			</div>
		)
			
	    
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.user_token !== null ? true : false,
        routes: state.Auth.routes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        _checkUrl: url => dispatch(checkUrl(url)),       
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteWrap))

