import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import { NavLink, withRouter } from 'react-router-dom';
import { Collapse, Badge } from 'reactstrap';
import SidebarRun from './Sidebar.run';
import SidebarUserBlock from './SidebarUserBlock';
import {connect} from 'react-redux';

/** Component to display headings on sidebar */
const SidebarItemHeader = ({item}) => (
    <li className="nav-heading">
        <span><Trans i18nKey={item.translate}>{item.heading}</Trans></span>
    </li>
)

/** Normal items for the sidebar */
const SidebarItem = ({item, isActive}) => (

    <li className={ isActive ? 'active' : '' }>
        <NavLink to={item.path} title={item.name}>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </NavLink>
    </li>
)

/** Build a sub menu with items inside and attach collapse behavior */
const SidebarSubItem = ({item, isActive, handler, children, isOpen}) => (
    <li className={ isActive ? 'active' : '' }>
        <div className="nav-item" onClick={ handler }>
            {item.label && <Badge tag="div" className="float-right" color={item.label.color}>{item.label.value}</Badge>}
            {item.icon && <em className={item.icon}></em>}
            <span><Trans i18nKey={item.translate}>{item.name}</Trans></span>
        </div>
        <Collapse isOpen={ isOpen }>
            <ul id={item.path} className="sidebar-nav sidebar-subnav">
                { children }
            </ul>
        </Collapse>
    </li>
)

/** Component used to display a header on menu when using collapsed/hover mode */
const SidebarSubHeader = ({item}) => (
    <li className="sidebar-subnav-header">{item.name}</li>
)

class Sidebar extends Component {

    state = {
        collapse: {}
    }

    constructor(props) {
        super(props)
        this.Menu = [];
    }

    componentDidMount() {
        // pass navigator to access router api
        SidebarRun(this.navigator.bind(this));
        // prepare the flags to handle menu collapsed states
        if(this.props.menu)
            this.buildCollapseList(this.props.menu)
    }


    /** prepare initial state of collapse menus. Doesnt allow same route names */
    buildCollapseList = (Menu) => {
        let collapse = {};
        Menu
            .filter(({heading}) => !heading)
            .forEach(({name, path, submenu}) => {
                collapse[name] = this.routeActive(submenu ? submenu.map(({path})=>path) : path)
            })
        this.setState({collapse});
    }

    navigator(route) {
        this.props.history.push(route);
    }

    routeActive(paths, checkExact = false) {
        paths = Array.isArray(paths) ? paths : [paths];
        if ( checkExact ) {

        	return paths[0] === this.props.location.pathname;
        } else {
        	return paths.some(p => this.props.location.pathname.indexOf(p) > -1)
        }
        
        
    }

    toggleItemCollapse(stateName) {
        for (let c in this.state.collapse) {
            if (this.state.collapse[c] === true && c !== stateName)
                this.setState({
                    collapse: {
                        [c]: false
                    }
                });
        }
        this.setState({
            collapse: {
                [stateName]: !this.state.collapse[stateName]
            }
        });
    }

    getSubRoutes = item => item.submenu.map(({path}) => path)

    /** map menu config to string to determine what elemetn to render */
    itemType = item => {
        if (item.heading) return 'heading';
        if (!item.submenu) return 'menu';
        if (item.submenu) return 'submenu';
    }

    _mapSubItem = (subitem, i) => {
    	if ( subitem.usageType ) {
    		if ( subitem.usageType !== this.props.user.usage_type_value) {
    			return null;
    		}
    	}
		return  <SidebarItem key={i} item={subitem} isActive={this.routeActive(subitem.path, true)} />
    }
    

    render() {

    	let style = {};

    	if(this.props.fuelingActionPending || this.props.pinPopupVisible) {
    		style = {
    			zIndex: 0
    		}
    	}
    	
        return (
            <aside className='aside-container' style={style}>
                { /* START Sidebar (left) */ }
                <div className="aside-inner">
                    <nav data-sidebar-anyclick-close="" className="sidebar">
                        { /* START sidebar nav */ }
                        <ul className="sidebar-nav">
                            { /* START user info */ }
                            <li className="has-user-block">
                                <SidebarUserBlock/>
                            </li>
                            { /* END user info */ }

                            { /* Iterates over all sidebar items */ }
                                {
                                this.props.menu ? 
                                    this.props.menu.map((item, i) => {
                                        // heading
                                        if(this.itemType(item) === 'heading'){
                                            if ( item.usageType ) {
									    		if ( item.usageType !== this.props.user.usage_type_value) {
									    			return null;
									    		}
									    	}
                                            return (
                                                <SidebarItemHeader item={item} key={i} />
                                            )
                                        }else {
                                            if(this.itemType(item) === 'menu'){
                                            	if ( item.usageType ) {
										    		if ( item.usageType !== this.props.user.usage_type_value) {
										    			return null;
										    		}
										    	}
                                                return (
                                                    <SidebarItem isActive={this.routeActive(item.path)} item={item} key={i} />
                                                )
                                            }
                                            if(this.itemType(item) === 'submenu') {

                                            	if ( item.usageType ) {
										    		if ( item.usageType !== this.props.user.usage_type_value) {
										    			return null;
										    		}
										    	}
                                                return [
                                                    <SidebarSubItem item={item} isOpen={this.state.collapse[item.name]} handler={ this.toggleItemCollapse.bind(this, item.name) } isActive={this.routeActive(this.getSubRoutes(item))} key={i}>
                                                        <SidebarSubHeader item={item} key={i}/>
                                                        {
                                                            item.submenu.map(this._mapSubItem)
                                                        }
                                                    </SidebarSubItem>
                                                ]
                                            }
                                        }
                                        return null; // unrecognized item
                                    })
                                : 
                                    null
                                }
                            
                        </ul>
                        { /* END sidebar nav */ }
                    </nav>
                </div>
                { /* END Sidebar (left) */ }
            </aside>
        );
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
    	user : state.User.user_profile ? state.User.user_profile : false,
        isLoggedIn: state.Auth.user_token !== null ? true : false,
        menu: state.Auth.menu
    }
}


export default withNamespaces('translations')(withRouter(connect(mapStateToProps)(Sidebar)));
