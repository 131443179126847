import actions from './actions';

const initState = { user_token: null, user_id: null, allowed_creatable_user_types: {}, can_change_quotum: false };

export default function authReducer(state = initState, action) {
	switch (action.type) {
		case actions.LOGIN_SUCCESS:
			return { 
				...state, 
				user_id: action.user_id,
				user_token: action.user_token
			};
		case actions.SET_CAN_CHANGE_QUOTUM:
			return {
				...state,
				can_change_quotum : action.payload
			}
		case actions.SET_ALLOWED_CREATABLE_USER_TYPES:
			return {
				...state,
				allowed_creatable_user_types : action.payload
			}
		case actions.SET_ROUTES:
			return { 
				...state, 
				routes: action.payload,
			};	
		case actions.SET_MENU:
		return { 
				...state, 
				menu: action.payload,
			};			
		case actions.LOGOUT:
			return initState;
		default:
			return state;
	}
}
