const actions = {
	LOCATION_UPDATE: 'LOCATION_UPDATE',
	LOCATION_UPDATE_SUCCESS: 'LOCATION_UPDATE_SUCCESS',
	LOCATION_ADD : 'LOCATION_ADD',
	FETCH_LOCATIONS : 'FETCH_LOCATIONS',
	SET_LOCATIONS : 'SET_LOCATIONS',
	PROCESS_LOCATIONS : 'PROCESS_LOCATIONS',
	SYNC_LOCATION : 'SYNC_LOCATION',
	SYNC_ACTION_PENDING : 'SYNC_ACTION_PENDING',
	GET_LOCATION_STATUS : 'GET_LOCATION_STATUS',
	SET_LOCATION_STATUS : 'SET_LOCATION_STATUS',
	STATUS_POPUP_OPEN : 'STATUS_POPUP_OPEN',
	FAILURE : 'FAILURE',
	failure : () => ({
		type : actions.FAILURE,
	}),

	updateLocation: (payload) => ({
	    type: actions.LOCATION_UPDATE,
	    payload: payload
	}),

	syncLocation: (payload) => ({
		type: actions.SYNC_LOCATION,
		payload: payload
	}),

	getLocationStatus: (payload) => ({
		type: actions.GET_LOCATION_STATUS,
		payload: payload
	}),

	processLocations: (payload) => ({
		type: actions.PROCESS_LOCATIONS,
		payload: payload
	}),

	addLocation: (payload) => ({
	    type: actions.LOCATION_ADD,
	    payload: payload
	}),

	setLocations : (payload) => ({
		type: actions.SET_LOCATIONS,
		payload: payload
	})

};

export default actions;
