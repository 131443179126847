import React from 'react';
import Header from './Header'
import Sidebar from './Sidebar'
import Footer from './Footer'
import { connect } from 'react-redux';

const Base = props => (
    <div className="wrapper">
    	
    
        <Header fuelingActionPending={props.fuelingActionPending} pinPopupVisible={props.pinPopupVisible}/>

        <Sidebar fuelingActionPending={props.fuelingActionPending} pinPopupVisible={props.pinPopupVisible}/>

      

        <section className="section-container">
            { props.children }
        </section>

        <Footer />
    </div>
)

const mapStateToProps = (state, ownProps) => {
    return {
        fuelingActionPending : state.Fueling.fuelingActionPending,
        pinPopupVisible : state.Fueling.pinPopupVisible
    }
}



export default connect(mapStateToProps)(Base)