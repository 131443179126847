import React, { Component } from 'react';
import Table from '../../Table/Table';
import { NavLink } from 'react-router-dom';
import { formatMoney } from '../../../helpers/utility';
import moment from 'moment';

class FuelingTable extends Component {
	_parseDate = (date) => {
		let dateArray = date.split('-');
		let returnDate = false;
		if ( dateArray[0].length === 4 ) {
    		returnDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
    	} else {
    		returnDate = moment(date, 'DD-MM-YYYY HH:mm:ss');
    	}

    	return returnDate.format('DD-MM-YYYY HH:mm:ss');

	}

    render() {
   		let columns = [];

   		if ( this.props.userObject ) {
   		
   			columns.push({
		            Header: 'Bedrijf',
					accessor: 'company',
		            Cell: props => <span>{this.props.userObject[props.original.userID] && !this.props.self ? this.props.userObject[props.original.userID]['company'] : this.props.userObject.company} </span>,
					sortMethod: (a, b) => {
						if (a === b) {
							return a > b ? 1 : -1;
						}
						return a > b ? 1 : -1;
					 } // String-based value accessors!
    		});

    		columns.push({
		            Header: 'Naam',
		            accessor: 'name',
		            Cell: props => <span>{console.log(props)}{this.props.userObject[props.original.userID] && !this.props.self ? this.props.userObject[props.original.userID]['firstname'] + ' ' + this.props.userObject[props.original.userID]['lastname'] : this.props.userObject.firstname + ' ' + this.props.userObject.lastname} </span>,
					sortMethod: (a, b) => {
						if (a === b) {
							return a > b ? 1 : -1;
						}
						return a > b ? 1 : -1;
					 } // String-based value accessors!
					});
   		}
        columns.push({
                Header: 'Locatie',
                accessor: 'location_firebase_id',
                Cell: props =><span>{props.value.name}</span>
            },
            {
            	Header: 'Datum',
                accessor: 'fueling_date',
                Cell: props => this._parseDate(props.value),
                sortMethod: (a, b) => {

                	let aArray = a.split('-');
                	if ( aArray[0].length === 4 ) {
                		a = parseInt(moment(a, 'YYYY-MM-DD HH:mm:ss').toDate().getTime());
                	} else {
                		a = parseInt(moment(a, 'DD-MM-YYYY HH:mm:ss').toDate().getTime());
                	}

                	let bArray = b.split('-');
                	if ( bArray[0].length === 4 ) {
                		b = parseInt(moment(b, 'YYYY-MM-DD HH:mm:ss').toDate().getTime());
                	} else {
                		b = parseInt(moment(b, 'DD-MM-YYYY HH:mm:ss').toDate().getTime())
                	}
            		
            		
                    if (a === b) {
                      return a > b ? 1 : -1;
                    }
                    return a > b ? 1 : -1;
                  }
            },
           
             {
                Header: 'afgenomen volume',
                accessor: 'volume_taken',
                Cell: props => <span>{props.value ? props.value : 0} liter</span> 

            }
        );

        if (  this.props.userObject ) {
			
			if ( this.props.type === 'all' ) {
        		
    			columns.push(
	    			{
			            Header: 'Saldo',
			            accessor: 'userID',
			            Cell: props => <span>{this.props.userObject[props.value] && !this.props.self ? formatMoney(this.props.userObject[props.value]['credit']) : formatMoney(this.props.userObject.credit)}</span>,
			            sortMethod: (a, b) => {
			            	let aVal = 0;
			            	let bVal = 0;
			            	if ( this.props.userObject[a] ) {
			            		aVal = this.props.userObject[a]['credit'];
			            	}

			            	aVal = parseFloat(aVal);
                			bVal = parseFloat(bVal);

			            	if ( this.props.userObject[b] ) {
			            		bVal = this.props.userObject[b]['credit'];
			            	}

			            	if (aVal === bVal) {
			                    return aVal > bVal ? 1 : -1;
			                }
			                return aVal > bVal ? 1 : -1;
                 		}
	    			}
	    		)
			}
			
			if ( this.props.type === 'large' || this.props.type === 'all' ) {
        	columns.push(
    			
    			{
		            Header: 'Klantnummer',
		            accessor: 'userID',
		            Cell: props => <span>{this.props.userObject[props.value] ? this.props.userObject[props.value]['client_number'] : ''}</span>,
					sortMethod: (a, b) => {
						if (a === b) {
							return a > b ? 1 : -1;
						}
						return a > b ? 1 : -1;
					 } // String-based value accessors!
    			},
    			{
		            Header: 'Gebruiker',
		            accessor: 'userID',
		            Cell: props => <span><NavLink to={'/edit_user/' + props.value}>{this.props.userObject[props.value] ? this.props.userObject[props.value]['firstname'] + ' ' + this.props.userObject[props.value]['lastname'] : ''}</NavLink></span>,
					sortMethod: (a, b) => {
						if (a === b) {
							return a > b ? 1 : -1;
						}
						return a > b ? 1 : -1;
					 } // String-based value accessors!
    			}
			)
			}

			columns.push({
                Header: 'Tankrapport',
                accessor: 'tank_report_filename',
                Cell: props => <a target="_blank" href={"//api.aquabox.acceptage.nl/uploads/tank_report/" + props.original.tank_report_filename}>{props.original.tank_report_filename}</a>
            });
        }

        if ( this.props.data ) {
        	return (
	            <Table data={this.props.data ? Object.values(this.props.data) : false} columns={columns} />
	        );
        } else {
        	return (
        		<Table data={[]} columns={columns} />
        	)
        }
        
    }

}

export default FuelingTable