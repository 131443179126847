export function hasError (fieldName, dataObject = false, sameAs = false, returnBool = false, ) {


    if(sameAs && dataObject) {
    	
        let thisField = dataObject[fieldName];
        let matchField = dataObject[sameAs];

        if(thisField === false && matchField === false) {

        	if(!returnBool) {
        		return {
					display: 'none'
				}
        	}else{
        		return false;
        	}
        	
        }
        if(thisField && matchField) {
				
            if(thisField !== matchField) {
                if(!returnBool) {
            		return {
						display: 'block'
					}
            	}else{
            		return true;
            	}
            }
        }else{
            if(thisField === '' && matchField === '') {
          
            }else{
                if(!returnBool) {
            		return {
						display: 'block'
					}
            	}else{
            		return true;
            	}
            }
           
        }
    }else{
    	if(!dataObject[fieldName]) {
    		if(!returnBool) {
        		return {
					display: 'block'
				}
        	}else{
        		return true;
        	}
    	}
    }

    if(!returnBool) {
		return {
			display: 'none'
		}
	}else{
		return false;
	}

}