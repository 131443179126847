import React, { Component } from 'react';
import {  Trans } from 'react-i18next';
import { Row, Col} from 'reactstrap';

import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer
} from 'recharts';


class FuelingStatistics extends Component {
	

    render() {
 	
 		if ( this.props.statistics ) {
	    	if ( this.props.statistics.length > 0) {

		        return (
		          
	                      <Col xl={ 6 } >
	                        <Row>
	                            <Col xl={ 12 }>
	                                <div className="card card-default">
	                                    <div className="card-header">
	                                        <div className="card-title"><Trans i18nKey={'dashboard.FUELING_STATISTICS_'+this.props.type}></Trans></div>
	                                    </div>
	                                    <div className="card-body">
	                                        { this.props.statistics.length ? 
											<ResponsiveContainer height={500} width={"100%"}>
												<LineChart  >
												<CartesianGrid strokeDasharray="1 1"/>
												<XAxis dataKey="key" />
												<YAxis dataKey="value" tickFormatter={this.props.tickFormatter}/>
												
												<Line isAnimationActive={true} dataKey="value" data={this.props.statistics} />
												</LineChart>
											</ResponsiveContainer>
									    :  <div className="pacman" style={{marginLeft: '35px'}}>
			                                    <div></div>
			                                    <div></div>
			                                    <div></div>
			                                    <div></div>
			                                    <div></div>
			                                </div> }
	                                    </div>
	                                </div>
	                            </Col>
	                        </Row>
	                    </Col>
	                 
		            );
		    }
	    }

	    return null;

    }

}

export default FuelingStatistics;
