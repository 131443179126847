import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import { push } from 'react-router-redux';
import { fetchData } from '../../helpers/fetch';
import { toast } from 'react-toastify';
import { getToken, getClientPath } from '../../helpers/utility';

const { rsfFirestore,firebase } = FirebaseHelper;


export function* fetchFuelingLocations() {
	yield takeEvery(actions.FETCH_FUELING_LOCATIONS, function*(action) {

		
		// -- Fetch a snapshot of all locations that have a reference to your current usertype
		const snapshot = yield call(rsfFirestore.getCollection, firebase.firestore().collection('locations').where("locationTypeValue", "==",  action.payload.usage_type_value));
		yield put({
			type: actions.PROCESS_FUELING_LOCATIONS,
			payload: snapshot
		})

	})
}

export function* fetchFuelingHistory() {
	yield takeEvery(actions.FETCH_FUELING_HISTORY, function*(action) {
		const user =  FirebaseHelper.auth().currentUser;
		
		yield fork(
			rsfFirestore.syncCollection, 
			'user_fueling_history/'+user.uid + '/fueling_actions', 
			{ successActionCreator: (snap) => actions.processFuelingHistory({type: 'self', data : FirebaseHelper.processFireStoreCollection(snap) }), failureActionCreator : () => actions.failure()}
		); 	
	})
}

export function* processFuelingHistory() {
	yield takeEvery(actions.PROCESS_FUELING_HISTORY, function*(action) {

		const processedCollection = action.payload.data;
		let historyCollection = [];

		if ( action.payload.type === 'self') {

			for (let key in processedCollection) {
			    if (processedCollection.hasOwnProperty(key)) {
			   		let item = processedCollection[key];

			   		// When fueling status is synced by an aquabox the reference can not be set by PHP. So we need to rebuild it.
			   		if (typeof item.location_firebase_id == 'string') {
			   			let location_id = firebase.firestore().doc('locations/' + item.location_firebase_id);
						item.location_firebase_id = location_id;
					}

					let locationRef = yield call(rsfFirestore.getCollection, item.location_firebase_id);
					let location = locationRef.data();

			   		item = {
			    		...item,
			    		location_firebase_id: location
			    	}

			    	historyCollection.push(item);
			    }
			}

			yield put({
				type: actions.SET_FUELING_HISTORY,
				payload: {
					type: action.payload.type,
					data : historyCollection
				}
			})
		} else {

			for (let key in processedCollection) {
				if (processedCollection.hasOwnProperty(key)) {
			   		let item = processedCollection[key];
			   		//console.log ( item );


			   		// When fueling status is synced by an aquabox the reference can not be set by PHP. So we need to rebuild it.
			   		/*if (typeof item.location_firebase_id == 'string') {
			   			let location_id = firebase.firestore().doc('locations/' + item.location_firebase_id);
						item.location_firebase_id = location_id;
					}

					let locationRef = yield call(rsfFirestore.getCollection, item.location_firebase_id);
					let location = locationRef.data();
					*/
					
			   		item = {
			    		...item
			    	}

			    	historyCollection.push(item);
			   	}
			}

			yield put({
				type: actions.SET_FUELING_HISTORY,
				payload: {
					type: action.payload.type,
					data : historyCollection
				}
			})
		}
	})
}


export function* processFuelingLocations() {
	yield takeEvery(actions.PROCESS_FUELING_LOCATIONS, function*(action) {


		const snapshot = action.payload;
		const processedCollection = FirebaseHelper.processFireStoreCollection(snapshot);
		let locationCollection = [];

		for (let key in processedCollection) {
		    if (processedCollection.hasOwnProperty(key)) {
		    			    			    	
		    	let item = {
		    		id : key,
		    		name : processedCollection[key]['name'],
		    		api_address : processedCollection[key]['api_address'],
		    		notes : processedCollection[key]['notes'],
					city : processedCollection[key]['city'],
					price_per_volume: processedCollection[key]['price_per_volume'],
		    	}

		    	if(processedCollection[key]['active'] === true ) {
		    		locationCollection.push(item);
		    	}
		    	
		    }
		}
		
		yield put({
			type: actions.SET_FUELING_LOCATIONS,
			payload: locationCollection
		})
	})
}

export function* initializeFueling() {
	yield takeEvery(actions.INITIALIZE_FUELING, function*(action) {
		
		yield put({
			type: actions.SET_FUELING_ACTION_PENDING,
			payload: true
		})

		const user =  FirebaseHelper.auth().currentUser;
		action.payload.append('token', user.uid);

		const response = yield call(
			fetchData,  
			getClientPath() + '/fueling/initializeFueling',
			action.payload
		)

		if(response) {
			yield put({
				type: actions.SET_FUELING_ACTION_PENDING,
				payload: false
			})

			toast(response.notification, {
		        type: response.type,
		        position: 'top-right'
		    })

		    if(response.fuelingDocument) {			    	
		    	// -- Get location ref   	
		    	let locationRef = firebase.firestore().doc('locations/' + response.fuelingDocument.location_firebase_id);
	    		response.fuelingDocument.location_firebase_id = locationRef;

	    		// -- Create document
		    	yield call(rsfFirestore.setDocument, 'user_fueling_history/'+user.uid+'/fueling_actions/'+response.fuelingDocument.id, response.fuelingDocument)				    
			}

			if(response.type === 'success') {
				yield put({
					type: actions.SET_PIN_POPUP_VISIBLE,
					payload: true
				})
			}
			
		}
	})
}

export function* cancelFueling() {
	yield takeEvery(actions.CANCEL_FUELING_ACTION, function*(action) {
		const user =  FirebaseHelper.auth().currentUser;
		action.payload.append('token', user.uid);

		const response = yield call(
			fetchData,
			getClientPath() + '/fueling/cancel',
			action.payload
		)

		if (response) {
			yield put({
				type: actions.SET_PIN_POPUP_VISIBLE,
				payload: false
			})
		}
	})
}

export function* sendPin() {
	yield takeEvery(actions.SEND_PIN, function*(action) {
		
		const user =  FirebaseHelper.auth().currentUser;
		action.payload.append('token', user.uid);
		
		const response = yield call(
			fetchData,  
			getClientPath() + '/fueling/checkPin',
			action.payload
		)

		if(response) {
			toast(response.notification, {
		        type: response.type,
		        position: 'top-right'
		    })
			let firebaseId = false;
		    if(response.fuelingDocument) {			    	
		    	// -- Get location ref
		    	firebaseId = response.fuelingDocument.location_firebase_id;
		    	let locationRef = firebase.firestore().doc('locations/' + response.fuelingDocument.location_firebase_id);
	    		response.fuelingDocument.location_firebase_id = locationRef;

	    		// -- Update document
		    	yield call(rsfFirestore.updateDocument, 'user_fueling_history/'+user.uid+'/fueling_actions/'+response.fuelingDocument.id, response.fuelingDocument)
								    
			}

			if(response.type === 'success') {
				yield put(push('/fueling-success/'+firebaseId + '/' + response.fuelingDocument.id));
			}

		    yield put({
				type: actions.SET_PIN_POPUP_VISIBLE,
				payload: false
			})


		    if(response.end_fueling_action) {
		    	yield put({
					type: actions.SET_PIN_POPUP_VISIBLE,
					payload: false
				})
		    	//
		    }
		}
	})
}

export function* getInvoiceFile() {
	yield takeEvery(actions.GET_INVOICE_FILE, function*(action) {
	

		let users = [];

		for ( let i = 0; i <= action.payload.users.length; i++ ) {
			if ( action.payload.users[i] ) {
				users.push( action.payload.users[i].value );

				
			}
		}

		
		const user =  FirebaseHelper.auth().currentUser;
		let payload = new FormData();
		payload.append('token', user.uid);
		payload.append('users', users);
		payload.append('dateFrom', action.payload.dateFrom);
		payload.append('dateTo', action.payload.dateTo);
		payload.append('fileType', action.payload.type)

		yield put({
			type : actions.SET_INVOICE_WORKING,
			payload : true
		})

		const response = yield call(
			fetchData,  
			getClientPath() + '/invoice/fetchInvoices',
			payload
		)

		if ( response.notification ) {
			toast(response.notification, {
		        type: response.type,
		        position: 'top-right'
		    })

			yield put({
				type : actions.SET_INVOICE_WORKING,
				payload : false
			})

			yield put({
				type : actions.SET_INVOICE_FILE_LINK,
				payload: response.filename
			})
		}

		yield put({
			type : actions.SET_INVOICE_WORKING,
			payload : false
		})



	})
}

export function* syncFuelingLocations() {
	const localUserData = getToken();

	const user_id = localUserData.get('userId');


	if ( user_id ) {

		yield fork(
		  	rsfFirestore.syncCollection,
		  	firebase.firestore().collection('locations'),
			{ successActionCreator: (snap) => actions.processFuelingLocations(snap), failureActionCreator : () => actions.failure()}
		  )	

	}
}

export function* syncFuelingHistory() {
	const localUserData = getToken();

	const user_id = localUserData.get('userId');


	if ( user_id ) {
	

		// -- Sync user document and set user properties with snapped data on change.
		yield fork(
			rsfFirestore.syncCollection, 
			'user_fueling_history/'+user_id + '/fueling_actions', 
			{ successActionCreator: (snap) => actions.processFuelingHistory({type: 'self', data : FirebaseHelper.processFireStoreCollection(snap)}), failureActionCreator : () => actions.failure()}
		);
	}
}

export function* failure() {
	yield takeEvery(actions.FAILURE, function*(action) {

		
	
	})
}


export default function* rootSaga() {
	yield all([
		fork(fetchFuelingLocations),	
		fork(processFuelingLocations),
		fork(syncFuelingLocations),
		fork(initializeFueling),
		fork(sendPin),
		fork(syncFuelingHistory),
		fork(fetchFuelingHistory),
		fork(processFuelingHistory),
		fork(cancelFueling),
		fork(failure),
		fork(getInvoiceFile)
	]);
}
