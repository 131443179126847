import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Table from '../../Table/Table';
import { NavLink } from 'react-router-dom';
import { formatMoney } from '../../../helpers/utility';
import moment from 'moment';

class PaymentTable extends Component {

   
    render() {
       
        const columns = [
            {
                Header: 'Bedrag',
                accessor: 'payment_amount',
                Cell: props => formatMoney(props.value),
                sortMethod: (a, b) => {
	            	if (a === b) {
	                    return a > b ? 1 : -1;
	                }
	                return a > b ? 1 : -1;
         		} // String-based value accessors!
            },{
                Header: 'Datum',
                accessor: 'payment_date',
            	sortMethod: (a, b) => {
            		a = parseInt(moment(a, 'DD-MM-YYYY HH:mm:ss').toDate().getTime());
            		b = parseInt(moment(b, 'DD-MM-YYYY HH:mm:ss').toDate().getTime())
                    if (a === b) {
                      return a > b ? 1 : -1;
                    }
                    return a > b ? 1 : -1;
                  }
            }, {
                Header: 'Naam',
                id: 'firstname',
                accessor: 'firstname',
                Cell: props => <span className='number'>{props.original.firstname + ' '+ props.original.lastname} </span> // Custom cell components!
            }, {
                Header: 'Type',
                accessor: 'payment_type'
            }, {
                Header: 'Status',
                accessor: 'status' // String-based value accessors!
            }, {
                Header: 'Factuur',
                accessor: 'invoice_filename', // String-based value accessors!
                Cell: props => <a target="_blank" href={"//api.aquabox.acceptage.nl/uploads/mollie_invoice/" + props.original.invoice_filename}>{props.original.invoice_filename}</a>
            }
        ];


       
        if ( this.props.data !== undefined ) {
        	return (
	            <Table data={this.props.data ? Object.values(this.props.data) : false} columns={columns} />
	        );
        }

        return <Table data={[]} columns={columns} />
        
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        allUsers: state.User.all_users,
        subUsers: state.User.sub_users
    }
}



export default withRouter(connect(mapStateToProps)(PaymentTable))