import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';


import Dashboard from './components/Dashboard/Dashboard'

import Base from './components/Layout/Base';
import BasePage from './components/Layout/BasePage';

import Login from './components/Pages/Auth/Login';
import Register from './components/Pages/User/Register';
import Recover from './components/Pages/Auth/Recover';

import User from './components/Pages/User/User';
import UpdateSaldo from './components/Pages/Credit/UpdateSaldo';
import PaymentHistory from './components/Pages/Credit/PaymentHistory';
import PaymentHistorySub from './components/Pages/Credit/PaymentHistorySub';
import AddUser from './components/Pages/User/AddUser';
import UserOverview from './components/Pages/User/UserOverview';

import LocationForm from './components/Pages/Location/LocationForm';
import LocationOverview from './components/Pages/Location/LocationOverview';

import Logout from './components/Pages/Auth/Logout';
import  actions  from './redux/auth/actions';

import FuelingLocationOverview from './components/Pages/Fueling/FuelingLocationOverview';
import Fueling from './components/Pages/Fueling/Fueling';
import FuelingHistory from './components/Pages/Fueling/FuelingHistory';
import FuelingSuccess from './components/Pages/Fueling/FuelingSuccess';

import Invoice from './components/Pages/Fueling/Invoice';

import RouteWrap from './components/Common/RouteWrap';

import UpdateUserPermissions from './components/Pages/UpdateUserPermissions';

import TermsAndConditions from './components/Pages/Content/TermsAndConditions';
import PrivacyStatement from './components/Pages/Content/PrivacyStatement';

// List of routes that uses the page layout
// listed here to Switch between layouts
// depending on the current pathname
const listofPages = [
    '/login',
    '/register',
	'/recover'
];

const {checkUrl} = actions;

// -- add components to list
const componentList = {
	'User' : User,
	'AddUser' : AddUser,
	'Dashboard' : Dashboard,
	'UserOverview' : UserOverview,
	'Saldo': UpdateSaldo,
	'LocationForm' : LocationForm,
	'LocationOverview' : LocationOverview,
	'FuelingLocationOverview' : FuelingLocationOverview,
	'Fueling' : Fueling,
	'FuelingHistory' : FuelingHistory,
	'UpdateUserPermissions' : UpdateUserPermissions,
	'PaymentHistory' : PaymentHistory,
	'PaymentHistorySub' : PaymentHistorySub,
	'Invoice' : Invoice
}
let i = 0;
class Routes extends Component {

	_mapRoutes = (routeObject) => {
			if ( routeObject.usageType ) {
	    		if ( routeObject.usageType !== this.props.user.usage_type_value) {
	    			return null;
	    		}
	    	}

		i++;
		return (
			<Route
				key={routeObject.pageKey + i}
				path={routeObject.route}
				exact
				render={ (props) =>
                    <RouteWrap component={componentList[routeObject.component]}/>
                }
				/>

			);
	}


	render() {

		const location = this.props.location;
		const currentKey = location.pathname.split('/')[1] || '/';
	    const timeout = { enter: 500, exit: 500 };

	    // Animations supported
	    //      'rag-fadeIn'
	    //      'rag-fadeInRight'
	    //      'rag-fadeInLeft'

	    const animationName = 'rag-fadeIn';

		// -- Check if user is logged in.
	    if(!this.props.isLoggedIn && location.pathname !== '/login' && location.pathname !== '/register' && location.pathname !== '/recover' && location.pathname !== '/algemene-voorwaarden') {
	    	return (
	    		<Switch>
	    			<Redirect to="/login" />
	    		</Switch>
	    	)
	    }else{
	    	if(this.props.isLoggedIn && location.pathname === '/login' || this.props.isLoggedIn && location.pathname === '/') {
	    		return (
		    		<Switch>
		    			<Redirect to="/dashboard" />
		    		</Switch>
		    	)
	    	}
	    }

	    if(listofPages.indexOf(location.pathname) > -1) {
	        return (
	            // Page Layout component wrapper
	            <BasePage>

	                <Switch location={location}>
	                	<Route
							path="/login"
							render={ (props) =>
			                    <RouteWrap component={Login}/>
			                }
						/>
						<Route
							path="/register"
							render={ (props) =>
			                    <RouteWrap component={Register}/>
			                }
						/>
						<Route
							path="/recover"
							render={ (props) =>
			                    <RouteWrap component={Recover}/>
			                }
						/>

	                </Switch>
	            </BasePage>
	        )
	    }
	    else {
	        return (
	            // Layout component wrapper
	            // Use <BaseHorizontal> to change layout

	            <Base>

	              <TransitionGroup>
	                <CSSTransition key={currentKey} timeout={timeout} classNames={animationName} exit={false}>
	                    <div>

	                        <Switch location={location}>
                                <Route exact path="/user_overview">
                                    <Redirect to="/user_overview/all" />
                                </Route>
	                        		<Route
											path="/user"
											render={ (props) =>
							                    <RouteWrap component={User}/>
							                }
										/>
									<Route
										path="/logout"
										render={ (props) =>
						                    <RouteWrap component={Logout}/>
						                }
									/>

									<Route
										path="/privacy-verklaring"
										render={ (props) =>
						                    <RouteWrap component={PrivacyStatement}/>
						                }
									/>

									<Route
										path="/algemene-voorwaarden"
										render={ (props) =>
											<RouteWrap component={TermsAndConditions}/>
										}
									/>


									<Route
										path="/fueling-success/:location_id/:fueling_id"
										render={ (props) =>
						                    <RouteWrap component={FuelingSuccess}/>
						                }
									/>
		                            {/*Get custom routes, but only for the base pages*/}
		                         	{this.props.routes ? this.props.routes.filter(route => route.pageKey === 'Base').map((item) => this._mapRoutes(item)) : null}

	                        </Switch>
	                    </div>
	                </CSSTransition>
	              </TransitionGroup>
	            </Base>
	        )
	    }
	}
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.user_token !== null ? true : false,
        user : state.User.user_profile ? state.User.user_profile : false,
        routes: state.Auth.routes,
         fuelingActionPending : state.Fueling.fuelingActionPending
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        _checkUrl: url => dispatch(checkUrl(url)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Routes))
