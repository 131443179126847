import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ContentWrapper from '../../Layout/ContentWrapper';
import { hasError } from '../../../helpers/formHelper';
import {
    Card,
    CardBody,
    CardHeader,
    FormGroup,
    Input,
     } from 'reactstrap';


import { toast } from 'react-toastify';
import actions from '../../../redux/credit/actions';

import { formatMoney } from '../../../helpers/utility';
const {updateCredit} = actions;


class UpdateSaldo extends Component {
 
	constructor(props) {
		super(props);

		this.state = {
			user_credit: false
		}
	}

	 _updateField = (field, event) => {
    	this.setState({
			...this.state,
			[field] : event.target.value
    		
    	})
    }

    componentDidUpdate(prevProps) {
        if(prevProps.credit.paymentUrl !== this.props.credit.paymentUrl) {
            window.location = Object.values(this.props.credit.paymentUrl).join('');
        }
    }

    onSubmit = e => {
        
        e.preventDefault();	

        const hasErrors = this._findAllErrors();

        if(!hasErrors) {
            this.props.updateCredit(this.state)
        }else{
        	toast('Let alstublieft op de foutmeldingen.', {
		        type: 'error',
		        position: 'top-right'
		    })
        }        
    }

    _findAllErrors = () => {
    	
    	if(
    		!hasError('user_credit', this.state, false, true) && 
    		!hasError('payment_method', this.state, false, true) 
    	) {
    		return false;
    	}else{

    		if(this.state.user_credit < 35) {
    			return false;
    		}

    		return true;
    	}

    }

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Saldo toevoegen</div>
                </div>
              
                <div className="row">
                	<div className="col-md-4">
                
                		 <div className="card flex-row align-items-center align-items-stretch border-0">
                            <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left">
                                <em className="fa fa-euro-sign fa-3x"></em>
                            </div>
                            <div className="col-8 py-3 bg-primary rounded-right">
                                <div className="h2 mt-0">€ {this.props.credit.credit} </div>
                                <div className="text-uppercase">Saldo</div>
                            </div>
                        </div>
                     
                	</div>
                    <div className="col-md-8">
                        <Card className="card-default">
                        	<CardHeader>
                       			<h4>Saldo toevoegen</h4>
                       		</CardHeader>
                            <CardBody>
                                <form onSubmit={this.onSubmit}>
                                	<FormGroup>
                                        <label>Hoeveel saldo wilt u toevoegen?</label>
                                        <span style={{position: 'absolute', top: '36px', left: '6px'}}>€</span>
                                        <Input onChange={(event) => this._updateField('user_credit', event)} min="35" type="number" step="0.25" placeholder="Bedrag" value={this.state.user_credit ? this.state.user_credit : ''}/>
                                    	<span style={hasError('user_credit', this.state)} className="invalid-feedback">Vul alstublieft een bedrag in. Minimaal €35,-</span> 
                                    </FormGroup>

                                    <FormGroup>
                                        <label>Hoe wilt u betalen?</label>
                                        <Input onChange={(event) => this._updateField('payment_method', event)} type="select" value={this.state.payment_method ? this.state.payment_method : ''}>
                                        	<option value="">Selecteer een betaalmethode</option>
                                        	<option value="ideal">iDeal</option>
                                        	<option value="paypal">PayPal</option>
                                        </Input>
                                    	<span style={hasError('payment_method', this.state)} className="invalid-feedback">Selecteer een betaalmethode</span> 
                                    </FormGroup>
                                   
                                    <button className="btn btn-sm btn-secondary" type="submit">Opwaarderen</button>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.user_profile ? state.User.user_profile : false,
        dbProfile: state.User.db_profile,
        userType: state.UserType,
        credit: state.Credit,
        paymentHistory: state.Credit.paymentHistory ? state.Credit.paymentHistory : false
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        updateCredit: (data) => dispatch(updateCredit(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, false, {pure: false})(UpdateSaldo))