import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    FormGroup,
    Input
     } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ContentWrapper from '../../Layout/ContentWrapper';

import Slider from 'react-rangeslider'

import 'react-rangeslider/lib/index.css'
import fuelingActions  from '../../../redux/fueling/actions';

import PinPopup from './PinPopup';

const { initializeFueling } = fuelingActions;

class Fueling extends Component {

    constructor(props) {
        super(props)
        this.state = {location: false, volume: 0, estimated_amount: 0}
    }

    componentDidMount() {

    	if(this.props.match.params.location_id) {
    		if(this.props.locations) {
    			const location = this.props.locations.find(item => item.id === this.props.match.params.location_id);
	    		this.setState({
	    			location
	    		})
    		}
    	}
    }

	handleOnChange = (value) => {

		let estimated_amount = this.state.location.price_per_volume * value;
		this.setState({
			volume: value,
			estimated_amount : estimated_amount
		})
	}

    componentDidUpdate() {
    	if(this.props.match.params.location_id) {
    		if(!this.state.location && this.props.locations.length) {
    			const location = this.props.locations.find(item => item.id === this.props.match.params.location_id);

    			if ( location ) {
    				this.setState({
		    			location
		    		})
    			}

	    		/*this.setState({
	    			location
	    		})*/
    		}
    	}
    }

    _updateField = (field, event) => {
      	let value = event.target.value;

      	let maxValue = 2000;

      	if(this.props.user.quotum_infinite) {
      		maxValue = 2000;
      	}else {
      		if(this.props.user.quota) {
	      		maxValue = parseInt(this.props.user.quota);
	      	}
	   	}

      	if(value > maxValue) {
      		value = maxValue;
      	}
    	this.setState({
    		[field] : value

    	})
    }


    _formSubmit = (event) => {
    	event.preventDefault();

    	let formData = new FormData();
    	formData.append('location_id', this.state.location.id);
    	formData.append('volume', this.state.volume);
    	formData.append('firebase_user_id', this.props.user.uid);
    	formData.append('api_address', this.state.location.api_address);

    	this.props.initializeFueling(formData);
    	/*
			Submit to server, server generates pincode for this location and user
			Server puts data in user table and sends to aquabox
			Aquabox displays pincode

			If server gives 'OK' response, application displays pin form
			User fills out form on aquabox display
			If pincode matches user table, send to server
			Server checks for valid code & location ID
			If OK, send to aquabox, display OK response to user

    	*/
    }

    _getMax = () => {
    	if ( this.props.user.usage_type_value === 'large') {
    		return 40000;
    	}

    	if ( this.props.user.usage_type_value === 'small' ) {
    		return 2000;
    	}
    }

    render() {

        return (
            <ContentWrapper>

                <div className="content-heading">
                    <div>Tankactie starten<br />
                    <small> {this.state.location ? this.state.location.name : null} </small>
                    </div>
                </div>

                <div className="row">
                	{this.props.fuelingActionPending ? <div className="overlay--full"></div> : null}
                	{ this.props.pinPopupVisible ? <PinPopup api_address={this.state.location.api_address} locationId={this.state.location.id} firebase_user_id={this.props.user.uid}/> : null }
                    <div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
                             <NavLink className="btn btn-sm btn-secondary" style={{marginBottom: '25px'}} to="/fueling_locations_overview"><i className="fa fa-chevron-left"></i>&nbsp;Terug naar overzicht</NavLink>
                            	<form onSubmit={this._formSubmit}>

	                                <FormGroup>
	                                    <label>Hoeveel wilt u afnemen?</label>
	                                   	<Input onChange={(event) => this._updateField('volume', event)} type="number" placeholder="Volume" value={this.state.volume}/>
	                                      <div className='slider'>
	                                    	<Slider
									        	value={parseInt(this.state.volume)}
									        	onChange={this.handleOnChange}
									        	min={0}
									        	max={this._getMax()}
									      	/>
									     </div>
									     <div className='value'>{this.state.volume} liter</div>

										 <div className='value'>{this.props.user.usage_type_value ? null : 'Geschatte kosten:'} € {this.state.estimated_amount}</div>

										 <div className='value'>Geschatte kosten: € {this.state.estimated_amount.toFixed(4).toString().replace('.', ',')} (excl. BTW)</div>


	                                </FormGroup>

	                                <FormGroup>
	                                   <button className="btn btn-sm btn-success float-right" type="submit" disabled={this.state.volume <= 0 ? 'disabled' : null}>Tankactie starten</button>
	                                   <NavLink to={"/fueling_locations_overview"} className="btn btn-sm btn-danger float-left" >Tankactie annuleren</NavLink>
	                                </FormGroup>
	                            </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        initializeFueling: (data) => dispatch(initializeFueling(data))
    };
};


const mapStateToProps = (state, ownProps) => {

    return {
       	locations : state.Fueling.fuelingLocations,
       	routes: state.Auth.routes,
       	user: state.User.user_profile ? state.User.user_profile : false,
        dbProfile: state.User.db_profile,
        userType: state.UserType,
        fuelingActionPending : state.Fueling.fuelingActionPending,
        pinPopupVisible : state.Fueling.pinPopupVisible
    }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Fueling))
