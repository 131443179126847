import React, { Component } from 'react';

import { Input } from 'reactstrap';

import FormValidator from '../../Forms/FormValidator.js';
import Firebase from "../../../helpers/firebase";

import { connect } from 'react-redux';
import authAction from '../../../redux/auth/actions';

import { Link } from 'react-router-dom';

import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { errorCodes } from '../../../helpers/errorMessage';
const { login } = authAction;

class Login extends Component {

    state = {
        formLogin: {
            email: '',
            password: ''
        }
    }

     /**
      * Validate input using onChange event
      * @param  {String} formName The name of the form in the state object
      * @return {Function} a function used for the event
      */
    validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    }

    onSubmit = e => {
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });


        e.preventDefault()

        const { email, password } = this.state.formLogin;

        let isError = false;
		Firebase.login(Firebase.EMAIL, { email, password })
		.catch(result => {
			toast(errorCodes[result.code]['message'], {
		        type: errorCodes[result.code]['type'],
		        position: 'top-right'
		    })

			isError = true;
		})
		.then(result => {
			
			if (isError) {
			
				return;
			}
			if (!result || result.message) {
				
				const message = result && result.message ? result.message : 'Er heeft zich een fout voorgedaan';
				toast(message, {
			        type: 'error',
			        position: 'top-right'
			    })
			} else {

				this.props.login(result);

				toast('U bent succesvol ingelogd', {
			        type: 'success',
			        position: 'top-right'
			    })
			}
		});
    }

    /* Simplify error check */
    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    }

    render() {

        return (
            <div className="block-center mt-4 wd-xl">
                <div className="card card-flat">
                    <div className="card-header text-center bg-dark">
                        <a href="/">
                        Easywatersupply
                        </a>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">Login om verder te gaan</p>
                        <form className="mb-3" name="formLogin" onSubmit={this.onSubmit}>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="email"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="E-mailadres"
                                        invalid={this.hasError('formLogin','email','required')||this.hasError('formLogin','email','email')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "email"]'
                                        value={this.state.formLogin.email}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formLogin','email','required') && <span className="invalid-feedback">Veld is verplicht</span> }
                                    { this.hasError('formLogin','email','email') && <span className="invalid-feedback">Veld moet een e-mailadres bevatten</span> }
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="password"
                                        id="id-password"
                                        name="password"
                                        className="border-right-0"
                                        placeholder="Wachtwoord"
                                        invalid={this.hasError('formLogin','password','required')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formLogin.password}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-lock"></em>
                                        </span>
                                    </div>
                                    <span className="invalid-feedback">Veld is verplicht</span>
                                </div>
                            </div>

                            <button className="btn btn-block btn-primary mt-3" type="submit">Login</button>
                            <div className="text-center"><Link to="/recover">wachtwoord vergeten?</Link></div>
                              <p className="pt-3 text-center">Heeft u nog geen account?</p>
                       		<Link to="/register" className="btn btn-block btn-secondary">Registreren</Link>
                              
                       		
                        </form>
                        
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.idToken !== null ? true : false
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        login: (data) => dispatch(login(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))

