const actions = {
	CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
	LOGIN_REQUEST: 'LOGIN_REQUEST',
	LOGOUT: 'LOGOUT',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_ERROR: 'LOGIN_ERROR',
	FETCH_ROUTES: 'FETCH_ROUTES',
	SET_ROUTES: 'SET_ROUTES',
	SET_MENU: 'SET_MENU',
	CHECK_URL: 'CHECK_URL',
	FETCH_ALLOWED_CREATABLE_USER_TYPES : 'FETCH_ALLOWED_CREATABLE_USER_TYPES',
	SET_ALLOWED_CREATABLE_USER_TYPES : 'SET_ALLOWED_CREATABLE_USER_TYPES',
	FETCH_ALL_DATA : 'FETCH_ALL_DATA',
	FETCH_USER_QUOTUM_PERMISSIONS : 'FETCH_USER_QUOTUM_PERMISSIONS',
	SET_CAN_CHANGE_QUOTUM: 'SET_CAN_CHANGE_QUOTUM',
	RESET_PERMISSIONS : 'RESET_PERMISSIONS',
	FAILURE : 'FAILURE',
	failure : () => ({
		type : actions.FAILURE,
	}),

	checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),
	updateUserPermissions:  (payload) => ({
		type: actions.RESET_PERMISSIONS,
		payload: payload
	}),
	checkUrl: (payload) => ({ type: actions.CHECK_URL, payload:payload }),

	  login: (payload) => ({
	    type: actions.LOGIN_REQUEST,
	    payload: payload
	  }),

	logout: () => ({
		type: actions.LOGOUT
	})
};

export default actions;
