import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ContentWrapper from '../Layout/ContentWrapper';
import {
    Card,
    CardBody,
    } from 'reactstrap';

import { connect } from 'react-redux';
import authAction from '../../redux/auth/actions';

const { updateUserPermissions } = authAction;

class UpdateUserPermissions extends Component {
	
    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Permissies resetten </div>
                </div>
              
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
   								<table className="table">
   									<tr>
   										<td>Klant</td>
   										<td><button onClick={() => this.props.updateUserPermissions(1)} className="btn btn-sm btn-danger float-left">Reset</button></td>
   									</tr>
   									<tr>
   										<td>Chauffeur</td>
   										<td><button onClick={() => this.props.updateUserPermissions(4)} className="btn btn-sm btn-danger float-left">Reset</button></td>
   									</tr>
   									<tr>
   										<td>Super Admin</td>
   										<td><button onClick={() => this.props.updateUserPermissions(5)} className="btn btn-sm btn-danger float-left">Reset</button></td>
   									</tr>
   									
   								</table>
   							</CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {

    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        updateUserPermissions: (data) => dispatch(updateUserPermissions(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UpdateUserPermissions))