import React, { Component } from 'react';
import pubsub from 'pubsub-js';
import { connect } from 'react-redux';

import { Link } from 'react-router-dom';

import { Collapse } from 'reactstrap';


class SidebarUserBlock extends Component {

    state = {
        userBlockCollapse: false
    }

    componentDidMount() {
        this.pubsub_token = pubsub.subscribe('toggleUserblock', () => {
            this.setState({
                userBlockCollapse: !this.state.userBlockCollapse
            });
        });
    }

    componentWillUnmount() {
        pubsub.unsubscribe(this.pubsub_token);
    }

    render() {
        return (
            <Collapse id="user-block" isOpen={ this.state.userBlockCollapse }>
                <div>
                    <div className="item user-block">
                       <div className="user-block-info">
                          <span className="user-block-name">{this.props.user ? this.props.user.displayName ? this.props.user.displayName : this.props.user.email : null} </span>
                          

                          <Link to="/user" title="Gebruiker wijzigen" style={{margin: '15'}}><strong>Profiel wijzigen</strong></Link>
                          <Link to="/logout" title="Uitloggen" style={{margin: '15px'}}><strong>Uitloggen</strong></Link>
                       </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}


const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.user_profile ? state.User.user_profile : false
    }
}

export default connect(mapStateToProps)(SidebarUserBlock)