import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
    TabContent, TabPane,  Nav, NavItem
     } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import classnames from 'classnames';
import UserTable from './UserTable';
import { NavLink } from 'react-router-dom';
import _ from 'lodash';
class UserOverview extends Component {

    constructor(props) {
        super(props)
        this.state = {user: false, activeTab: '1'}       
    }

    toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}


    _getUsersWithParentId( parent_id, users ) {
    	let returnValue = [];
    	for ( const user in users ) {
    		if ( users.hasOwnProperty( ( user ) ) ) {

    			let currentUser = users[user];

    			if ( currentUser.parent_id ) {
    				if ( currentUser.parent_id === parent_id ) {
    					returnValue.push (
    						<li>
    							<NavLink to={'/edit_user/' + currentUser.firebase_user_id}>{currentUser.firstname} {currentUser.lastname}</NavLink>
    							<ul>{this._getUsersWithParentId(currentUser.firebase_user_id, users)}</ul>
    						</li>
    					)
    				}
    			}


    		}
    	}

    	return returnValue;
    }

   	_renderUserTree = (users) => {
   		let returnValue = [];
   		for ( const user in users ) {
   			if ( users.hasOwnProperty( ( user ) ) ) {
   				let currentUser = users[user];

   				// -- Super admin can see all the things
   				if ( parseInt(this.props.user.user_type.id) === 5 ) {
   					if ( !currentUser.parent_id ) {
	   					returnValue.push(
	   						<li>
	   							<NavLink to={'/edit_user/' + currentUser.firebase_user_id}>{currentUser.firstname} {currentUser.lastname}</NavLink>
	   							<ul>{this._getUsersWithParentId(currentUser.firebase_user_id, users)}</ul>
	   						</li>
	   					)
	   				}
   				} else {
   					
   					//if ( currentUser.firebase_user_id === this.props.user.firebase_user_id ) {
	   					returnValue.push(
	   						<li>
	   							<NavLink to={'/edit_user/' + currentUser.firebase_user_id}>{currentUser.firstname} {currentUser.lastname}</NavLink>
	   							<ul>{this._getUsersWithParentId(currentUser.firebase_user_id, users)}</ul>
	   						</li>
	   					)
	   				//}
   				}
   				
   			}
   		}

   		return <ul>{returnValue}</ul>;
   	}

    render() {
    	const editRoute = this.props.routes.find(item => item.route === '/edit_user/:user_index');

    	let users = this.props.subUsers;
    	let displayParentUser = false;
    	if ( this.props.match.params ) {
    		if ( this.props.match.params.type === 'all' ) {
    			users = this.props.allUsers;
				displayParentUser = true;
				
    		}
    	}
    	/*if ( this.props.match.location.pathname === '/user_overview/all') {
    		console.log("ALL");
    	}*/
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Gebruikers overzicht                        
                    </div>
                </div>

                 <Nav tabs>
					<NavItem>
						<span className={'nav-link ' + classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
							Tabel
						</span>
					</NavItem>

					<NavItem>
						<span className={'nav-link ' + classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
							Boom
						</span>
					</NavItem>	
					<NavItem>
						<span className={'nav-link ' + classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
							Grootverbruikers
						</span>
					</NavItem>	
					<NavItem>
						<span className={'nav-link ' + classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
							Kleinverbruikers
						</span>
					</NavItem>			
				</Nav>
              	

                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-default">
                         	<TabContent activeTab={this.state.activeTab}>
					          	<TabPane tabId="1">
		                            <CardBody>
		                            	{<UserTable data={users} displayParentUser={displayParentUser} displayUsage={true} displayEditRoute={(editRoute ? true : false)}/>}
		                            </CardBody>
	                            </TabPane>

					          	<TabPane tabId="2">
		                            <CardBody>
		                            	{this._renderUserTree( users )}
		                            </CardBody>
	                            </TabPane>
								<TabPane tabId="3">
		                            <CardBody>
									{<UserTable data={_.filter(users, function(user) {return user.usage_type_value == 'large'})} displayParentUser={displayParentUser} displayUsage={true} displayEditRoute={(editRoute ? true : false)}/>}
		                            </CardBody>
	                            </TabPane>
								<TabPane tabId="4">
		                            <CardBody>
									{<UserTable data={_.filter(users, function(user) {return user.usage_type_value == 'small'})} displayParentUser={displayParentUser} displayUsage={true} displayEditRoute={(editRoute ? true : false)}/>}
		                            </CardBody>
	                            </TabPane>
								
                            </TabContent>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        subUsers: state.User.sub_users,
        allUsers: state.User.all_users,
        dbProfile: state.User.db_profile,
        userType: state.UserType,
        routes: state.Auth.routes,
        user: state.User.user_profile ? state.User.user_profile : false,
    }
}



export default withRouter(connect(mapStateToProps)(UserOverview))