import actions from './actions';

const initState = { 
	locations: [],
	locationStatus : {},
	statusOpen : false,
	syncActionPending : false
};

export default function locationReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_LOCATIONS:
			return { 
				...state, 
				locations: action.payload
			};	

		case actions.LOGOUT:
			return initState;

		case action.SYNC_ACTION_PENDING:
			return {
				...state,
				syncActionPending : action.payload
			};

		case actions.SET_LOCATION_STATUS:
			return {
				...state,
				locationStatus : action.payload
			};

		case actions.STATUS_POPUP_OPEN:
			return {
				...state,
				statusOpen : action.payload
			};

		default:
			return state;
	}
}
