import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    Card,
    CardBody
     } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import FuelingTable from './FuelingTable';

class FuelingHistory extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	user: false,
        	fuelingHistory : [],
        	userObject : [],
			type : false,
			self : false
        }             
    }

    _mapFuelingHistory = (userList, type = false) => {
    	let fuelingHistory = {};
    	

    	for ( let userID in userList ) {
    		if ( userList.hasOwnProperty(userID) ) {
    			const user = userList[userID];

    			if ( type ) {
    				if ( user.usage_type_value !== type ) {
    					continue;
    				}
    			}

    			fuelingHistory = {
    				...fuelingHistory,
    				...user.fueling_history
    			}
    		}
    	}

    	return fuelingHistory;
    }

    componentDidMount () {	
    	this._setFuelingHistory();
    }

    componentDidUpdate (prevProps) {
    	if ( this.props.match.params ) {
    		if (this.props.match.params.type) {
    			if ( this.props.match.params.type === 'sub' ) {
    				if ( this.props.subUsers !== prevProps.subUsers ) {
    					this._setFuelingHistory();
    				}
    			}else {
    				if ( this.props.allUsers !== prevProps.allUsers ) {
    					this._setFuelingHistory();
    				}
    			}
    		}

    	} else {
			
    		if ( this.props.fuelingHistory.self !== prevProps.fuelingHistory.self ) {				
    			this._setFuelingHistory();
    		}
		}
		
		if ( this.props.fuelingHistory !== prevProps.fuelingHistory ) {							
			this._setFuelingHistory();
		}

    }

    _setFuelingHistory = () => {
    	let fuelingHistory = this.props.fuelingHistory.self;
    	let userObject = this.props.user;
		let self = true;
    	if ( this.props.match.params ) {
			
    		switch (this.props.match.params.type) {
    			case 'all':
    				userObject = this.props.allUsers;
					fuelingHistory = this._mapFuelingHistory(userObject);
					self = false;
    			break;

    			case 'small':
    				userObject = this.props.allUsers;
					fuelingHistory = this._mapFuelingHistory(userObject, 'small');
					self = false;
    			break;

    			case 'large':
    				userObject = this.props.allUsers;
					fuelingHistory = this._mapFuelingHistory(userObject, 'large');
					self = false;
    			break;

    			case 'sub':
    				userObject = this.props.subUsers;
					fuelingHistory = this._mapFuelingHistory(userObject);
					self = false;
    			break;    			
    		}
    	}    	
		
		

    	this.setState({
    		fuelingHistory : fuelingHistory,
			userObject : userObject,
			'self' : self
    	})
    }

    _getType = () => {
    	if ( this.props.user ) {
    		if ( this.props.user.usage_type_value === 'small' ) {
    			return 'small';
    		} else {
    			if ( this.props.match.params ) {
    				return this.props.match.params.type;
    			}
    		}
    	}
    }
   
    render() {    	
   
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Afnamegeschiedenis</div>
                </div>
              
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
                               	<FuelingTable data={this.state.fuelingHistory} userObject={this.state.userObject} self={this.state.self} type={this._getType()}/>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
       	fuelingHistory : state.Fueling.fuelingHistory,
       	subUsers: state.User.sub_users,
        allUsers: state.User.all_users,
        user: state.User.user_profile ? state.User.user_profile : false
    }
}

export default withRouter(connect(mapStateToProps)(FuelingHistory))