import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Table from '../../Table/Table';
import Modal from 'react-modal';
import { toast } from 'react-toastify'; // Make sure toast is imported, for some reason it not imported in the saga.

import {
    Card,
    CardBody
     } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ContentWrapper from '../../Layout/ContentWrapper';
import locationActions  from '../../../redux/location/actions';

const { syncLocation } = locationActions;
const { getLocationStatus } = locationActions; 

const customStyles = {
    content : {
        top                   : '50%',
        left                  : '50%',
        right                 : 'auto',
        bottom                : 'auto',
        marginRight           : '-50%',
        transform             : 'translate(-50%, -50%)',
    },
    overlay: {
        zIndex  : '999'
    }
};

Modal.setAppElement('#app');

class LocationOverview extends Component {

    constructor(props) {
        super(props)
        this.state = {user: false, modalOpen: false}    
    }

    componentDidMount() {
    	
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.statusOpen !== this.props.statusOpen && this.props.statusOpen !== this.state.modalOpen) {
            this.setState({modalOpen : this.props.statusOpen});
        }
    }

    _formSyncSubmit = (event, id) => {
        event.preventDefault();

        toast('Synchronisatie wordt gestart', {
            type: 'success',
            position: 'top-right'
        })

        let formData = new FormData();
        formData.append('aquabox_id', id);

        this.props.syncLocation(formData);
    }

    _getStatus = (event, id, name) => {
        event.preventDefault();

        toast('Status wordt opgehaald', {
            type: 'success',
            position: 'top-right'
        })

        let formData = new FormData();
        formData.append('aquabox_id', id);
        formData.append('name', name);

        this.props.getLocationStatus(formData);
    }

    _closeModal = () => {
        this.setState({modalOpen : false});
    }

    render() {
    	
    	const editRoute = this.props.routes.find(item => item.route === '/edit_location/:location_id');
        const columns = [
            {
                Header: 'Plaats',
                id: 'city',
                accessor: 'city' // String-based value accessors!
            },
            {
                Header: 'Adres',
                id: 'name',
                accessor: 'name' // String-based value accessors!
            },
            
            {
                Header: 'Type',
                accessor: 'locationTypeValue', // String-based value accessors!
                id: 'locationTypeValue',
                Cell : (props) =>  props.original.locationType.label 
            },
            {
                Header: 'Volume price',
                accessor: 'price_per_volume',
                sortMethod: (a, b) => {
                	a = parseFloat(a);
                	b = parseFloat(b);
                    if (a === b) {
                      return a > b ? 1 : -1;
                    }
                    return a > b ? 1 : -1;
                  }
            },
           	{
           		Header : 'Actief',
           		accessor : 'active',
           		Cell : (props) => (<div>{ props.original.active ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}  </div>)
           	}
        ];

        if(editRoute) {
        	columns.push({
	                Header: 'Acties',
	                id: 'actions',
	                Cell: props => 
                    <div className='location_actions'>
                        <NavLink to={"/edit_location/"+props.original.id}><i className="fa fa-edit"></i></NavLink>
                        <a href="/#" onClick={(event) => this._formSyncSubmit(event, props.original.id)} rel="Synchronise fueling stats">
                            <i className="fa fa-sync"></i>
                        </a>
                        <a href="/#" onClick={(event) => this._getStatus(event, props.original.id, props.row.name)}>
                            <i className="fa fa-file-medical-alt"></i>
                        </a>
                    </div>
	            });
        }

        var sensorData = this.props.status.data ? this.props.status.data : {};
        const sensorList = Object.keys(sensorData).map(
            key => <div key={key}>{key} :<span className="status_value">{sensorData[key]}</span></div>
        )

        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Locatie overzicht                        
                    </div>
                </div>
                
                <Modal
                    isOpen={this.state.modalOpen}
                    onRequestColse={this._closeModal}
                    style={customStyles}
                >
                <h2>Aquabox {this.props.status.name}</h2>
                <div>Aquabox ID: <span className="status_value">{this.props.status.aquabox_id}</span></div>
                <div>Status: <span className="status_value">{this.props.status.status}<span className={this.props.status.status}><i className="fas fa-circle"></i></span></span></div>
                <div className="sensor_wrapper">
                    {sensorList}
                </div>
                <button onClick={this._closeModal}>Sluit</button>
                </Modal>


                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
                                <Table data={this.props.locations ? Object.values(this.props.locations) : false} columns={columns} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}

const mapStateToProps = (state, ownProps) => {

    return {
       	locations : state.Location.locations,
        status : state.Location.locationStatus,
        statusOpen : state.Location.statusOpen,
        syncActionPending : state.Location.syncActionPending,
       	routes: state.Auth.routes
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        syncLocation: (data) => dispatch(syncLocation(data)),
        getLocationStatus: (data) => dispatch(getLocationStatus(data))
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationOverview))