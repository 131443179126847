import { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import authAction from '../../../redux/auth/actions';

const { logout } = authAction;

class Logout extends Component {

	componentWillMount() {
		this.props.logout();
	}


    render() {
     	return null;   
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(logout()),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps, false, {pure: false})(Logout))