import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card,
    CardBody,
    FormGroup,
    Input,
    Label,
    Row,
    Col
     } from 'reactstrap';
     import { toast } from 'react-toastify';

import ContentWrapper from '../../Layout/ContentWrapper';

import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import Select from 'react-select';
import _ from 'lodash';
import actions from '../../../redux/fueling/actions';

const Overlay = props => <div style={{width: '100%', height: '100%', top: 0, left: 0, position: 'absolute', background: 'rgba(255, 255, 255, .5)', zIndex: 9999999999}}>
<div className="spinner" />
</div>;

class Invoice extends Component {

    

    constructor(props) {
        super(props)
        this.state = {
			user_type_id: false, 
			select_usage_type: false,
        	userSelectValue: false, 
        	exportSelectValue : {
    			value : 'XLS',
    			label : 'XLS'
    		}
    	}
    }
    
    _mapUserTypes(key) {
        return (
        	<FormGroup check>
          	<Label check>

                <Input type="radio" onChange={(e) => this._handleRadioChange(e)} name="select_client" value={this.props.userType.data[key].id} />
                    {this.props.userType.data[key].type}
                
            </Label>
            </FormGroup>
        )        
    }

    _handleRadioChange(e){        
        this.setState({user_type_id: e.currentTarget.value, selectValue : false})
        
	}
	_handleRadioUsageChange(e) {
		this.setState({select_usage_type: e.currentTarget.value})
	}

    _mapOptions = (key) => {
       
        if(this.state.user_type_id)
        {
            if(this.state.user_type_id === this.props.user.all_users[key].user_type_id && this.props.user.all_users[key].user_type_id !== 'undefined')
            {
                return ({value: this.props.user.all_users[key].id, label :this.props.user.all_users[key].firstname})
            }
        }
        else
        {
            return ({value: this.props.user.all_users[key].id, label :this.props.user.all_users[key].firstname})
        }

        return false;
        
    }

    _handleUserSelectChange = (value) => {
    	this.setState({
    		userSelectValue : value
    	})
    }

     _handleExportSelectChange = (value) => {
    	this.setState({
    		exportSelectValue : value
    	})
    }

    _getOptions() {
		let options = [];
		let allUsers = this.props.user.all_users;
		if(this.state.select_usage_type == 'small') {
			allUsers = _.filter(allUsers, function(user) {return user.usage_type_value == 'small'});
		}

		if(this.state.select_usage_type == 'large') {
			allUsers = _.filter(allUsers, function(user) {return user.usage_type_value == 'large'});
		}

	
    	for ( let userID in  allUsers) {
    		if ( allUsers[userID] ) {
    			const user = allUsers[userID];

    			if ( this.state.user_type_id ) {
    				if ( parseInt(this.state.user_type_id) === parseInt(user.user_type_id) && user.user_type_id !== 'undefined' ) {

    					options.push({
		    				value : user.firebase_user_id,
		    				label : (user.company ? user.company : user.firstname + ' ' + user.lastname)
		    			})
    				}
    			} else {
    				options.push({
	    				value : user.firebase_user_id,
	    				label : (user.company ? user.company : user.firstname + ' ' + user.lastname)
	    			})
    			}
    		}
    	}

    	return options;
    }

    _getExportOptions() {
    	return [
    		{
    			value : 'XLS',
    			label : 'XLS'
    		},
    		{
    			value : 'CSV',
    			label : 'CSV'
    		}
    	];
    }

    _submitExportForm = (e) => {
    	e.preventDefault();

    	let readyForSubmission = true;
    	if ( this.state.from && this.state.to && this.state.exportSelectValue ) {
    		
    		if( this.state.from_moment.isBefore(this.state.to_moment) ) {
	    		// if ( !this.state.userSelectValue ) {
	    		// 	toast('Selecteer minimaal één gebruiker', {
				//         type: 'error',
				//         position: 'top-right'
				//     })
	    			
	    		// 	readyForSubmission = false;
	    		// }


	    		/*else {
	    			if ( this.state.userSelectValue ) {
	    				// -- Send only selected users

	    			} else {
	    				toast('Selecteer minimaal een gebruikerstype of één gebruiker', {
					        type: 'error',
					        position: 'top-right'
					    })
	    			}
	    		}*/
    		} else {
    			toast('De van-datum moet vóór de tot-datum liggen', {
			        type: 'error',
			        position: 'top-right'
			    })
			    readyForSubmission = false;
		    }	
    	} else {
    		toast('Selecteer een van-tot datum', {
		        type: 'error',
		        position: 'top-right'
		    })
		    readyForSubmission = false;
    	}

    	if ( readyForSubmission ) {
    		this.props.actions.getInvoiceFile({ users : this.state.userSelectValue, dateFrom : this.state.from, dateTo : this.state.to, type: this.state.exportSelectValue.value })
    	}

    
    }

    _setDate = (e, type) => {
		
		this.setState({
			[type] : e.format('D-M-Y'),
			[type + '_moment'] : e
		})
		
    }
    
    render() {
		
        return (
            <ContentWrapper>
            { this.props.working ? <Overlay /> : null }
            	<form>
	                <Card>
	                    <CardBody>
						<Row>
						<Col xl={ 6 }>
	                    <h3>Kies type</h3>
	                  
	                        {this.props.userType.data ? Object.keys(this.props.userType.data).map((key) => this._mapUserTypes(key)) : null}	                   	
						</Col>
						<Col xl={ 6 }>
							<h3>Kies groot- of kleinverbruiker</h3>
							<FormGroup check>
							<Label check>

								<Input type="radio" onChange={(e) => this._handleRadioUsageChange(e)} name="select_usage_type" value="small" />
									Kleinverbruiker
								
							</Label>
							</FormGroup>
							<FormGroup check>
							<Label check>

								<Input type="radio" onChange={(e) => this._handleRadioUsageChange(e)} name="select_usage_type" value="large" />
									Grootverbruiker
								
							</Label>
							</FormGroup>
						</Col>
	                    </Row>    
	                    <h3>Kies periode</h3>
	                    
						<Row>
							<Col xl={ 6 }>
								<FormGroup>
									<Label>Datum vanaf</Label>
									<Datetime input={false} onChange={(e) => this._setDate(e, 'from')} dateFormat="YYYY-MM-DD" timeFormat={false} inputProps={{className: 'form-control'}}/>
								</FormGroup>
							</Col>
							<Col xl={ 6 }>
								<FormGroup>
									<Label>Datum tot</Label>
									<Datetime input={false} onChange={(e) => this._setDate(e, 'to')} dateFormat="YYYY-MM-DD" timeFormat={false} inputProps={{className: 'form-control'}}/>
								</FormGroup>
							</Col>
						</Row>

	                    <h3>Kies klant</h3>
	                    <FormGroup>
	                        <label>Gebruikers</label>                      
	                            <Select
	                                name="multi-select-name"                                            
	                                simpleValue={true}    
	                                onChange={this._handleUserSelectChange}
	                                options={this._getOptions()}
	                                isMulti={true}
	                                value={this.state.userSelectValue}
	                            />
	                    </FormGroup>

	                    <h3>Export type</h3>
	                    <FormGroup>
	                        <label>Export type</label>                      
	                            <Select
	                                name="multi-select-export"                                            
	                                simpleValue={true}    
	                                onChange={this._handleExportSelectChange}
	                                options={this._getExportOptions()}
	                                value={this.state.exportSelectValue}
	                            />
	                    </FormGroup>
	                     <p>
	                    	Het is verplicht een gebruikersselectie te maken (gebruikers type en/of gebruiker) en een van-tot datum te selecteren. <br />
	                    	Selecteer ook een export-type.
	                    </p>
	                    <button className="btn btn-sm btn-primary" type="submit" onClick={this._submitExportForm}>Genereren</button>

	                    { this.props.fileLink ? <span><br /><br /><a className="btn btn-sm btn-primary" target="_BLANK" href={this.props.fileLink}>Downloaden</a></span> : null}
	                    </CardBody>
	                </Card>
	            </form>
            </ContentWrapper>
            );
    }

}

const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) })

const mapStateToProps = (state, ownProps) => {

    return {
       	locations : state.Fueling.fuelingLocations,
       	working : state.Fueling.invoiceWorking,
       	fileLink : state.Fueling.invoice_file_link,
       	routes: state.Auth.routes,
       	user: state.User,
        dbProfile: state.User.db_profile,
        userType: state.UserType,
        fuelingActionPending : state.Fueling.fuelingActionPending,
        pinPopupVisible : state.Fueling.pinPopupVisible
    }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Invoice))