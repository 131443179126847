import actions from './actions';

const initState = { 
	paymentUrl: null,
	credit: '0,00'
};

export default function creditReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_PAYMENT_URL:
		
			return { 
				...state, 
				paymentUrl: {					
					...action.payload
				}
			};	
		case actions.SET_CREDIT:
			let credit = '0,00';
			if(action.payload) {
				if(action.payload.credit) {
					credit = action.payload.credit
				}
				
			}

			return {
				...state,
				credit: credit
			}	
			
		case actions.SET_PAYMENT_HISTORY:
			return {
				...state,
				paymentHistory: action.payload
			}
		default:
			return state;
	}
}
