import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import { fetchData } from '../../helpers/fetch';
import { toast } from 'react-toastify';
import { getToken, getClientPath } from '../../helpers/utility';

const { rsfFirestore } = FirebaseHelper;

export function* updateCredit() {
	yield takeEvery(actions.UPDATE_CREDIT, function*(action) {

		const user = FirebaseHelper.auth().currentUser;

		let formData = new FormData();
		formData.append('payment_method', action.payload.payment_method);
		formData.append('user_credit', action.payload.user_credit);
		formData.append('token', user.uid);



		// -- Send formdata to API
		const response = yield call(
			fetchData,
			getClientPath() + '/credit/add_credit',
			formData
		)

		if(response.notification) {

			toast(response.notification, {
		        type: response.type,
		        position: 'top-right'
		    })


		    if(response.firebaseRecord) {


		    	// -- create new collection and set document
		    	yield call(rsfFirestore.setDocument, 'user_payment_history/'+user.uid+'/payments/'+response.firebaseRecord.payment_id, response.firebaseRecord)


	    		yield put({
					type: actions.SET_PAYMENT_URL,
					payload: response.paymentUrl
				})
		    }


		}

	})
}



export function* syncPaymentHistoryCollection() {
	const localUserData = getToken();



	const user_id = localUserData.get('userId');

	if ( user_id ) {
		// -- Sync user document and set user properties with snapped data on change.
		yield fork(
			rsfFirestore.syncCollection,
			'user_payment_history/'+ user_id + '/payments',
			{ successActionCreator: (snap) => actions.setPaymentHistory(FirebaseHelper.processFireStoreCollection(snap)), failureActionCreator : () => actions.failure()}
		);
	}
}

export function* fetchUserPaymentHistory() {
	yield takeEvery(actions.FETCH_PAYMENT_HISTORY, function*(action) {
		const snapshot = yield call(rsfFirestore.getCollection, 'user_payment_history/'+action.payload + '/payments');

		const processedCollection = FirebaseHelper.processFireStoreCollection(snapshot);

		yield put({
			type: actions.SET_PAYMENT_HISTORY,
			payload: processedCollection
		})
	})
}

export function* fetchCredit() {
	yield takeEvery(actions.FETCH_CREDIT, function*(action) {
		const snapshot = yield call(rsfFirestore.getDocument, 'credit/'+action.payload);

		const data = snapshot.data();
		yield put({
			type: actions.SET_CREDIT,
			payload: data
		})
	})
}


export function* syncCredit() {
	const localUserData = getToken();

	const user_id = localUserData.get('userId');

	if ( user_id ) {

		// -- Sync user document and set user properties with snapped data on change.
		yield fork(
			rsfFirestore.syncDocument,
			'credit/'+user_id,
			{ successActionCreator: (snap) => actions.setCredit(snap.data()), failureActionCreator : () => actions.failure()}
		);
	}


}

export function* failure() {
	yield takeEvery(actions.FAILURE, function*(action) {



	})
}

export default function* rootSaga() {
	yield all([
		fork(updateCredit),
		fork(fetchCredit),
		fork(syncCredit),
		fork(fetchUserPaymentHistory),
		fork(syncPaymentHistoryCollection),
		fork(failure)
	]);
}
