import React, { Component } from 'react';
import { withRouter } from 'react-router';
import ContentWrapper from '../../Layout/ContentWrapper';
import { Card, CardBody, FormGroup, Input, Label, TabContent, TabPane,  Nav, NavItem } from 'reactstrap';
import { connect } from 'react-redux';
import userAction from '../../../redux/user/actions';
import { toast } from 'react-toastify';
import {hasError} from '../../../helpers/formHelper';
import Select from 'react-select';
import classnames from 'classnames';
import UserTable from './UserTable';
import PaymentTable from './PaymentTable';
import FuelingTable from '../Fueling/FuelingTable';
import { NavLink } from 'react-router-dom';
import FirebaseHelper from '../../../helpers/firebase';

const { rsfFirestore,firebase } = FirebaseHelper;

const { addUser, updateSubUser } = userAction;

class AddUser extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	user: {
        		phone_number : "+31",
        		quotum_infinite : true
        	},
        	activeTab: '1'}
    }

    componentDidMount() {

    	if(this.props.match.params.user_index) {
			this._getPaymentHistory(this.props.match.params.user_index)
    		let user = false;
    		if ( this.props.subUsers[this.props.match.params.user_index] ) {
    			user = {...this.props.subUsers[this.props.match.params.user_index]};
    		}

    		if ( !user || this.props.allUsers !== false ) {

    			if ( this.props.allUsers[this.props.match.params.user_index] ) {
	    			user = {...this.props.allUsers[this.props.match.params.user_index]};
	    		}
    		}

    		if(user) {
    			this.setState({	user })
    		}
    	}

    }

    componentDidUpdate(prevProps, prevState) {
    	if(this.props.match.params.user_index) {

    		let user = false;
    		if ( this.props.subUsers[this.props.match.params.user_index] ) {
    			user = {...this.props.subUsers[this.props.match.params.user_index]};
    		}

    		if ( !user || this.props.allUsers !== false ) {

    			if ( this.props.allUsers[this.props.match.params.user_index] ) {
	    			user = {...this.props.allUsers[this.props.match.params.user_index]};
	    		}
    		}

    		if ( user.fueling_history !== this.state.user.fueling_history ) {
    			if(user) {
	    			this.setState({	user })
	    		}
    		}
    	}

    }


    onSubmit = e => {
        e.preventDefault();

        const hasErrors = this._findAllErrors();

        if(!hasErrors) {
        	if(this.state.user.firebase_user_id) {
        		this.props.updateSubUser(this.state.user);
        	}else{
        		this.props.addUser(this.state.user);
        	}

        }else{
        	toast('Let alstublieft op de foutmeldingen.', {
		        type: 'error',
		        position: 'top-right'
		    })
        }
    }

    _findAllErrors = () => {

    	if(this.state.user.firebase_user_id) {

			if(!this.state.user.userType || this.state.user.userType.length == 0) {
				toast('Gebruikerstype mag niet leeg zijn.', {
					type: 'error',
					position: 'top-right'
				})
				return true;
			}

			if(!this.state.user.usageType || this.state.user.usageType.length == 0) {
				toast('Type verbruik mag niet leeg zijn.', {
					type: 'error',
					position: 'top-right'
				})
				return true;
			}

			if(this.state.user.tancode) {
				if(this.state.user.is_valid_type == '') {
					toast('U bent vergeten de geldigheid van de code aan te geven.', {
						type: 'error',
						position: 'top-right'
					})
					return true;
				}
			}
            if ( this.state.user.update_password ) {

                if ( this.state.user.password !== this.state.user.password_repeat) {

                    return true;
                }
            }
    		if(
	    		!hasError('firstname', this.state.user, false, true) &&
	    		!hasError('lastname', this.state.user, false, true) &&
	    		!hasError('phone_number', this.state.user, false, true)
	    	) {
	    		return false;
	    	}else{
	    		return true;
	    	}
		}



		if(!this.state.user.userType || this.state.user.userType.length == 0) {
			toast('Gebruikerstype mag niet leeg zijn.', {
				type: 'error',
				position: 'top-right'
			})
			return true;
		}

		if(!this.state.user.usageType || this.state.user.usageType.length == 0) {
			toast('Type verbruik mag niet leeg zijn.', {
				type: 'error',
				position: 'top-right'
			})
			return true;
		}

		if(this.state.user.tancode) {
			if(!this.state.user.is_valid_type || this.state.user.is_valid_type == '') {
				toast('U bent vergeten de geldigheid van de code aan te geven.', {
					type: 'error',
					position: 'top-right'
				})
				return true;
			}
		}


    	if(
    		!hasError('firstname', this.state.user, false, true) &&
    		!hasError('lastname', this.state.user, false, true) &&
    		!hasError('email', this.state.user, false, true) &&
    		!hasError('phone_number', this.state.user, false, true) &&
    		!hasError('displayName', this.state.user, false, true) &&
    		!hasError('password', this.state.user, 'password_repeat', true)
    	) {
    		return false;
    	}else{
    		return true;
    	}

    }

    _updateField = (field, event) => {

    	let value = event.target.value;
    	if(event.target.type === 'checkbox') {
    		if(event.target.checked) {
    			value = true;
    		}else{
    			value = false;
    		}
		}

		if (event.target.type === 'tancode') {
			if (value.length != 6) {
				value = false;
				toast('Tancode wordt genegeerd, veld heeft een incorrecte waarde.', {
					type: 'error',
					position: 'top-right'
				})
			}
		}

    	this.setState({
    		user: {
    			...this.state.user,
    			[field] : value
    		}
		})


    }

    _handleSelectChange = (event) => {
        this.setState({
            user: {
                ...this.state.user,
                'userType' : event
            }
		})


    }

    _handleSelectChangeUsage = (event) => {
    	let user_pays_later = false;
    	if ( event.value === 'large') {
    		user_pays_later = true;
    	}
        this.setState({
            user: {
                ...this.state.user,
                usageType : event,
                user_pays_later
            }
        })
    }

    _mapOptions = (key) => {
        return ({value: this.props.creatableUserTypes[key].id, label :this.props.creatableUserTypes[key].type})
    }

    _getUsageTypeOptions = (key) => {

    	if ( !this.props.user ) {
    		return [];
    	}
    	if ( this.props.user.user_type_id === 5 ) {
    		return [
	    		{
	    			value: 'large',
	    			label : 'Grootverbruik'
	    		},
	    		{
	    			value: 'small',
	    			label : 'Kleinverbruik'
	    		}
	    	]
    	} else {
    		let options = [];
    		options.push(this.props.user.usageType);
    		return options;
    	}

    }

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

	_getPaymentHistory(userId)
	{
		let items = [];
		firebase.firestore().collection('user_payment_history/'+userId + '/payments/').get().then((snapshot) => {

			snapshot.docs.forEach(doc => {
				let item = doc.data();
				items.push(item);
				/* Make data suitable for rendering */
				//items = JSON.stringify(items);


			});
		});

		/* Update the components state with query result */
		this.setState({ paymentHistory : items })


	}

    render() {
       
    	const displaySubUsers = this.state.user.sub_users ? Object.keys(this.state.user.sub_users).length ? true : false : false;
    	const displayFuelingHistory = this.state.user.fueling_history ? Object.keys(this.state.user.fueling_history).length ? true : false : false;
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Gebruiker {this.state.user.firebase_user_id ? 'wijzigen' : 'aanmaken'}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
					{this.state.user.firebase_user_id ?
                    <Nav tabs>
						<NavItem>
							<span className={'nav-link ' + classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
								Gebruiker
							</span>
						</NavItem>


							<NavItem>
								<span className={'nav-link ' + classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
									Gebruikers
								</span>
							</NavItem>



							<NavItem>
								<span className={'nav-link ' + classnames({ active: this.state.activeTab === '3' })} onClick={() => { this.toggle('3'); }}>
									Afnamegeschiedenis
								</span>
							</NavItem>
							<NavItem>
								<span className={'nav-link ' + classnames({ active: this.state.activeTab === '4' })} onClick={() => { this.toggle('4'); }}>
									Betaalgeschiedenis
								</span>
							</NavItem>
					</Nav>
					: null }
				        <TabContent activeTab={this.state.activeTab}>
				          	<TabPane tabId="1">
					             <Card className="card-default">
		                            <CardBody>
		                             <NavLink className="btn btn-sm btn-secondary" style={{marginBottom: '25px'}} to="/user_overview"><i className="fa fa-chevron-left"></i>&nbsp;Terug naar overzicht</NavLink>
		                                <form onSubmit={this.onSubmit}>

		                                	 <FormGroup>
		                                    	<label>Gebruikerstype</label>
				                                   <Select
				                                        name="multi-select-name"
				                                        simpleValue={true}
				                                        value={this.state.user.userType}
				                                        onChange={this._handleSelectChange}
				                                        options={this.props.creatableUserTypes ? Object.keys(this.props.creatableUserTypes).map((key) => this._mapOptions(key)) : null}
				                                    />
				                            </FormGroup>

		                                     <FormGroup>
		                                    	<label>Type verbruik</label>
		                                    	<Select
			                                        name="multi-select-name"
			                                        simpleValue={true}
			                                        value={this.state.user ? this.state.user.usageType ? this.state.user.usageType : null : null}
			                                        onChange={this._handleSelectChangeUsage}
			                                        options={
			                                        	this._getUsageTypeOptions()
			                                        }
			                                    />
		                                    </FormGroup>

                                        

		                                    {this.props.canChangeQuotum ?
		                                    	<span >
													<FormGroup check style={{display: 'none'}}>
														<Label check>
															<Input  onChange={(event) => this._updateField('user_pays_later', event)} type="checkbox" checked={this.state.user.user_pays_later ? this.state.user.user_pays_later : false}/>{' '}
															Gebruiker betaalt achteraf
														</Label>
													</FormGroup>

													<FormGroup check style={{display: 'none'}}>
														<Label check>
															<Input  onChange={(event) => this._updateField('quotum_infinite', event)} type="checkbox" checked={this.state.user.quotum_infinite ? this.state.user.quotum_infinite : false}/>{' '}
															Onbeperkt quotum
														</Label>
													</FormGroup>


			                      					{!this.state.user.quotum_infinite ?
					                                     <FormGroup>
					                                        <label>Quotum (liters)</label>
					                                        <Input onChange={(event) => this._updateField('quota', event)} type="number" placeholder="Quotum" value={this.state.user.quota ? this.state.user.quota : ''}/>
					                                        <span style={hasError('quota', this.state.user)} className="invalid-feedback">Veld is verplicht</span>
					                                    </FormGroup>
						                            : null }
				                                </span>
			                                 : null }

											{this.props.user.user_type_id === 5 || this.props.user.user_type_id === 1 ?
												<span>
												<FormGroup>
													<label>Statische Pincode</label>
													<Input onChange={(event) => this._updateField('tancode', event)} type="number" minLength="6" maxLength="6" placeholder="Pincode" value={this.state.user.tancode ? this.state.user.tancode : ''}/>
													<span style={{marginTop: '0.25rem', fontSize: '80%', width: '100%'}} className="information-feedback">Deze code kan zonder APP worden gebruikt.</span>
													<span style={hasError('tancode', this.state.user)} className="invalid-feedback">Voer een 6 cijferig nummer in. </span>
												</FormGroup>
												<FormGroup>
												<label>Hoelang geldig?</label>
													<div style={{'marginLeft' : '20px'}}><Input onChange={(event) => this._updateField('is_valid_type', event)} name="is_valid_type" type="radio" value='HOUR' defaultChecked={this.state.user.is_valid_type == 'HOUR' ? true : false } /><label>1 uur</label></div>
													<div style={{'marginLeft' : '20px'}}><Input onChange={(event) => this._updateField('is_valid_type', event)} name="is_valid_type" type="radio" value='DAY' defaultChecked={this.state.user.is_valid_type == 'DAY' ? true : false } /><label>1 dag</label></div>
													<div style={{'marginLeft' : '20px'}}><Input onChange={(event) => this._updateField('is_valid_type', event)} name="is_valid_type" type="radio" value='WEEK' defaultChecked={this.state.user.is_valid_type == 'WEEK' ? true : false } /><label>1 week</label></div>
													<div style={{'marginLeft' : '20px'}}><Input onChange={(event) => this._updateField('is_valid_type', event)} name="is_valid_type" type="radio" value='MONTH' defaultChecked={this.state.user.is_valid_type == 'MONTH' ? true : false } /><label>1 maand</label></div>
													<div style={{'marginLeft' : '20px'}}><Input onChange={(event) => this._updateField('is_valid_type', event)} name="is_valid_type" type="radio" value='YEAR' defaultChecked={this.state.user.is_valid_type == 'YEAR' ? true : false } /><label>1 jaar</label></div>
												</FormGroup>
												</span>
											: null}

		                                    {!this.state.user.firebase_user_id ?
			                                	<FormGroup>
			                                        <label>Gebruikersnaam</label>
			                                        <Input onChange={(event) => this._updateField('displayName', event)} type="text" placeholder="Gebruikersnaam" value={this.state.user.displayName}/>
			                                    	<span style={hasError('displayName', this.state.user)} className="invalid-feedback">Veld is verplicht</span>
			                                    </FormGroup>
			                                : null }

											{this.props.user.user_type_id === 5 ?
												<FormGroup>
													<label>Kortingspercentage</label>
													<span style={{position: 'absolute', top: '36px', right: '35px'}}>% </span>
													<Input onChange={(event) => this._updateField('discount_amount', event)} type="number" min="0" max="100" placeholder="Korting" value={this.state.user.discount_amount ? this.state.user.discount_amount : 0}/>
													<span style={{marginTop: '0.25rem', fontSize: '80%', width: '100%'}} className="information-feedback">Korting wordt doorberekend op de literprijs.</span>
												</FormGroup>
											: null }

		                                    <FormGroup>
		                                        <label>Voornaam</label>
		                                        <Input onChange={(event) => this._updateField('firstname', event)} type="text" placeholder="Voornaam" value={this.state.user.firstname ? this.state.user.firstname : ''}/>
		                                    	<span style={hasError('firstname', this.state.user)} className="invalid-feedback">Veld is verplicht</span>
		                                    </FormGroup>
		                                     <FormGroup>
		                                        <label>Achternaam</label>
		                                        <Input  onChange={(event) => this._updateField('lastname', event)} type="text" placeholder="Achternaam" value={this.state.user.lastname ? this.state.user.lastname : ''}/>
		                                    	<span style={hasError('lastname', this.state.user)} className="invalid-feedback">Veld is verplicht</span>
		                                    </FormGroup>
		                                     <FormGroup>
		                                        <label>Bedrijf</label>
		                                        <Input  onChange={(event) => this._updateField('company', event)} type="text" placeholder="Bedrijf" value={this.state.user.company ? this.state.user.company : ''}/>
		                                    </FormGroup>
		                                    <FormGroup>
		                                        <label>Postcode</label>
		                                        <Input  onChange={(event) => this._updateField('zipcode', event)} type="text" placeholder="Postcode" value={this.state.user.zipcode ? this.state.user.zipcode : ''}/>
		                                    </FormGroup>
		                                    <FormGroup>
		                                        <label>Plaats</label>
		                                        <Input  onChange={(event) => this._updateField('city', event)} type="text" placeholder="Plaats" value={this.state.user.city ? this.state.user.city : ''}/>
		                                    </FormGroup>
		                                     <FormGroup>
		                                        <label>Telefoonnummer</label>
		                                        <Input  onChange={(event) => this._updateField('phone_number', event)} type="text" placeholder="Telefoonnummer" value={this.state.user.phone_number ? this.state.user.phone_number : ''}/>
		                                    	<span style={hasError('phone_number', this.state.user)} className="invalid-feedback">Veld is verplicht</span>
		                                    </FormGroup>
		                                    { this.props.user.user_type_id === 5  ?
		                                    <span>
		                                    	<FormGroup check >
													<Label check>
														<Input  onChange={(event) => this._updateField('user_active', event)} type="checkbox" checked={this.state.user.user_active ? this.state.user.user_active : false}/>{' '}
														Gebruiker actief
													</Label>
												</FormGroup>
												<br />
											</span>
											: null }
		                                     {!this.props.match.params.user_index ?
		                                     	<span>
				                                    <FormGroup>
				                                        <label>E-mail adres</label>
				                                        <Input onChange={(event) => this._updateField('email', event)} type="email" placeholder="E-mailadres" value={this.state.user.email}/>
				                                    	<span style={hasError('email', this.state.user)} className="invalid-feedback">Veld is verplicht</span>
				                                    </FormGroup>

				                                </span>
			                                :  null }
                                            {!this.props.match.params.user_index ?
												<span><FormGroup>
													<label>Wachtwoord</label>
													<Input onChange={(event) => this._updateField('password', event)} type="password" placeholder="Wachtwoord"/>
													<span style={hasError('password', this.state.user, 'password_repeat')} className="invalid-feedback">Wachtwoorden moeten gelijk zijn aan elkaar</span>
												</FormGroup>
												<FormGroup>
													<label>Wachtwoord herhalen</label>
													<Input onChange={(event) => this._updateField('password_repeat', event)} type="password" placeholder="Wachtwoord herhalen"/>
													<span style={hasError('password_repeat', this.state.user, 'password')} className="invalid-feedback">Wachtwoorden moeten gelijk zijn aan elkaar</span>
												</FormGroup>
											</span>

                                            : null }

                                            {this.props.match.params.user_index ?
                                                <span>
                                                <FormGroup check >
													<Label check>
														<Input  onChange={(event) => this._updateField('update_password', event)} type="checkbox" checked={this.state.user.update_password ? this.state.user.update_password : false}/>{' '}
														Wachtwoord wijzigen
													</Label>
												</FormGroup>
                                                <br />

                                                {this.state.user.update_password ?
                                                    <span>
                                                    <FormGroup>
    													<label>Wachtwoord</label>
    													<Input onChange={(event) => this._updateField('password', event)} type="password" placeholder="Wachtwoord"/>
    													<span style={hasError('password', this.state.user, 'password_repeat')} className="invalid-feedback">Wachtwoorden moeten gelijk zijn aan elkaar</span>
    												</FormGroup>
    												<FormGroup>
    													<label>Wachtwoord herhalen</label>
    													<Input onChange={(event) => this._updateField('password_repeat', event)} type="password" placeholder="Wachtwoord herhalen"/>
    													<span style={hasError('password_repeat', this.state.user, 'password')} className="invalid-feedback">Wachtwoorden moeten gelijk zijn aan elkaar</span>
    												</FormGroup>
                                                    </span>
                                                : null }

											</span>

                                            : null }

		                                    <button className="btn btn-sm btn-secondary" type="submit">Opslaan</button>
		                                </form>
		                            </CardBody>
		                        </Card>
				          	</TabPane>


							<TabPane tabId="2">
								<div style={{backgroundColor: "white"}}>
									<UserTable data={this.state.user.sub_users}  displayUsage={false} displayParentUser={false} displayEditRoute={true}/>
								</div>
							</TabPane>



							<TabPane tabId="3">
								<div style={{backgroundColor: "white"}}>
									<FuelingTable data={this.state.user.fueling_history}/>
								</div>
							</TabPane>
							<TabPane tabId="4">
								<div style={{backgroundColor: "white"}}>
									<PaymentTable data={this.state.paymentHistory}/>
								</div>
							</TabPane>

				        </TabContent>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.user_profile ? state.User.user_profile : false,
        canChangeQuotum: state.Auth.can_change_quotum,
        dbProfile: state.User.db_profile,
        creatableUserTypes: state.Auth.allowed_creatable_user_types,
        userType: state.UserType,
        subUsers: state.User.sub_users ? state.User.sub_users : false,
        allUsers : state.User.all_users ? state.User.all_users : false
    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        addUser: (data) => dispatch(addUser(data)),
        updateSubUser: (data) => dispatch(updateSubUser(data)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddUser))
