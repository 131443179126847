import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Table from '../../Table/Table';
import {
    Card,
    CardBody
     } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ContentWrapper from '../../Layout/ContentWrapper';
import fuelingActions  from '../../../redux/fueling/actions';
const { setPinPopupVisible } = fuelingActions;

class FuelingLocationOverview extends Component {

    constructor(props) {
        super(props)
        this.state = {user: false}       
    }

    componentDidMount() {
        this.props.setPinPopupVisible(false);
        
        console.log(this.props.locations)
    }
   
    render() {
    	
    	const fuelRoute = this.props.routes.find(item => item.route === '/fueling/:location_id');

        const columns = [
            {
                Header : 'Plaats',
                id : 'city',
            	accessor : 'city'
            },
            {
                Header: 'Adres',
                id : 'name',
                accessor: 'name' 
            },           
            {
                Header: 'Opmerkingen',
                id: 'notes',
                accessor: 'notes', 
            },
            {
                Header: 'Prijs per 100L',
                id: 'price_per_volume',
                Cell: props => <span>€ {(props.original.price_per_volume * 100)}</span>
            }
        ];

        if(fuelRoute) {
        	columns.unshift({
                Header: 'Tanken',
                id: 'actions',
                Cell: props => <NavLink className="btn btn-success" to={"/fueling/"+props.original.id}>Start</NavLink> // Custom cell components!
            });
        }
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>Tanken</div>
                </div>
              
                <div className="row">
                    <div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
                                <Table                                 	
									data={this.props.locations ? Object.values(this.props.locations) : false} columns={columns} />
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </ContentWrapper>
            );
    }

}

const mapDispatchToProps = (dispatch) => {
    return {
        setPinPopupVisible: (data) => dispatch(setPinPopupVisible(data))
    };
};


const mapStateToProps = (state, ownProps) => {
    return {
       	locations : state.Fueling.fuelingLocations,
       	routes: state.Auth.routes
    }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FuelingLocationOverview))