import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import {
   XAxis, YAxis, CartesianGrid, ResponsiveContainer, Bar, BarChart
} from 'recharts';


class TopUsers extends Component {
	

    render() {
        
        if(this.props.topUsers)
        {
    	if ( Object.keys(this.props.topUsers).length > 0 ) {
	        return (
	          
                    <Col xl={ 6 } >
                        <Row>
                            <Col xl={ 12 }>
                                <div className="card card-default">
                                    <div className="card-header">
                                        <div className="card-title"><Trans i18nKey={this.props.title}></Trans></div>
                                    </div>
                                    <div className="card-body">
                                       
										<ResponsiveContainer height={500} width={"100%"}>
												<BarChart width={600} height={300} data={this.props.topUsers}
											            margin={{top: 5, right: 30, left: 20, bottom: 5}}>
											       <CartesianGrid strokeDasharray="3 3"/>
											       <XAxis dataKey="userID"/>
											       <YAxis tickFormatter={this.props.tickFormatter}/>
											    
											       <Bar dataKey="userFueling" fill="#8884d8" />
											       
											      </BarChart>
										</ResponsiveContainer>
							
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                 
	            );
	    } else {
	    	return null;
        }
    }
        return null;

    }

}

export default TopUsers;
