import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { NavLink } from 'react-router-dom';
import { GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { geolocated } from "react-geolocated";
import icon from '../../assets/img/streetview-icon.png';
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");


class GoogleMapWidget extends Component {
	constructor(props) {
		super(props);

		this._mapMarkers = this._mapMarkers.bind(this);

		this.state = {
			item: false,
			isOpen : false
		}
	}


	_markerClicked = (e, item) => {
		this.props.history.push('/fueling/'+item.id)
	}

	_getSelfMarker = () => {

		if (  this.props.coords ) {
			return <Marker icon={icon} position={{ lat: this.props.coords.latitude, lng: this.props.coords.longitude}} />
		}
	}

	_mapMarkers(item, key) {

		if( parseInt(this.props.user.user_type.id) === 5 ) {
			return (
				<Marker onClick={(e) => this._markerClicked(e, item)} key={key} position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)}}>
					{this.state.isOpen && item.id === this.state.item.id ? <InfoBox

					      options={{ closeBoxURL: ``, enableEventPropagation: true }}
					    >
					      <div style={{ width: '250px', backgroundColor: `white`, opacity: 1, padding: `12px` }}>
					        <div style={{ fontSize: `16px`, fontColor: `#08233B` }}>
					          <strong>{item.name}</strong> <br />
					          {item.city}
					          <br />
					          <NavLink to={'/fueling/'+item.id}>Tanken</NavLink>

					        </div>
					      </div>
					    </InfoBox> : null }
				</Marker>
			)
		} else {
			if(item.usage_type) {

				if( item.usage_type === this.props.user.usage_type_value )  {
					return <Marker key={key} onClick={(e) => this._markerClicked(e, item)} position={{ lat: parseFloat(item.latitude), lng: parseFloat(item.longitude)}} />
				}
			}
		}
	}

    render() {
        return (
        	 <GoogleMap
			    defaultZoom={7}
			    defaultCenter={{ lat: 51.987880, lng: 5.888740 }}
			  >
			    {this.props.markers ? this.props.markers.map(this._mapMarkers) : null}

			    {this._getSelfMarker()}
			  </GoogleMap>
        );

    }

}


export default geolocated({
    positionOptions: {
        enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
})(withRouter(withScriptjs(withGoogleMap(GoogleMapWidget))));
