import React, { Component } from 'react';
import pubsub from 'pubsub-js';
import ToggleState from '../Common/ToggleState';
import TriggerResize from '../Common/TriggerResize';
import HeaderRun from './Header.run'

import { connect } from 'react-redux';

import mainLogo from '../../assets/img/ew-supply-logo.png';
import { Link } from 'react-router-dom';
import { formatMoney } from '../../helpers/utility';

class Header extends Component {

	constructor(props) {
		super(props);

		this.state = {
			user_credit: false
		}
	}
    componentDidMount() {
        HeaderRun();
    }



    toggleUserblock(e) {
        e.preventDefault();
        pubsub.publish('toggleUserblock');
    }

    render() {
    	let style = {};
    	if(this.props.fuelingActionPending || this.props.pinPopupVisible) {
    		style = {
    			zIndex: 1
    		}
    	}
        return (
            <header className="topnavbar-wrapper">
                { /* START Top Navbar */ }
                <nav className="navbar topnavbar"  style={style}>
                    { /* START navbar header */ }
                    <div className="navbar-header">
                        <a className="navbar-brand" href="#/">
                            <div className="brand-logo">
                                <img className="img-fluid" src={mainLogo} alt="App Logo" />
                            </div>
                            <div className="brand-logo-collapsed">
                                <img className="img-fluid" src={mainLogo} alt="App Logo" />
                            </div>
                        </a>
                    </div>
                    { /* END navbar header */ }

                    { /* START Left navbar */ }
                    <ul className="navbar-nav mr-auto flex-row">
                        <li className="nav-item">
                            { /* Button used to collapse the left sidebar. Only visible on tablet and desktops 
                            <TriggerResize>
                                <ToggleState state="aside-collapsed">
                                    <a href="/#" className="nav-link d-none d-md-block d-lg-block d-xl-block">
                                        <em className="fas fa-bars"></em>
                                    </a>
                                </ToggleState>
                            </TriggerResize>*/ }
                            { /* Button to show/hide the sidebar on mobile. Visible on mobile only. */ }
                            <ToggleState state="aside-toggled" nopersist={true}>
                                <a href="/#"  className="nav-link sidebar-toggle d-md-none">
                                    <em className="fas fa-bars"></em>
                                   
                                </a>
                               
                            </ToggleState>
                        </li>
                        <li className="nav-item d-none d-md-block">
                            <a  className="nav-link" href="/#" onClick={ this.toggleUserblock }>
                                <em className="icon-user"></em>
                            </a>
                        </li>
                    </ul>
                    <form className="navbar-form" role="search" action="search.html">
                       <div className="form-group">
                          <input className="form-control" type="text" placeholder="Type and hit enter ..."/>
                          <div className="fa fa-times navbar-form-close" data-search-dismiss=""></div>
                       </div>
                       <button className="d-none" type="submit">Submit</button>
                    </form>
                    { /* END Search form */ }
                </nav>
                { /* END Top Navbar */ }
            </header>
            );
    }

}


const mapStateToProps = (state, ownProps) => {
    return {
        user: state.User.user_profile ? state.User.user_profile : false,
        credit: state.Credit,
    }
}

export default connect(mapStateToProps)(Header)
