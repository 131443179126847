import React from 'react';

import ContentWrapper from '../../Layout/ContentWrapper';


const TermsAndConditions = props => (
	 <ContentWrapper>
                <div className="content-heading">
                    <div>Algemene voorwaarden</div>
                </div>
              
                <div className="row">
                    <div className="col-md-12">
						<div>
						<p class="p1"><span class="s1"><strong>Algemene Leveringsvoorwaarden osmosewater laadpunten, EW Supply B.V. Versie: </strong></span></p>
					<p class="p1"><span class="s1"><strong>oktober 2017</strong></span></p>
					<p class="p1"><span class="s1"><strong>Artikel 1 - Begripsomschrijvingen</strong></span></p>
					<p class="p1"><span class="s1">1.1&nbsp;&nbsp;&nbsp;&nbsp; <strong>Afnemer</strong></span></p>
					<p class="p1"><span class="s1">Diegene die gebruik maakt van de demiwater/</span></p>
					<p class="p1"><span class="s1">osmosewater laadpunten van EW Supply B.V.</span></p>
					<p class="p1"><span class="s1">1.2&nbsp;&nbsp;&nbsp;&nbsp; De Algemene Leveringsvoorwaarden Gedemineraliseerd Water/Osmosewater van EW Supply B.V. &ndash; hierna EWS &ndash; voor de levering van het Product aan Afnemers. Deze voorwaarden kunnen verder worden aangehaald als &ldquo;ALV&rdquo;.&nbsp;&nbsp;</span></p>
					<p class="p1"><span class="s1">1.3&nbsp;&nbsp;&nbsp;&nbsp; <strong>Code</strong></span></p>
					<p class="p1"><span class="s1">De automatische gegeneerde code die op het scherm verschijnt na het storten van een bedrag, het tegoed van Afnemer, op de rekening van EW Supply B.V. na het inloggen door Afnemer</span></p>
					<p class="p1"><span class="s1">1.4&nbsp;&nbsp;&nbsp;&nbsp; <strong>Leverancier</strong></span></p>
					<p class="p1"><span class="s1">EW Supply B.V. (EWS), gevestigd te Rotterdam, dan wel het voor Leverancier werkende personeel.</span></p>
					<p class="p1"><span class="s1">1.5&nbsp;&nbsp;&nbsp;&nbsp; <strong>Leveringsovereenkomst</strong></span></p>
					<p class="p1"><span class="s1">De overeenkomst, bestelling, transactie of opdracht met of verricht inzake de levering of beschikbaarstelling van het Product ten behoeve van de Afnemer waarvan deze ALV en de in artikel 2.5 vermelde bijlagen tevens integraal onderdeel van uit maken.</span></p>
					<p class="p1"><span class="s1">1.6&nbsp;&nbsp;&nbsp;&nbsp; <strong>Leveringspunt</strong></span></p>
					<p class="p1"><span class="s1">De demiwater/osmosewater locatie waar middels een Code, het Product geladen kan worden door Afnemer, specifiek tot het afronden van de tankbeurt.</span></p>
					<p class="p1"><span class="s1">1.7&nbsp;&nbsp;&nbsp;&nbsp; <strong>Laadpunt</strong></span></p>
					<p class="p1"><span class="s1">De fysieke locatie waar Afnemers het Product kunnen afnemen.</span></p>
					<p class="p1"><span class="s1">1.8&nbsp;&nbsp;&nbsp;&nbsp; <strong>Meetinrichting</strong></span></p>
					<p class="p1"><span class="s1">De apparatuur bestemd voor de meting en/of berekening van de hoeveelheid afgenomen Product.</span></p>
					<p class="p1"><span class="s1">1.9&nbsp;&nbsp;&nbsp;&nbsp; <strong>Partijen</strong></span></p>
					<p class="p1"><span class="s1">Leverancier en Afnemer.</span></p>
					<p class="p1"><span class="s1">1.10&nbsp;&nbsp; <strong>Tegoed op rekening </strong></span></p>
					<p class="p1"><span class="s1">Afnemer dient vooraf een tegoed te storten op de rekening van EWS om toegang te krijgen tot de Code en daarmee het tanken/laden mogelijk te maken.</span></p>
					<p class="p1"><span class="s1">1.11&nbsp;&nbsp; <strong>Producent</strong></span></p>
					<p class="p1"><span class="s1">De partij en diens personeel, die het Product produceert ten behoeve van het Laadpunt, dan wel het laadpunt beheert.</span></p>
					<p class="p1"><span class="s1">1.12&nbsp;&nbsp; <strong>Product</strong></span></p>
					<p class="p1"><span class="s1">Het per liter door Leverancier aan Afnemer geleverde dan wel beschikbaar gestelde product, zijnde gedemineraliseerd water van een nader omschreven kwaliteit.&nbsp; Ons Demiwater (osmosewater), oftewel gedemineraliseerd water, is ontdaan van vrijwel alle verontreinigingen, mineralen en zouten conform de in artikel 3.4. vermelde specificaties.</span></p>
					<p class="p1"><span class="s1">1.13&nbsp;&nbsp; <strong>Demiwater/osmosewater prijs</strong></span></p>
					<p class="p1"><span class="s1">De prijs per 100 liter, uitgedrukt in &euro; per liter, daadwerkelijk afgenomen Product, dat dient te worden betaald door Afnemer aan Leverancier middels het in minder brengen van het Tegoed op rekening.</span></p>
					<p class="p1"><span class="s1">1.14&nbsp;&nbsp; <strong>Afname overzicht</strong></span></p>
					<p class="p1"><span class="s1">De daadwerkelijke afname van het Product is verwerkt in het automatische overzicht op de portal van de afnemer. Na iedere tankbeurt is te zien welk volume is afgenomen en welk bedrag van het Tegoed is afgeschreven.</span></p>
					<p class="p1"><span class="s1">1.15&nbsp;&nbsp; <strong>Portal</strong></span></p>
					<p class="p1"><span class="s1">Op de portal dient de Afnemer zichzelf te registreren. Op de portal kan hij/zij zijn/haar verbruik en betaalgegevens inzien en controleren. Deze gegevens worden volledig vertrouwelijk behandeld.</span></p>
					<p class="p1"><span class="s1">1.16&nbsp;&nbsp; <strong>Kleinverbruik</strong></span></p>
					<p class="p1"><span class="s1">Onder kleinverbruik wordt verstaan afgenomen volumes vanaf 1 liter een Laadpunt.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 2 - Algemeen bepaling</strong></span></p>
					<p class="p1"><span class="s1">2.1&nbsp;&nbsp;&nbsp;&nbsp; Deze ALV zijn van toepassing op alle overeenkomsten, transacties en leveringen van of door EWS voor Kleinverbruik. Bestellingen, opdrachten en transacties van de Afnemer gelden als erkenning en aanvaarding van de ALV, met gelijktijdige algehele buiten effectstelling van eventuele, bijzondere of algemene condities of voorwaarden van de Afnemer, tenzij schriftelijk anders is overeengekomen tussen Partijen.</span></p>
					<p class="p1"><span class="s1">2.2&nbsp;&nbsp;&nbsp;&nbsp; De toepasselijkheid van eventuele inkoopvoorwaarden van Afnemer wordt met deze Leveringsovereenkomst uitgesloten, ook indien in eventuele correspondentie of aanvullende afspraken tussen Partijen daarnaar wordt verwezen.</span></p>
					<p class="p1"><span class="s1">2.3&nbsp;&nbsp;&nbsp;&nbsp; Als Afnemer in de zin van deze condities en de Leveringsovereenkomst wordt beschouwd de natuurlijk en/of rechtspersoon die de Leveringsovereenkomst is aangegaan. De Afnemer is uit dien hoofde tevens aansprakelijk voor de (vooruit)betaling die ingevolge de Leveringsovereenkomst verschuldigd is door Afnemer aan Leverancier en/of eventueel geleden schade door Leverancier of Producent.</span></p>
					<p class="p1"><span class="s1">2.4&nbsp;&nbsp;&nbsp;&nbsp; Indien de Afnemer namens of ten behoeve van een derde de bestelling plaatst of de Leveringsovereenkomst aangaat aanvaarden zowel de Afnemer als diens principaal de toepasselijkheid van deze condities en zal zij de condities ALV doorleggen aan deze derde.</span></p>
					<p class="p1"><span class="s1">2.5&nbsp;&nbsp;&nbsp;&nbsp; Van deze ALV maken twee bijlagen deel uit:</span></p>
					<p class="p1"><span class="s1">-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bijlage 1 waarin de veiligheidsvoorschriften van EWS zijn opgenomen welke de Afnemer strikt dient te volgen.</span></p>
					<p class="p1"><span class="s1">-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bijlage 2 waarin de vulprocedure is opgenomen welke de Afnemer strikt dient te volgen.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 3 &ndash; Overeenkomst tot Levering, kwaliteit en gebruik</strong></span></p>
					<p class="p1"><span class="s1">3.1&nbsp;&nbsp;&nbsp;&nbsp; Levering geschiedt uit hoofde van de Leveringsovereenkomst tussen Leverancier en Afnemer. Het Product is door Leverancier geleverd zodra het Leveringspunt is gepasseerd.</span></p>
					<p class="p1"><span class="s1">3.2&nbsp;&nbsp;&nbsp;&nbsp; De levering geschied normaliter met een debiet van 50 - 150 liter per minuut op het Leveringspunt nadat de Code is ingegeven. De levering geschied op basis van beschikbaarheid.</span></p>
					<p class="p1"><span class="s1">3.3&nbsp;&nbsp;&nbsp;&nbsp; Leverancier verplicht zich tot het (kunnen) leveren, op basis van beschikbaarheid, van het Product en Afnemer verplicht zich tot het (kunnen) afnemen van en vooruit betalen voor het Product, zoals overeengekomen in deze Leveringsovereenkomst.</span></p>
					<p class="p1"><span class="s1">3.4&nbsp;&nbsp;&nbsp;&nbsp; Leverancier levert Afnemer op Leveringspunt het Product van een kwaliteit zoals hieronder is gespecificeerd (Demiwater):</span></p>
					<p class="p1"><span class="s1">** Het Product kan CO2 bevatten, de geleidbaarheid wordt bepaald op basis van ontgast water.</span></p>
					<p class="p1"><span class="s1">3.5&nbsp;&nbsp;&nbsp;&nbsp; Afnemer houdt zich te allen tijde aan de door Leverancier dan wel de Producent verstrekte veiligheidsvoorschriften en overige relevante voorschriften en instructies (schriftelijk en/of mondeling), waaronder de vigerende 'vulprocedure' en veiligheidsvoorschriften, zoals beschreven in de bijlagen 1 en 2 bij deze ALV. Afnemer zal er voor zorgdragen dat ook haar klanten en de door haar ingeschakelde derden zich houden aan de vigerende voorschriften, instructies en procedures.</span></p>
					<p class="p1"><span class="s1">3.6&nbsp;&nbsp;&nbsp;&nbsp; Onverminderd het voorgaande, staat Leverancier op geen enkele wijze in voor de veiligheid van Afnemer, de klanten van Afnemer of de door Afnemer ingeschakelde derden.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 4 &ndash; Belastingen, heffingen en indexering</strong></span></p>
					<p class="p1"><span class="s1">4.1&nbsp;&nbsp;&nbsp;&nbsp; Alle bedragen, niet beperkt tot hetgeen vermeld in de overeenkomst, de bestelling, transactie of opdracht, die de Afnemer ingevolge de Leveringsovereenkomst (met bijbehorende ALV) verschuldigd is, zijn exclusief de belastingen en heffingen die Leverancier krachtsens enig besluit van de overheid in verband met de overeengekomen Levering verplicht respectievelijk bevoegd is in rekening te brengen.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 5 &ndash; Betaling</strong></span></p>
					<p class="p1"><span class="s1">5.1&nbsp;&nbsp;&nbsp;&nbsp; Afnemer zal een minimaal begin bedrag van &euro; 25,00 storten in zijn/haar Tegoed op rekening.</span></p>
					<p class="p1"><span class="s1">5.2&nbsp;&nbsp;&nbsp;&nbsp; De bedragen die Afnemer verschuldigd is aan Leverancier wegens de daadwerkelijk afgenomen hoeveelheid Product worden gebaseerd op de door Leverancier opgenomen of verkregen standen van de Meetinrichting als genoemd in artikel 8.</span></p>
					<p class="p1"><span class="s1">5.3&nbsp;&nbsp;&nbsp;&nbsp; De door Leverancier af te schrijven bedragen zullen na iedere tankbeurt&nbsp; in de app te vinden zijn.</span></p>
					<p class="p1"><span class="s1">5.4&nbsp;&nbsp;&nbsp;&nbsp; Verschil van mening omtrent de omvang van verschuldigde bedragen of het al dan niet verschuldigd zijn van bedragen schort de betalingsverplichting van Afnemer niet op.</span></p>
					<p class="p1"><span class="s1">5.5&nbsp;&nbsp;&nbsp;&nbsp; Afnemer zal eventuele bedragen de zij uit andere hoofde van Leverancier te vorderen heeft niet met de uit de hoofde van de Leveringsovereenkomst verschuldigde respectievelijk te vorderen bedragen verrekenen.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 6 &ndash; Geen vooruitbetaling</strong></span></p>
					<p class="p1"><span class="s1"><strong>6.1&nbsp;&nbsp;&nbsp;&nbsp; </strong>Indien Afnemer geen vooruitbetaling heeft gedaan, zal geen Demiwater kunnen worden afgenomen op het Laadpunt zonder nadrukkelijke toestemming van Leverancier en dan alleen middels een via Leverancier verstrekte code.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 7 &ndash; Verwerking tanken</strong></span></p>
					<p class="p1"><span class="s1">7.1&nbsp;&nbsp;&nbsp;&nbsp; Leverancier heeft het recht na het tanken/afnemen het bedrag te (direct) verwerken op het Tegoed op rekening van de betreffende Afnemer.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 8 &ndash; Meetinrichting</strong></span></p>
					<p class="p1"><span class="s1">8.1&nbsp;&nbsp;&nbsp;&nbsp; De Levering van het Product zal via een deugdelijke en voor de gewenste maximale hoeveelheid geschikte Meetinrichting plaatsvinden. De met behulp van deze meetinrichting verkregen gegevens zijn bindend.</span></p>
					<p class="p1"><span class="s1">8.2&nbsp;&nbsp;&nbsp;&nbsp; De Leverancier heeft zijn leveringsplicht vervuld wanneer de goederen ter beschikking zijn gesteld bij het Leveringspunt. Afnemer is verantwoordelijk voor alle kosten en risico's verbonden aan het goederenvervoer vanaf het Leveringspunt tot de gewenste bestemming. De Leverancier is niet verantwoordelijk voor het laden of vullen van het Product op of in een voertuig.</span></p>
					<p class="p1"><span class="s1">8.3&nbsp;&nbsp;&nbsp;&nbsp; In geval van twijfel over de juistheid van de verkregen gegevens middels de Meetinrichting kunnen Partijen in overleg treden. Indien Partijen in overleg treden zullen zij proberen in goed overleg tot een oplossing te komen.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 9 &ndash; Onderbreking of vermindering van de levering</strong></span></p>
					<p class="p1"><span class="s1">9.1&nbsp;&nbsp;&nbsp;&nbsp; Ingeval van een (dreigende) onderbreking of vermindering van de Levering is Leverancier niet verplicht de Afnemer hiervan op de hoogte te stellen.</span></p>
					<p class="p1"><span class="s1">9.2&nbsp;&nbsp;&nbsp;&nbsp; Leverancier mag de levering of beschikbaarstelling van het Product onderbreken.</span></p>
					<p class="p1"><span class="s1">9.3&nbsp;&nbsp;&nbsp;&nbsp; De verplichtingen van partijen worden opgeschort gedurende de tijd dat sprake is van overmacht, zoals gedefinieerd in artikel 6:75 van het Burgerlijk Wetboek.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 10 &ndash; Verbodsbepalingen</strong></span></p>
					<p class="p1"><span class="s1">10.1&nbsp;&nbsp; Het is de Afnemer verboden:</span></p>
					<p class="p1"><span class="s1">- verzegelingen die door of vanwege Leverancier of Producent zijn aangebracht op de Meetinrichting of op toestellen die deel uitmaken van het laadpunt zonder uitdrukkelijke toestemming van Leverancier te schenden of te verbreken;</span></p>
					<p class="p1"><span class="s1">&nbsp;- handelingen te verrichten of te doen verrichten, waardoor de omvang van de Levering niet of niet juist kan worden vastgesteld, dan wel een situatie te scheppen, waardoor het normaal functioneren van de Meetinrichting of andere apparatuur van Leverancier wordt verhinderd of belemmerd;</span></p>
					<p class="p1"><span class="s1">- zonder uitdrukkelijke toestemming van Leverancier werkzaamheden te verrichten aan het laadpunt</span></p>
					<p class="p1"><span class="s1">- In het bijzonder geldt: Bij de osmosewater/deminwaterlaadfaciliteit op het terrein van derden zal</span></p>
					<p class="p1"><span class="s1">Afnemer zich te allen tijde houden aan de geldende veiligheidsvoorschriften en de overige relevante voorschriften en instructies, waaronder de &lsquo;vulprocedure, zoals eventueel beschreven op het bord op het terrein.</span></p>
					<p class="p1"><span class="s1">In het geval van schade opgelopen door terreineigenaar welke is veroorzaakt door of toe te schrijven aan Afnemer, in het bijzonder schade ten gevolge van het niet volgen van de geldende (veiligheid)voorschriften en (vul)procedure of schade ten gevolge van een aanrijding op het terrein, is de Afnemer volledig aansprakelijk tot een maximum bedrag van &euro;10.000 per gebeurtenis en heeft de terrein eigenaar het recht om de betreffende schade rechtstreeks bij de Afnemer te verhalen. De Afnemer draagt zorg voor een adequate verzekering van de risico&rsquo;s die samenhangen met haar verplichtingen onder deze overeenkomst.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 11 &ndash; Aansprakelijkheid</strong></span></p>
					<p class="p1"><span class="s1">11.1&nbsp;&nbsp; Leverancier is niet gehouden tot betaling van enigerlei schadevergoeding aan Afnemer voor schade in welke vorm dan ook, die voor Afnemer is ontstaan of zou kunnen ontstaan ten gevolge van de Levering en/of het verbruik van het Product of van onderbreking of vermindering van de Levering of van afwijkingen van de kwaliteitsnormen, tenzij bedoelde schade is ontstaan door opzet of grove schuld aan de zijde van Leverancier. Alsdan komt uitsluitend voor vergoeding in aanmerking schade aan personen en/of zaken, indien en voor zover die schade is ontstaan als direct en bewezen gevolg van de Levering of van het verbruik van het Product. Leverancier zelf is verantwoordelijk voor de juiste toepassing van het Product en de controle op de toepasbaarheid van het Product.</span></p>
					<p class="p1"><span class="s1">11.2&nbsp;&nbsp; Vergoeding van indirecte of gevolgschade door Leverancier is te allen tijde uitgesloten. Onder indirecte of gevolgschade wordt verstaan: alle andere schade dan directe schade aan personen en/of zaken, zoals schade wegens bedrijfsstilstand, gederfde winst e.d.</span></p>
					<p class="p1"><span class="s1">11.3&nbsp;&nbsp; Afnemer vrijwaart Leverancier tegen aanspraken van derden in verband met schade, die voor hen is ontstaan of zou kunnen ontstaan ten gevolge van de Levering en/of gebruik van het Product en/of handelen door Afnemer of diens ingeschakelde derden of van stagnatie in de Levering, behoudens in geval van opzet, grove schuld van Leverancier.</span></p>
					<p class="p1"><span class="s1">11.4&nbsp;&nbsp; Afnemer verplicht zich er voor te zorgen dat het geleverde Product alleen voor die toepassingen gebruikt wordt c.q. dit Product niet als drinkwater of voor andere sanitaire doeleinden kan worden of wordt gebruikt. Afnemer vrijwaart Leverancier voor aanspraken van personeel van Afnemer en van derden te dier zake.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 12 &ndash; Contracttermijn</strong></span></p>
					<p class="p1"><span class="s1">12.1&nbsp;&nbsp; Afnemer heeft geen vaste contracttermijn en kan op ieder moment de afname (al dan niet tijdelijk) stopzetten. Het door Afnemer gestorte tegoed zal hierbij op aanvraag van Afnemer volledig worden teruggestort, indien bedrag hoger is dan 50 euro, op het bij Leverancier bekende bankrekeningnummer.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 13 &ndash; Be&euml;indiging registratie</strong></span></p>
					<p class="p1"><span class="s1">13.1&nbsp;&nbsp; Afnemer kan zijn registratie ongewijzigd aanhouden tijdens het niet meer afnemen van het Product. (Persoons)gegevens zijn te verwijderen door het versturen van een &nbsp;e-mail sturen naar Leverancier. Bij faillissement of andere reden van be&euml;indiging van bedrijf Afnemer is het wenselijk om Leverancier hiervan op de hoogte te brengen.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 14 &ndash; Informatieverstrekking </strong></span></p>
					<p class="p1"><span class="s1">14.1&nbsp;&nbsp;Individuele kennisgevingen die Afnemer op grond van de Leveringsovereenkomst aan Leverancier doet, vinden via e-mail plaats. Mondelinge mededelingen, toezeggingen of afspraken hebben geen rechtskracht tenzij deze schriftelijk zijn bevestigd door een daartoe bevoegde persoon.</span></p>
					<p class="p1"><span class="s1">14.2&nbsp;&nbsp; Wijzigingen in tarieven, werkwijze van het vulpunt, tijdelijk onderhoud en overige zaken zullen waar mogelijk, over het algemeen, op de website en app gecommuniceerd worden.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 15 &ndash; Beslechting der geschillen</strong></span></p>
					<p class="p1"><span class="s1">15.1&nbsp;&nbsp; Partijen zullen in overleg treden om tot een redelijke oplossing te komen aangaande alle problemen die ontstaan naar aanleiding van de onderhavige Leveringsovereenkomst, van nadere Leveringsovereenkomsten of van afspraken van Leverancier met Afnemer met betrekking tot de onderhavige Leveringsovereenkomst.</span></p>
					<p class="p1"><span class="s1">15.1&nbsp;&nbsp; Mocht na verloop van twee maanden na de eerste bijeenkomst van Partijen in het kader van het overleg bedoeld in artikel 16.1, het overleg naar het redelijk oordeel van een of beide Partijen niet tot het gewenste resultaat leiden, dan is er sprake van een geschil zodra een der Partijen zulks schriftelijk aan de andere partij meedeelt. Geschillen in het kader van deze Leveringsovereenkomst zullen aan de Rechtbank te Rotterdam ter beslechting worden voorgelegd indien en uitsluitend als het geschil meer van 1.000 euro betreft, tenzij partijen een andere vorm van geschillenbeslechting nader overeenkomen.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 16 &ndash; Overdracht van rechten en verplichtingen</strong></span></p>
					<p class="p1"><span class="s1">16.1&nbsp;&nbsp; Leverancier is gerechtigd zijn rechten en verplichtingen uit de leveringsovereenkomst en de bijbehorende ALV over te dragen aan een derde. Afnemer is alleen na schriftelijke toestemming van Leverancier gerechtigd zijn rechten en verplichtingen uit de Leveringsovereenkomst en de bijbehorende ALV over te dragen. In geval van overdracht dient &nbsp;overdragende partij er voor zorgt te dragen dat de overnemende partij alle bedoelde rechten en verplichtingen overneemt en onverminderd het recht van de wederpartij van de overdragende partij om niet in te stemmen met die overdracht indien daartoe naar het redelijk oordeel van die wederpartij aanleiding bestaat. De overdragende partij staat er voor in dat elke overdracht als hier bedoeld onverwijld aan de wederpartij, niet zijnde de overnemende partij, schriftelijk wordt meegedeeld.</span></p>
					<p class="p1"><span class="s1"><strong>Artikel 17 &ndash; Toepasselijk recht</strong></span></p>
					<p class="p1"><span class="s1">17.1&nbsp;&nbsp; Op de Leveringsovereenkomst is het Nederlands recht van toepassing.</span></p>
						</div>
				</div>
			</div>
		</ContentWrapper>
)


export default TermsAndConditions;