import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class Footer extends Component {

    render() {
       
        return (
            <footer className="footer-container">
                <span><NavLink to="/privacy-verklaring">Privacy verklaring</NavLink> - <NavLink to="/algemene-voorwaarden">Algemene voorwaarden</NavLink></span>
            </footer>
        );
    }

}

export default Footer;
