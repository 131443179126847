import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import './i18n';
// -- set clientpath
const url = window.location.host.split(':');

if(url[0] === 'localhost') {
    localStorage.setItem('clientPath','http://localhost:8000'); 
} else {
    localStorage.setItem('clientPath','https://' + window.location.host.replace('www', '') + '/api/public');
}

ReactDOM.render(<App />, document.getElementById('app'));
