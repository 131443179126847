import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'

class Table extends Component { 
	
	constructor(props,context) {
      super(props,context);

      this.columns = this.props.columns;

      if(this.props.additionalColumns && this.columns)
      {
        this.columns = [...this.columns, ...this.props.additionalColumns]
      }

    }


  	render() {

  		return (
  			<div>
	    		<ReactTable
	              data={this.props.data}
	              columns={this.columns}
	              sorted={this.props.sorted} 
	              defaultPageSize={10}
	              previousText="Vorige"
	              nextText='Volgende'
	              loadingText='Laden...'
	              noDataText='Geen data gevonden'
	              pageText='Pagina'
	              ofText='van'
	              rowsText='rijen'
	            />
		    </div>
  		)
  	}
}
export default (Table)