import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import { fetchData } from '../../helpers/fetch';
import { push } from 'react-router-redux';
import { toast } from 'react-toastify';
import { getToken, getClientPath } from '../../helpers/utility';
import { errorCodes } from '../../helpers/errorMessage';
import fuelingActions from '../fueling/actions';
import moment from 'moment';
const { rsfFirestore,firebase } = FirebaseHelper;

function fetchUser() {
	return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.reload().then((result) => {
		  	resolve(user);
		})
	});
}

function updatePassword(newPassword) {
	return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.updatePassword(newPassword).then((result) => {

			toast('Wachtwoord geupdated', {
		        type: 'success',
		        position: 'top-right'
		    })
		    resolve(result);
		}).catch((err) => {
			toast(errorCodes[err.code]['message'], {
		        type: errorCodes[err.code]['type'],
		        position: 'top-right'
		    })
		});
	});
}

function updateEmail(emailAddress) {
	return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.updateEmail(emailAddress).then((result) => {
			toast('E-mailadres geupdated', {
		        type: 'success',
		        position: 'top-right'
		    })
		    resolve(result);
		}).catch((err) => {
			toast(errorCodes[err.code]['message'], {
		        type: errorCodes[err.code]['type'],
		        position: 'top-right'
		    })
		});
	});
}

function updateDisplayName(displayName) {
	return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.updateProfile({displayName: displayName}).then((result) => {

			toast('Gebruikersnaam geupdated', {
		        type: 'success',
		        position: 'top-right'
		    })
		    resolve(result);
		}).catch((err) => {
			toast(err.code, {
		        type: 'error',
		        position: 'top-right'
		    })
		});
	});
}


export function* processFuelingHistoryPerUser() {
	yield takeEvery(actions.PROCESS_FUELINGHISTORY_PER_USER, function*(action) {


		let userTotal = 0;
		let userTotalYTD = 0;

		let fuelingHistory = {};
		let i = 0;
		for ( const property in action.payload.data ) {
			if ( action.payload.data.hasOwnProperty(property) ) {
				let fuelingAction = action.payload.data[property];

					// -- If is current year, add value to Year To Date.
				if ( moment(fuelingAction.fueling_date,"DD-MM-YYYY").format("YYYY") === moment().format('YYYY') ) {
					userTotalYTD += fuelingAction.volume_taken ? fuelingAction.volume_taken : 0;
				}

				if (typeof fuelingAction.location_firebase_id == 'string') {
		   			let location_id = firebase.firestore().doc('locations/' + fuelingAction.location_firebase_id);
					fuelingAction.location_firebase_id = location_id;
				}

				let locationRef = yield call(rsfFirestore.getCollection, fuelingAction.location_firebase_id);
				let location = locationRef.data();

		   		fuelingAction = {
		    		...fuelingAction,
		    		location_firebase_id: location,
		    		userID : action.payload.userId
		    	}

		    	i++;

		    	let newFuelingAction = {
		    		[i + action.payload.userId] : fuelingAction
		    	}



		    	fuelingHistory = {
		    		...fuelingHistory,
		    		...newFuelingAction
		    	}

				userTotal += fuelingAction.volume_taken ? fuelingAction.volume_taken : 0;
			}
		}


		yield put({
    		type : fuelingActions.PROCESS_FUELING_HISTORY,
    		payload : {
    			type : action.payload.type,
    			data : fuelingHistory,
    			userID : action.payload.userId
    		}
    	})

		const userTotals = {
			userTotal,
			userTotalYTD,
			fuelingHistory
		}


		yield put({
			type : actions.UPDATE_SUB_USER_FUELING_DATA,
			payload : { type : action.payload.type, data : userTotals, user_id : action.payload.userId},
		})
	})
}

export function* getUserCredit() {
	yield takeEvery(actions.GET_USER_CREDIT, function*(action) {

		const snapshot = yield call(rsfFirestore.getDocument, 'credit/'+action.payload.userID);
		const data = snapshot.data();
		let credit = 0;
		if ( data ) {
			if ( data.credit ) {
				credit = data.credit;
			}
		}

		yield put({
			type : actions.SET_USER_CREDIT,
			payload : {
				type : action.payload.type,
				userID : action.payload.userID,
				credit : credit
			}
		})
	})
}

export function* getUserPaymentHistory() {
	yield takeEvery(actions.GET_USER_PAYMENT_HISTORY, function*(action) {
		let paymentHistory = [];
		const paymentHistorySnapshot = yield call(rsfFirestore.getCollection, 'user_payment_history/'+action.payload.userID + '/payments');

		if ( paymentHistorySnapshot.docs.length ) {
			paymentHistorySnapshot.docs.forEach((item, i) => {
				const itemData = item.data();
				const newItem = {
					...itemData,
					firstname :  action.payload.firstname,
					lastname :  action.payload.lastname,
					'name' : action.payload.firstname + ' ' + action.payload.lastname,
					'payment_amount_excl' : itemData.payment_amount_excl ? itemData.payment_amount_excl : 0,
					'payment_btw_amount' : itemData.payment_btw_amount ? itemData.payment_btw_amount : 0
				}

				paymentHistory.push(newItem);
			});

		}

		yield put({
			type : actions.SET_USER_PAYMENT_HISTORY,
			payload : {
				type : action.payload.type,
				userID : action.payload.userID,
				paymenthistory : paymentHistory
			}
		})
	})
}

export function* parseSubUsers() {
	yield takeEvery(actions.PARSE_SUB_USERS, function*(action) {

		yield put({
			type: actions.SET_SUB_USERS,
			payload : action.payload
		})

		let data = action.payload.data;
		for ( const userID in data ) {
			if ( data.hasOwnProperty(userID) ) {
				yield put({
					type : actions.GET_USER_CREDIT,
					payload : {
						userID : userID,
						type : action.payload.type
					}
				})

				yield put({
					type : actions.GET_USER_PAYMENT_HISTORY,
					payload : {
						userID : userID,
						type : action.payload.type,
						firstname : data[userID].firstname,
						lastname : data[userID].lastname
					}
				})
			}
		}
	})
}

// -- Find all sub users for each user and add to their object.
export function* setSubUsers() {
	yield takeEvery(actions.SET_SUB_USERS, function*(action) {

		for ( const userId in action.payload.data){
			if (action.payload.data.hasOwnProperty(userId)) {
				yield fork(
					rsfFirestore.syncCollection,
					'user_fueling_history/'+userId + '/fueling_actions',
					{
						successActionCreator: (snap) => actions.processFuelingHistoryPerUser({type: action.payload.type, data : FirebaseHelper.processFireStoreCollection(snap), userId: userId}),
						failureActionCreator : () => actions.failure()
					}
				);

				yield fork(
				  	rsfFirestore.syncCollection,
				  	firebase.firestore().collection('users').where('parent_id', '==', userId),
					{
						successActionCreator: (snap) => actions.setUserSubUsers({type : action.payload.type, data : FirebaseHelper.processFireStoreCollection(snap), userId: userId}),
						failureActionCreator : () => actions.failure()
					}
				)

			}

		}
	})
}

export function* setUserProfile() {
	yield takeEvery(actions.SET_USER_PROFILE, function*(action) {
		yield localStorage.setItem('user_profile', JSON.stringify(action.payload));

	})
}

export function* fetchUserProperties() {
	yield takeEvery(actions.FETCH_USER_PROPERTIES, function*(action) {


		const snapshot = yield call(rsfFirestore.getDocument, 'users/'+action.payload);
		yield put({
			type: actions.SET_USER_PROPERTIES,
			payload: snapshot.data()
		})

		yield put({
			type: fuelingActions.FETCH_FUELING_LOCATIONS,
			payload: snapshot.data()
		})
	})
}


export function* userUpdate() {
	yield takeEvery(actions.USER_UPDATE, function*(action) {

		// -- Update firestore userdata
		const user = FirebaseHelper.auth().currentUser;
		if(user.displayName !== action.payload.displayName) {
			yield updateDisplayName(action.payload.displayName);
		}

		if(action.payload.password) {
			yield updatePassword(action.payload.password);
		}
		if(user.email !== action.payload.email) {
			yield updateEmail(action.payload.email);
		}


		// -- Create user formData
		let formData = new FormData();
		formData.append('user_id', user.uid);
		formData.append('token', user.uid);
		formData.append('firstname', action.payload.firstname);
		formData.append('lastname', action.payload.lastname);
		formData.append('phone_number', action.payload.phone_number);
		formData.append('company', action.payload.company);
		formData.append('city', action.payload.city);
		formData.append('zipcode', action.payload.zipcode);
		formData.append('usage_type', action.payload.usageType.value);
		formData.append('client_number', action.payload.client_number);
		formData.append('active', action.payload.user_active);
		formData.append('email', action.payload.email);
		formData.append('tancode', action.payload.tancode);
		formData.append('is_valid_type', action.payload.is_valid_type);
		// -- Send formdata to API
		const response = yield call(
			fetchData,
			getClientPath() + '/user/updateDbProfile',
			formData
		)


		response['usageType'] = action.payload.usageType;
		response['email'] = action.payload.email
		// -- Update firestore document with server response
		try {
			yield call(rsfFirestore.updateDocument, 'users/'+user.uid, response);
		} catch(error) {

		}


		// -- Fetch user itself from firestore
		const userResult = yield fetchUser();

		if(action.payload.logoBlob) {
			try {

			// Create a root reference
			var storageRef = firebase.storage().ref();

			// Create a reference to 'images/mountains.jpg'
			var logoImagesRef = storageRef.child(user.uid+'/'+action.payload.logoName);

			var metadata = {
			  contentType: action.payload.logoType
			};

			logoImagesRef.put(action.payload.logoBlob, metadata).then(function(snapshot){
				// -- set logo url
				snapshot.ref.getDownloadURL().then(function(downloadURL) {
				   firebase.firestore().collection('users').doc(user.uid).set({logo: downloadURL, logo_name: action.payload.logoName}, {merge: true});
				});

			});

			} catch(error) {

			}
		}

		yield put({
			type:actions.SET_USER_PROFILE,
			payload: userResult
		})
	});
}

export function* createNewUser() {
	yield takeEvery(actions.USER_ADD, function*(action) {
		// -- Create user formData
		let formData = new FormData();
		formData.append('token', FirebaseHelper.auth().currentUser.uid);
		formData.append('firstname', action.payload.firstname);
		formData.append('lastname', action.payload.lastname);
		formData.append('phone_number', action.payload.phone_number);
		formData.append('company', action.payload.company);
		formData.append('city', action.payload.city);
		formData.append('zipcode', action.payload.zipcode);
		formData.append('email', action.payload.email);
		formData.append('password', action.payload.password);
		formData.append('userType', action.payload.userType.value);
		formData.append('quota', action.payload.quota);
		formData.append('quotum_infinite', action.payload.quotum_infinite);
		formData.append('user_pays_later', action.payload.user_pays_later);
		formData.append('usage_type', action.payload.usageType.value);
		formData.append('client_number', action.payload.client_number);
		formData.append('discount_amount', action.payload.client_number);
		formData.append('tancode', action.payload.tancode);
		formData.append('is_valid_type', action.payload.is_valid_type);
		const response = yield call(
			fetchData,
			getClientPath() + '/user/createNewUser',
			formData
		)

		if(response.userDocument) {

			if ( errorCodes[response.notification] ) {
				toast(errorCodes[response.notification]['message'], {
			        type: response.type,
			        position: 'top-right'
			    })
			} else {
				toast(response.notification, {
			        type: response.type,
			        position: 'top-right'
			    })
			}
			response.userDocument['usageType'] = action.payload.usageType;
			response.userDocument['userType'] = action.payload.userType;
			response.userDocument['email'] = action.payload.email;

			// -- replace user type data with reference
			response.userDocument = {...response.userDocument, user_type: firebase.firestore().doc('user_types/' + response.userDocument.user_type_id)}

			response.userDocument = {...response.userDocument, user_type_permissions: firebase.firestore().doc('user_type_permissions/' + response.userDocument.user_type_id)}
			// -- replace user type data with reference
			response.userDocument = {...response.userDocument, parent_user: firebase.firestore().doc('users/' + response.userDocument.parent_id)}

			yield call(rsfFirestore.setDocument, 'users/'+response.userDocument.firebase_user_id, response.userDocument)


		}else{
			if ( errorCodes[response.notification] ) {
				toast(errorCodes[response.notification]['message'], {
			        type: response.type,
			        position: 'top-right'
			    })
			} else {
				toast(response.notification, {
			        type: response.type,
			        position: 'top-right'
			    })
			}

		}
	});
}

export function* createNewUserRegisterForm() {
	yield takeEvery(actions.USER_REGISTER, function*(action) {
		// -- Create user formData
		let formData = new FormData();
		formData.append('firstname', action.payload.name);
		formData.append('lastname', action.payload.lastname);
		formData.append('phone_number', action.payload.phone);
		formData.append('company', action.payload.company);
		formData.append('city', action.payload.city);
		formData.append('zipcode', action.payload.zipcode);
		formData.append('email', action.payload.email);
		formData.append('password', action.payload.password);
		formData.append('tancode', action.payload.tancode);
		formData.append('userType', 4); // chauffeur
		formData.append('quotum_infinite', true); // true
		formData.append('user_pays_later', false); // false
		formData.append('usage_type', 'small'); // small

		const response = yield call(
			fetchData,
			getClientPath() + '/userRegister/createNewUser',
			formData
		)

		if(response.userDocument) {

			if ( errorCodes[response.notification] ) {
				toast(errorCodes[response.notification]['message'], {
					type: response.type,
					position: 'top-right'
				})
			} else {
				toast(response.notification, {
					type: response.type,
					position: 'top-right'
				})
			}
			response.userDocument['usageType'] = {
				label : "Kleinverbruik",
				value : response.userDocument.usage_type_value
			};
			response.userDocument['userType'] = {
				label : "Chauffeur",
				value : response.userDocument.user_type_id
			}

			response.userDocument['user_active'] = true;
			response.userDocument['email'] = action.payload.email;



			// -- replace user type data with reference
			response.userDocument = {...response.userDocument, user_type: firebase.firestore().doc('user_types/' + response.userDocument.user_type_id)}

			response.userDocument = {...response.userDocument, user_type_permissions: firebase.firestore().doc('user_type_permissions/' + response.userDocument.user_type_id)}

			try {
				yield call(rsfFirestore.setDocument, 'users/'+response.userDocument.firebase_user_id, response.userDocument)
			} catch(e)
			{
				console.log(e)
			}
			window.location.href = "/#/login";

		}else{

			if ( errorCodes[response.notification] ) {
				toast(errorCodes[response.notification]['message'], {
					type: response.type,
					position: 'top-right'
				})
			} else {
				toast(response.notification, {
					type: response.type,
					position: 'top-right'
				})
			}

		}
	});
}


export function* updateSubUser() {
	yield takeEvery(actions.SUB_USER_UPDATE, function*(action) {


		// -- Create user formData
		let formData = new FormData();
		formData.append('token', FirebaseHelper.auth().currentUser.uid);
		formData.append('firebase_user_id', action.payload.firebase_user_id);
		formData.append('firstname', action.payload.firstname);
		formData.append('lastname', action.payload.lastname);
		formData.append('phone_number', action.payload.phone_number);
		formData.append('company', action.payload.company);
		formData.append('zipcode', action.payload.zipcode);

		if ( action.payload.credit ) {
			formData.append('credit', action.payload.credit);
		}


		if(action.payload.userType)
			formData.append('userType', action.payload.userType.value);

		formData.append('quota', action.payload.quota);
		formData.append('city', action.payload.city);
		formData.append('quotum_infinite', action.payload.quotum_infinite);
		formData.append('user_pays_later', action.payload.user_pays_later);
		formData.append('usage_type', action.payload.usageType.value);
		formData.append('client_number', action.payload.client_number);
		formData.append('discount_amount', (action.payload.discount_amount ? action.payload.discount_amount : 0));
		formData.append('active', (action.payload.user_active ? true : false));
		if ( action.payload.update_password ) {
			formData.append('password', action.payload.password);
		} else {
			formData.append('password', '');
		}

		formData.append('tancode', action.payload.tancode);
		formData.append('is_valid_type', action.payload.is_valid_type);

		const response = yield call(
			fetchData,
			getClientPath() + '/user/updateSubUser',
			formData
		)

		if(response.userDocument) {


			if ( errorCodes[response.notification] ) {
				toast(errorCodes[response.notification]['message'], {
					type: response.type,
					position: 'top-right'
				})
			} else {
				toast(response.notification, {
					type: response.type,
					position: 'top-right'
				})
			}

		    response.userDocument['usageType'] = action.payload.usageType;
		    response.userDocument['userType'] = action.payload.userType;

		    // -- replace user type data with reference
			response.userDocument = {...response.userDocument, user_type: firebase.firestore().doc('user_types/' + response.userDocument.user_type_id)}

		    yield call(rsfFirestore.updateDocument, 'users/'+action.payload.firebase_user_id, response.userDocument);

		}
	});
}


export function* removeLogo() {
	yield takeEvery(actions.REMOVE_LOGO, function*(action) {
		const localUserData = getToken();
		const user_id = localUserData.get('userId');
		const snapshot = yield call(rsfFirestore.getDocument, 'users/'+user_id);
		const userData = snapshot.data();


		if(userData) {

			try {

				var storageRef = firebase.storage().ref();
				// Create a reference to 'images/mountains.jpg'
				var logoImagesRef = storageRef.child(user_id+'/'+userData.logo_name);

				// Delete the file
				logoImagesRef.delete().then(function() {

					firebase.firestore().collection('users').doc(user_id).set({logo: '', logo_name: ''}, {merge: true});

					toast('Logo succesvol verwijderd', {
				        type: 'success',
				        position: 'top-right'
				    })


				}).catch(function(error) {

				});
			} catch(error) {
				console.log(error);
			}
		}
		//

	});
}

export function* syncUserCollection() {
	const localUserData = getToken();

	const user_id = localUserData.get('userId');


	if ( user_id ) {
	// -- Sync user document and set user properties with snapped data on change.
		yield fork(
			rsfFirestore.syncDocument,
			'users/'+user_id,
			{
				successActionCreator: (snap) => actions.setUserProperties(snap.data()),
				failureActionCreator : () => actions.failure()
			}
		);
	}
}

// -- Selects all user for current parent and all users total. Total is only available for super-admins.
export function* syncSubUsers() {

	yield takeEvery(actions.GET_SUB_USERS, function*(action) {
		const user_id = action.payload.userId;
		const user_type_id = action.payload.user_type;

		if ( user_id ) {
			if(user_type_id == 1) {
				yield fork(
					rsfFirestore.syncCollection,
					firebase.firestore().collection('users').where('parent_id', '==', user_id),
					{
						successActionCreator: (snap) => actions.parseSubUsers({type : 'sub_users', data : FirebaseHelper.processFireStoreCollection(snap)}),
						failureActionCreator : () => actions.failure()
					}
				)
			}

			// -- TODO: only fetch all when is superuser
			if(user_type_id == 5) {

				yield fork(
					rsfFirestore.syncCollection,
					firebase.firestore().collection('users'),
					{
						successActionCreator: (snap) => actions.parseSubUsers({type : 'all_users', data: FirebaseHelper.processFireStoreCollection(snap)}),
						failureActionCreator : () => actions.failure()
					}
				)
			}
		}
	});
}
export function* failure() {
	yield takeEvery(actions.FAILURE, function*(action) {

	})
}

export default function* rootSaga() {
	yield all([
		fork(userUpdate),
		fork(createNewUser),
		fork(setUserProfile),
		fork(fetchUserProperties),
		fork(syncUserCollection),
		fork(removeLogo),
		fork(syncSubUsers),
		fork(updateSubUser),
		fork(setSubUsers),
		fork(getUserPaymentHistory),
		fork(processFuelingHistoryPerUser),
		fork(parseSubUsers),
		fork(getUserCredit),
		fork(failure),
		fork(createNewUserRegisterForm)
	]);
}
