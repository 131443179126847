import React, { Component } from 'react';
import { withNamespaces, Trans } from 'react-i18next';
import ContentWrapper from '../Layout/ContentWrapper';
import { Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import Now from '../Common/Now';
import moment from 'moment';
import GoogleMapWidget from '../Widgets/GoogleMapWidget';
import { formatMoney } from '../../helpers/utility';
import TopUsers from '../Widgets/Charts/TopUsers';
import CreditStatistics from '../Widgets/Charts/CreditStatistics';
import FuelingStatistics from '../Widgets/Charts/FuelingStatistics';
import TopTenFueling from '../Widgets/Charts/TopTenFueling';
import { fetchData } from '../../helpers/fetch';
class DashboardV1 extends Component {
	constructor(props) {
		super(props);
		this.highest = 0;
		this.highestFueling = 0;



		this.state = {
	        fuelingHistory : {
	        	data : []
	        },
	        paymentHistory : {
	        	data : []
			},
			TopTenFueling : []

    	}
	}


    componentDidMount() {
		this._parsePaymentHistory();
			this._fetchTopTen();
    }

    componentDidUpdate(prevProps) {

    	if(this.props.paymentHistory.length !== prevProps.paymentHistory.length) {
    		this.setState({
    			...this.state,
	    		paymentHistory : {
	    			...this.state.paymentHistory,
	    			data : this._parsePaymentHistory()
	    		}
			})

			this._fetchTopTen();
    	}
    }

    changeLanguage = lng => {
        this.props.i18n.changeLanguage(lng);
    }

    toggle = () => {
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    _mapDayPayments = (key) => {
    	const item = this.props.paymentHistory[key];
    	if(parseFloat(item.payment_amount) >= this.highest) {
    		this.highest = parseFloat(item.payment_amount);
    	}

    	return {
    		key : item.payment_date,
    		value: parseFloat(item.payment_amount)
    	}
    }

    _mapHistoryPayments = (key, items, type) => {
    	let item = items[key];
    	if(parseFloat(item) >= this.highest) {
    		this.highest = parseFloat(item);
    	}

    	let graphKey = key;

    	if ( type === 'month' ) {
    		graphKey = moment().month(key).format("MMM");;
    	}
    	return {
    		key : graphKey,
    		value: parseFloat(item)
    	}
    }

    _mapDayFueling = (key, items) => {
    	const item = items[key];
    	if(parseFloat(item.volume_requested) >= this.highestFueling) {
    		this.highest = parseFloat(item.volume_requested);
    	}

    	return {
    		key : item.fueling_date,
    		value: parseFloat(item.volume_requested)
    	}
    }

    _mapHistoryFueling = (key, items, type) => {
    	let item = items[key];
    	if(parseFloat(item) >= this.highestFueling) {
    		this.highestFueling = parseFloat(item);
    	}

    	let graphKey = key;
    	if ( type === 'month' ) {
    		graphKey = moment().month(key).format("MMM");;
    	}

    	return {
    		key : graphKey,
    		value: parseFloat(item)
    	}
    }

    _parsePaymentHistory = () => {
    	const paymentHistory = this.props.paymentHistory;
    	let data = [];

    	let monthNames = [];
    	let years = [];
    	let weeks = [];

    	let weekPayments = {};
    	let monthPayments = {};
    	let yearPayments = {};

    	for(let key in paymentHistory) {
    		if(paymentHistory.hasOwnProperty(key)) {

    			// -- Parse all payment data
    			let paymentAmount = parseFloat(paymentHistory[key]['payment_amount']);
    			const year = moment(paymentHistory[key]['payment_date'], 'DD-MM-YYYY H:i:s').format('Y');
    			const monthName = moment(paymentHistory[key]['payment_date'], 'DD-MM-YYYY H:i:s').format('MMMM');
    			const monthNumber = moment(paymentHistory[key]['payment_date'], 'DD-MM-YYYY H:i:s').format('M');
    			const weekNumber = moment(paymentHistory[key]['payment_date'], 'DD-MM-YYYY H:i:s').weeks();

    			// -- Add to array and objects by property
    			if(!years.includes(year)) {
    				years.push(year);
    				yearPayments[year] = 0;
    			}
    			if(!monthNames.includes(monthName)) {
    				monthNames.push(monthName);
    				monthPayments[monthNumber] = 0;
    			}
    			if(!weeks.includes(weekNumber)) {
    				weeks.push(weekNumber);
    				weekPayments[weekNumber] = 0;
    			}

    			// -- Ad amount for payment to property
    			yearPayments[year] += paymentAmount;
    			monthPayments[monthNumber] += paymentAmount;
    			weekPayments[weekNumber] += paymentAmount;
    		}
    	}
    	// -- Check for the right type of data to display
    	if(years.length > 1) {
    		data = Object.keys(yearPayments).map((key) => this._mapHistoryPayments(key, yearPayments, 'year'));
    	}else if(monthNames.length > 1) {
    		data = Object.keys(monthPayments).map((key) => this._mapHistoryPayments(key, monthPayments, 'month'));
    	}else if(weeks.length > 1) {
    		data = Object.keys(weekPayments).map((key) => this._mapHistoryPayments(key, weekPayments, 'week'));
    	}else {
    		data = Object.keys(paymentHistory).map((key) => this._mapDayPayments(key, paymentHistory, 'day'));
    	}



    	return data;
    }

    _parseFuelingHistory = (type) => {

    	let fuelingHistoryUncleaned = this.props.fuelingHistory[type];

		let fuelingHistory = {};

		for(let key in fuelingHistoryUncleaned) {
			if ( fuelingHistoryUncleaned.hasOwnProperty(key)) {
				if ( fuelingHistoryUncleaned[key].pin_accepted === 1 ) {
					fuelingHistory = {
						...fuelingHistory,
						[key] : fuelingHistoryUncleaned[key]
					}
				}
			}
		}

    	let data = [];

    	let monthNames = [];
    	let years = [];
    	let weeks = [];

    	let weekFuelingActions = {};
    	let monthFuelingActions = {};
    	let yearFuelingActions = {};

    	for(let key in fuelingHistory) {
    		if(fuelingHistory.hasOwnProperty(key)) {

    			if(fuelingHistory[key]['pin_accepted'] === 1) {
	    				// -- Parse all payment data
	    			let requestedAmount = parseFloat(fuelingHistory[key]['volume_requested']);
	    			const year = moment(fuelingHistory[key]['fueling_date'], 'YYYY-MM-DD H:i:s').format('Y');

	    			const monthName = moment(fuelingHistory[key]['fueling_date'], 'YYYY-MM-DD H:i:s').format('MMMM');
	    			const monthNumber = moment(fuelingHistory[key]['fueling_date'], 'YYYY-MM-DD H:i:s').format('M');

	    			const weekNumber = moment(fuelingHistory[key]['fueling_date'], 'YYYY-MM-DD H:i:s').weeks();

	    			// -- Add to array and objects by property
	    			if(!years.includes(year)) {
	    				years.push(year);
	    				yearFuelingActions[year] = 0;
	    			}
	    			if(!monthNames.includes(monthName)) {
	    				monthNames.push(monthName);
	    				monthFuelingActions[monthNumber] = 0;
	    			}
	    			if(!weeks.includes(weekNumber)) {
	    				weeks.push(weekNumber);
	    				weekFuelingActions[weekNumber] = 0;
	    			}

	    			// -- Ad amount for payment to property
	    			yearFuelingActions[year] += requestedAmount;
	    			monthFuelingActions[monthNumber] += requestedAmount;
	    			weekFuelingActions[weekNumber] += requestedAmount;
    			}

    		}
    	}

    	// -- Check for the right type of data to display
    	if(years.length > 1) {

    		data = Object.keys(yearFuelingActions).map((key) => this._mapHistoryFueling(key, yearFuelingActions, 'year'));
    	}else if(monthNames.length > 1) {

    		data = Object.keys(monthFuelingActions).map((key) => this._mapHistoryFueling(key, monthFuelingActions, 'month'));
    	}else if(weeks.length > 1) {

    		data = Object.keys(weekFuelingActions).map((key) => this._mapHistoryFueling(key, weekFuelingActions, 'week'));
    	}else {
    		data = Object.keys(fuelingHistory).map((key) => this._mapDayFueling( key, fuelingHistory, 'day') );
    	}

    	return data;
    }

    _parseTopUsersToArray(userData, usageType = false) {
    	let userObject = {};
    	let userArray = [];
    	for ( const userID in userData ) {
    		if ( userData.hasOwnProperty(userID) ) {
    			const user = userData[userID];

    			if ( usageType !== false ) {
    				if ( user.usage_type_value !== usageType ) {
	    				continue;
	    			}
    			}

    			let userFueling = 0;
    			if ( user.fueling_history) {

    				for ( const fuelingID in user.fueling_history) {
    					if ( user.fueling_history.hasOwnProperty(fuelingID) ) {
    						const fuelingItem = user.fueling_history[fuelingID];
    						userFueling += fuelingItem.volume_taken;
    					}
    				}
    			}

    			if ( userFueling ) {
    				userObject = {
	    				...userObject,
	    				[userFueling + '_' + userID] : {
	    					userID : user.company,
	    					userFueling : userFueling
	    				}
	    			}
    			}

    		}
    	}

    	for ( let userItem in userObject ) {
    		if ( userObject.hasOwnProperty(userItem) ) {
    			userArray.push(userObject[userItem]);
    		}
    	}

    	return userArray.reverse().slice(0, 9);

    }

    _mapTopUsers = () => {
    	let topUsers = {};
    	if ( this.props.user.user_type_id === 5 ) {
    		// -- Super admin, show all
    		 topUsers = {
    		 	...topUsers,
    		 	small : this._parseTopUsersToArray(this.props.allUserData, 'small'),
    		 	large : this._parseTopUsersToArray(this.props.allUserData, 'large')
    		 }
    	} else if ( this.props.user.user_type.id === 1 ) {
    		return this._parseTopUsersToArray(this.props.subUserData);
		}

		return topUsers;
	}

	_fetchTopTen() {
		const userid = localStorage.getItem('user_id');
		const clientPath = localStorage.getItem('clientPath');

		let formData = new FormData();
		formData.append('token', userid);
		let items = {};
		fetchData(clientPath + '/fueling/fetchTopTen', formData).then((snapshot) => {

			console.log(snapshot);
			this.setState({TopTenFueling : snapshot})

		});

	}

    render() {


		const topUsers = this._mapTopUsers();

		const formatter = (value) => `${value} liter`;

        return (
            <ContentWrapper>
                <div className="content-heading hidden-xs">
                    <div>Dashboard
                        <small><Trans i18nKey='dashboard.WELCOME'></Trans></small>
                    </div>
                    {/*
                    <div className="ml-auto">
                        <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                            <DropdownToggle>
                                Nederlands
                            </DropdownToggle>
                            <DropdownMenu className="dropdown-menu-right-forced animated fadeInUpShort">
                                <DropdownItem onClick={() => this.changeLanguage('en')}>English</DropdownItem>
                                <DropdownItem onClick={() => this.changeLanguage('nl')}>Nederlands</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
               	 */}
                </div>
				{(this.props.user.usage_type_value === 'small') ?
                <Row>

	                    <Col xl={ 3 } md={ 6 } className="hidden-xs">
	                         <div className="card flex-row align-items-center align-items-stretch border-0">
	                            <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left">
	                                <em className="fa fa-euro-sign fa-3x"></em>
	                            </div>
	                            <div className="col-8 py-3 bg-primary rounded-right">
	                                <div className="h2 mt-0">€ {this.props.credit.credit}</div>
	                                <div className="text-uppercase">Saldo</div>
	                            </div>
	                        </div>
	                    </Col>

                     <Col xl={ 3 } lg={ 6 } md={ 12 }>
                        <div style={{height: 'calc(100% - 20px)', minHeight: '97px'}}className="card flex-row align-items-center align-items-stretch border-0">
						<NavLink className="col-12 d-flex align-items-center bg-warning-dark justify-content-center rounded-left" to={'/finance'}>
                                <div className="text-center">
	                                	<div className="text-uppercase large-button-mobile" style={{fontSize : "20px"}}><strong>Opwaarderen</strong></div>
                                </div>
							</NavLink>
                        </div>
                    </Col>


                    <Col xl={ 3 } lg={ 6 } md={ 12 }>
                        <div style={{height: 'calc(100% - 20px)'}}className="card flex-row align-items-center align-items-stretch border-0">
						<NavLink className="col-12 d-flex align-items-center bg-green justify-content-center rounded-left" to={'/fueling_locations_overview'}>
                                <div className="text-center">
	                                	<div className="text-uppercase large-button-mobile" style={{fontSize : '20px'}}><strong>Tankbeurt starten</strong></div>
                                </div>
							</NavLink>
                        </div>
                    </Col>
					{this.props.user.tancode != "" ?
					<Col xl={ 3 } lg={ 6 } md={ 12 }>
                        <div style={{height: 'calc(100% - 20px)'}}className="card flex-row align-items-center align-items-stretch border-0">
						<NavLink className="col-12 d-flex align-items-center bg-purple justify-content-center rounded-left" to={'#'}>
                                <div className="text-center">
	                                	<div className="text-uppercase large-button-mobile" style={{fontSize : '20px'}}><strong>Tancode:</strong>{this.props.user.tancode}</div>
                                </div>
							</NavLink>
                        </div>
                    </Col> : null }
                </Row>
				:  <Row>
						<Col xl={ 3 } lg={ 6 } md={ 12 }>
						<div style={{height: 'calc(100% - 20px)',minHeight: '97px'}}className="card flex-row align-items-center align-items-stretch border-0">
						<NavLink className="col-12 d-flex align-items-center bg-green justify-content-center rounded-left" to={'/fueling_locations_overview'}>
								<div className="text-center">
										<div className="text-uppercase large-button-mobile">Tankbeurt starten</div>
								</div>
							</NavLink>
						</div>
					</Col>
					{this.props.user.tancode != "" ?
					<Col xl={ 3 } lg={ 6 } md={ 12 }>
                        <div style={{height: 'calc(100% - 20px)'}}className="card flex-row align-items-center align-items-stretch border-0">
						<NavLink className="col-12 d-flex align-items-center bg-purple justify-content-center rounded-left" to={'#'}>
                                <div className="text-center">
	                                	<div className="text-uppercase large-button-mobile" style={{fontSize : '20px'}}><strong>Tancode:</strong>{this.props.user.tancode}</div>
                                </div>
							</NavLink>
                        </div>
                    </Col> : null }
				</Row> }
                <Row className="hidden-xs">
                	{ this.props.user.usage_type_value === 'small' ? <CreditStatistics  statistics={this.props.paymentHistory.data} /> : null }
                    { this.props.fuelingHistory ? this.props.fuelingHistory.self ? Object.keys(this.props.fuelingHistory.self).length ? <FuelingStatistics tickFormatter={formatter} type="my" statistics={this._parseFuelingHistory('self')} /> : null : null : null }
                    { this.props.fuelingHistory ? this.props.fuelingHistory.sub_users ? Object.keys(this.props.fuelingHistory.sub_users).length ? <FuelingStatistics tickFormatter={formatter} type="users" statistics={this._parseFuelingHistory('sub_users')} /> : null : null : null}
                    { parseInt(this.props.user.user_type.id) === 5 ? this.props.fuelingHistory ? this.props.fuelingHistory.all_users ? Object.keys(this.props.fuelingHistory.all_users).length ? <FuelingStatistics type="all_users" statistics={this._parseFuelingHistory('all_users')} /> : null : null : null : null}
                    { parseInt(this.props.user.user_type.id) === 5  ? <TopUsers title={'dashboard.TOP_USERS_LARGE'} tickFormatter={formatter} topUsers={topUsers.large}/> : null }
                    { parseInt(this.props.user.user_type.id) === 5 ?  <TopUsers title={'dashboard.TOP_USERS_SMALL'} tickFormatter={formatter} topUsers={topUsers.small}/> : null }
                    { parseInt(this.props.user.user_type.id) ===1 ? <TopUsers title={'dashboard.TOP_USERS'} tickFormatter={formatter} topUsers={topUsers}/> : null }
					{ parseInt(this.props.user.user_type.id) ===5 ? <TopTenFueling title={'dashboard.TOP_USERS'} tickFormatter={formatter} data={this.state.TopTenFueling} /> : null }
                </Row>
                  <Row >
                    <Col xl={ 12 }>
                        <Row>
                            <Col  xl={ 12 }>
                                <div className="card card-default">
                                   <GoogleMapWidget
                                   user={this.props.user}
                                    loadingElement={<div style={{ height: '100%' }}  />}
                                     googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyC5H-JrNWHSnr9UExsodbLIC2ni9NyWAek"
                                      containerElement={<div style={{ height: `400px` }} />}
                                      mapElement={<div style={{ height: `100%` }} />}
                                      markers={this.props.locations}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>

            </ContentWrapper>
            );

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
    	allUserData : state.User.all_users,
    	subUserData : state.User.sub_users,
        credit: state.Credit,
        locations : state.Location.locations,
        user: state.User.user_profile ? state.User.user_profile : false,
        dbProfile: state.User.db_profile,
        userType: state.UserType,
        fuelingHistory : state.Fueling.fuelingHistory,
        paymentHistory : state.Credit.paymentHistory
    }
}



export default withRouter(withNamespaces('translations')(connect(mapStateToProps)(DashboardV1)));
