import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Table from '../../Table/Table';
import { NavLink } from 'react-router-dom';
import { formatMoney } from '../../../helpers/utility';
import moment from 'moment';
import { Card, CardBody, FormGroup, Input, Label, TabContent, TabPane,  Nav, NavItem } from 'reactstrap';
import ContentWrapper from '../../Layout/ContentWrapper';
import classnames from 'classnames';
class TopTenFueling extends Component {

    constructor(props) {
        super(props)
        this.state = {
        	activeTab: '1'}
    }

    toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}

    render() {

        const columns = [
            {
                Header: 'Klantnummer',
                accessor: 'klantnummer'
            },
            {
                Header: 'Bedrijfsnaam',
                accessor: 'company'
            }, {
                Header: 'Naam',
                accessor: 'name' // String-based value accessors!
            }, {
                Header: 'Afnamedatum',
                accessor: 'fueling_date' // String-based value accessors!
            }, {
                Header: 'Tankrapport',
                accessor: 'tank_report', // String-based value accessors!
                Cell: props => <a target="_blank" href={"//api.aquabox.acceptage.nl/uploads/tank_report/" + props.original.tank_report}>{props.original.tank_report}</a>
            }
        ];



        if ( this.props.data) {
        	return (
                <ContentWrapper>
                    <div className="card card-default">
                     <div className="card-header">
                        <div className="card-title">Laatste 10 tankbeurten</div>
                    </div>
                    </div>
                <Nav tabs>
                    <NavItem>
                        <span className={'nav-link ' + classnames({ active: this.state.activeTab === '1' })} onClick={() => { this.toggle('1'); }}>
                            Klant
                        </span>
                    </NavItem>
                    <NavItem>
                        <span className={'nav-link ' + classnames({ active: this.state.activeTab === '2' })} onClick={() => { this.toggle('2'); }}>
                            Chauffeur
                        </span>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                        <div style={{backgroundColor: "white"}}>
                        <Table data={this.props.data['klant'] ? Object.values(this.props.data['klant']) : []} columns={columns} />
                        </div>
                    </TabPane>
                    <TabPane tabId="2">
                        <div style={{backgroundColor: "white"}}>
                        <Table data={this.props.data['chaffeur'] ? Object.values(this.props.data['chaffeur']) : []} columns={columns} />
                        </div>
                    </TabPane>
	            </TabContent>
                </ContentWrapper>
	        );
        }

        return <Table data={[]} columns={columns} />

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        allUsers: state.User.all_users,
        subUsers: state.User.sub_users
    }
}



export default withRouter(connect(mapStateToProps)(TopTenFueling))
