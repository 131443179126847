import actions from './actions';

const initState = {
	user: null,
	user_profile: {
		user_credit: '0,00'
	},
	sub_user: null,
	paymentHistory: null,
	all_users : false
};

export default function userReducer(state = initState, action) {

	switch (action.type) {
		case actions.SET_USER_PROFILE:
			return {
				...state,
				user_profile: {
					...state.user_profile,
					...action.payload
				}
			};
		case actions.UPDATE_SUB_USER_FUELING_DATA :
			return {
				...state,
				[action.payload.type] : {
					...state[action.payload.type],
					[action.payload.user_id] : {
						...state[action.payload.type][action.payload.user_id],
						fueling_history : action.payload.data.fuelingHistory,
						total_fueling : action.payload.data.userTotal,
						total_fueling_ytd : action.payload.data.userTotalYTD
					}
				}
			}

		case actions.SET_USER_SUB_USERS :
			return {
				...state,
				[action.payload.type] : {
					...state[action.payload.type],
					[action.payload.userId] : {
						...state[action.payload.type][action.payload.userId],
						firebase_user_id : action.payload.userId,
						'sub_users' : {
							...state[action.payload.type][action.payload.userId]['sub_users'],
							...action.payload.data
						}
					}
				}
			}

		case actions.SET_USER_CREDIT :
			return {
				...state,
				[action.payload.type] : {
					...state[action.payload.type],
					[action.payload.userID] : {
						...state[action.payload.type][action.payload.userID],
						credit : action.payload.credit
					}
				}
			}
		case actions.SET_USER_PAYMENT_HISTORY :
			return {
				...state,
				[action.payload.type] : {
					...state[action.payload.type],
					[action.payload.userID] : {
						...state[action.payload.type][action.payload.userID],
						paymentHistory : action.payload.paymenthistory
					}
				}
			}

		case actions.SET_USER_PROPERTIES:
			return {
				...state,
				user_profile: {
					...state.user_profile,
					...action.payload
				}
			}
		case actions.SET_SUB_USERS:

			return {
				...state,
				[action.payload.type] : {
					...state[action.payload.type],
					...action.payload.data
				}

			}
		case 'LOGOUT':
			return initState;


		default:
			return state;
	}
}
