import React, { Component } from 'react';
import Firebase from "../../../helpers/firebase";

import { connect } from 'react-redux';
import authAction from '../../../redux/auth/actions';

import { withRouter } from 'react-router';
import { toast } from 'react-toastify';

import { errorCodes } from '../../../helpers/errorMessage';
import { Input } from 'reactstrap';
import FormValidator from '../../Forms/FormValidator.js';

class Recover extends Component {

    state = {
        formLogin: {
            email: '',
            password: ''
        }     
    }

     /**
      * Validate input using onChange event
      * @param  {String} formName The name of the form in the state object
      * @return {Function} a function used for the event
      */
     validateOnChange = event => {
        const input = event.target;
        const form = input.form
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                [input.name]: value,
                errors: {
                    ...this.state[form.name].errors,
                    [input.name]: result
                }
            }
        });

    }

    onSubmit = e => {
      
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        e.preventDefault()

        const { email } = this.state.formLogin;

        

        let isError = false;
		Firebase.resetPassword( email )
		.catch(result => {
			toast(errorCodes[result.code]['message'], {
		        type: errorCodes[result.code]['type'],
		        position: 'top-right'
		    })

			isError = true;
		})
		.then(result => {		
			
            toast('U heeft succesvol een nieuw wachtwoord aangevraagd.', {
                type: 'success',
                position: 'top-right'
            })
		});
		
    }

    /* Simplify error check */
    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    }


    render() {
        return (
            <div className="block-center mt-4 wd-xl">
                {/* START card */}
                <div className="card card-flat">
                    <div className="card-header text-center bg-dark">
                        <a href="">
                        Easywatersupply
                        </a>
                    </div>
                    <div className="card-body">
                        <p className="text-center py-2">Wachtwoord vergeten</p>
                        <form name="formLogin" onSubmit={this.onSubmit}>
                            <p className="text-center">Vul uw e-mailadres in om instructies te ontvangen over het opnieuw instellen van uw wachtwoord.</p>
                            <div className="form-group">
                                <label className="text-muted" htmlFor="resetInputEmail1">Email</label>
                                <div className="input-group with-focus">
                                    <Input type="email"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="E-mailadres"
                                        invalid={this.hasError('formLogin','email','required')||this.hasError('formLogin','email','email')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "email"]'
                                        value={this.state.formLogin.email}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formLogin','email','required') && <span className="invalid-feedback">Veld is verplicht</span> }
                                    { this.hasError('formLogin','email','email') && <span className="invalid-feedback">Veld moet een e-mailadres bevatten</span> }
                                </div>
                            </div>
                            <button className="btn btn-danger btn-block" type="submit">Reset</button>
                        </form>
                    </div>
                </div>               
            </div>
        );
    }
}
const mapStateToProps = (state, ownProps) => {
    return {
        isLoggedIn: state.Auth.idToken !== null ? true : false
    }
}


export default withRouter(connect(mapStateToProps)(Recover))
