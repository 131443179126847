const actions = {
	USER_TYPE_UPDATE: 'USER_TYPE_UPDATE',
	USER_TYPE_UPDATE_SUCCESS: 'USER_TYPE_UPDATE_SUCCESS',
	FETCH_USER_TYPE : 'FETCH_USER_TYPE',
	SET_USER_TYPE : 'SET_USER_TYPE',
	FAILURE : 'FAILURE',
	failure : () => ({
		type : actions.FAILURE,
	}),
	updateUserType: (payload) => ({
	    type: actions.USER_TYPE_UPDATE,
	    payload: payload
	}),	

	setUserTypes: (payload) => ({
		type: actions.SET_USER_TYPE,
		payload: payload
	}),

	fetchUserType: (payload) => ({
		type: actions.FETCH_USER_TYPE,
		payload: payload,
	}),
	getUserTypes: () => ({ type: actions.FETCH_USER_TYPE })

};

export default actions;
