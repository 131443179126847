export const firebaseConfig = {
	apiKey: "AIzaSyC5H-JrNWHSnr9UExsodbLIC2ni9NyWAek",
    authDomain: "aquabox-2c861.firebaseapp.com",
    databaseURL: "https://aquabox-2c861.firebaseio.com",
    projectId: "aquabox-2c861",
    storageBucket: "aquabox-2c861.appspot.com",
    messagingSenderId: "172358822832"
  };




