import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import {
    Card,
    CardBody,
     } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import ContentWrapper from '../../Layout/ContentWrapper';

import 'react-rangeslider/lib/index.css'
import fuelingActions  from '../../../redux/fueling/actions';

import { formatMoney } from '../../../helpers/utility';

const { initializeFueling } = fuelingActions;

class FuelingSuccess extends Component {

    constructor(props) {
        super(props)
        this.state = {location: false, fuelingAction: false}       
    }

    componentDidMount() {
    	
    	if(this.props.match.params.location_id) {
    		if(this.props.locations) {
    			const location = this.props.locations.find(item => item.id === this.props.match.params.location_id);
	    		this.setState({
	    			...this.state,
	    			location
	    		})
    		}
    	}

    	if(this.props.match.params.fueling_id) {
    		
    		if(this.props.fuelingHistory) {
    			const fuelingAction = this.props.fuelingHistory.find(item => item.id === parseInt(this.props.match.params.fueling_id));

    			if(fuelingAction) {
    				this.setState({
    					...this.state,
		    			fuelingAction
		    		})
    			}
	    		
    		}
    	}
    }

    componentDidUpdate() {

    
    	if(this.props.match.params.location_id) {
    		if(!this.state.location && this.props.locations.length) {
    			const location = this.props.locations.find(item => item.id === this.props.match.params.location_id);
	    		this.setState({
	    			...this.state,
	    			location
	    		})
    		}
    	}

    	if(this.props.match.params.fueling_id) {
    		if(this.props.fuelingHistory.length) {
	    		const fuelingAction = this.props.fuelingHistory.find(item => item.id === parseInt(this.props.match.params.fueling_id));
	    		if(this.state.fuelingAction !== fuelingAction) {
	    			this.setState({
    					...this.state,
		    			fuelingAction,
		    		})
	    		}

	    	}
    	}
    }


    render() {

        return (
            <ContentWrapper>

                <div className="content-heading">
                    <div>Tankactie <br />
                    <small> {this.state.location ? this.state.location.name : null} </small>
                    </div>
                </div>

                <div className="row">  
                    <div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
								{this.state.fuelingAction.volume_taken === null ?
                            	<div className="alert alert-info">
                            		U kunt nu tanken.
                            	</div>
								:
								<div className="alert alert-success">
									Tankbeurt is afgerond.
								</div> }

                            	{this.state.fuelingAction ? 
                            		<table className="table">
	                            		<tbody>
	                            			<tr>
	                            				<td>Locatie</td>
	                            				<td>{this.state.location.name}</td>
	                            			</tr>
	                            			<tr>
	                            				<td>Pin geaccepteerd</td>
	                            				<td>{this.state.fuelingAction.pin_accepted === 1 ? <i className="fa fa-check"></i> : <i className="fa fa-times"></i>}</td>
	                            			</tr>
	                            			<tr>
	                            				<td>Datum</td>
	                            				<td>{this.state.fuelingAction.fueling_date}</td>
	                            			</tr>
	                            			<tr>
	                            				<td>Aangevraagd volume</td>
	                            				<td>{this.state.fuelingAction.volume_requested}</td>
	                            			</tr>
	                            			{this.state.fuelingAction.volume_taken != null ?
		                            			<tr>
		                            				<td>Afgenomen volume</td>
		                            				<td>{this.state.fuelingAction.volume_taken}</td>
		                            			</tr>
		                            		: null }
	                            			
	                            			{this.state.fuelingAction.expected_price ? 
		                            			<tr>
		                            				<td>Verwachte prijs</td>
		                            				<td>{formatMoney(this.state.fuelingAction.expected_price)}</td>
		                            			</tr>
		                            		: null }
		                            	</tbody>
                            		</table>

                            	: null
                            	}
                            	<br />
                            	<NavLink to={"/fueling_locations_overview"} className="btn btn-sm btn-danger float-left" >Terug</NavLink>
                            </CardBody>
                        </Card>
                    </div>
                </div>
              
      
            </ContentWrapper>
            );
    }

}


const mapDispatchToProps = (dispatch) => {
    return {
        initializeFueling: (data) => dispatch(initializeFueling(data))
    };
};


const mapStateToProps = (state, ownProps) => {

    return {
       	locations : state.Fueling.fuelingLocations,
       	fuelingHistory : state.Fueling.fuelingHistory,
    }
}



export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FuelingSuccess))