

const routeMap = {
	can_change_quotum: 0,
	menu : [
		{
			heading: "Main navigation",
			translate: "sidebar.heading.HEADER"
		},
		{
			icon: "icon-speedometer",
			name: "Dashboard",
			path: "/dashboard",
			translate: "sidebar.nav.DASHBOARD"
		},
		
		{
			heading: "Financieel",
			translate: "sidebar.heading.FINANCIAL",
			usageType : "small"
		},
		{
			icon : "icon-wallet",
			name : "Financieel",
			translate : "sidebar.nav.FINANCIAL",
			usageType : "small",
			submenu : [
				{
					name : "Saldo toevoegen",
					path : "/finance",
					usageType : "small"
				},
				{
					name : "Betalingen",
					path : "/payment_history",
					usageType : "small"
				}
			]
		},
		{
			heading: "Tanken",
			translate: "sidebar.heading.FUELING"
		},
		{
			icon : "icon-speedometer",
			name : "Tanken",
			translate : "sidebar.nav.FUELING",
			submenu : [
				{
					name : "Tankbeurt starten",
					path : "/fueling_locations_overview"
				},
				{
					name : "Afnamegeschiedenis",
					path : "/fueling_history"
				}
			]
		}
	],
	routes : [
		{
			component : "Dashboard",
			pageKey : "Base",
			route : "/dashboard"
		},{
			component : "PrivacyStatement",
			pageKey : "Base",
			route : "/privacy-verklaring"
		},
		{
			component : "User",
			pageKey : "Base",
			route : "/user"
		},
		{
			component : "TermsAndConditions",
			pageKey : "Base",
			route : "/algemene-voorwaarden"
		},
		{
			component : "Saldo",
			pageKey : "Base",
			route : "/finance",
			usageType : "small"
		},
		{
			component : "PaymentHistory",
			pageKey : "Base",
			route : "/payment_history"
		},
		{
			component : "FuelingHistory",
			pageKey : "Base",
			route : "/fueling_history"
		},
		{
			component : "FuelingLocationOverview",
			pageKey : "Base",
			route : "/fueling_locations_overview"
		},
		{
			component : "Fueling",
			pageKey : "Base",
			route : "/fueling/:location_id"
		} 
	]
}
export default routeMap