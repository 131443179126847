import React from 'react';

import ContentWrapper from '../../Layout/ContentWrapper';


const PrivacyStatement = props => (
	 <ContentWrapper>
                <div className="content-heading">
                    <div>Privacy verklaring</div>
                </div>
              
                <div className="row">
                    <div className="col-md-12">
		<p><span>EW Supply</span></p>
<p><span>Privacyverklaring</span></p>
<p><span>1 juni 2018</span></p>
<p><span>Je privacy is voor EW Supply B.V. (Easywatersupply) van groot belang. Wij houden ons dan ook aan de privacywet. Dit betekent dat je gegevens veilig bij ons zijn en dat wij ze altijd netjes gebruiken. In deze privacyverklaring leggen we uit wat we bij de diensten van Easywatersupply portal en mobiele app doen om jouw gegevens te beschermen.</span></p>
<p><span>Je account</span></p>
<p><span>Bij bepaalde onderdelen van onze dienst moet je je eerst registreren. Je moet dan informatie over jezelf opgeven en een gebruikersnaam kiezen. Daarmee maken wij een account, waarop je kunt inloggen met die gebruikersnaam en een zelfgekozen wachtwoord. Hiervoor kunnen wij de volgende gegevens gebruiken: je naw-gegevens, telefoonnummer, factuuradres en e-mailadres. Deze hebben wij nodig vanwege het gebruik kunnen maken van onze demiwater vulpunten. Wij bewaren deze informatie tot zes maanden nadat je het account hebt opgeheven. Wij bewaren deze informatie zodat je ze niet steeds opnieuw in hoeft te vullen en zodat we je makkelijker kunnen benaderen als dat nodig is bij de dienst. Je kunt via je account informatie aanpassen wanneer je dat wilt.</span></p>
<p><span>Afhandelen diensten</span></p>
<p><span>Wanneer je bij ons demiwater afneemt houden wij ofwel de afgenomen hoeveelheid bij om aan het eind van de maand een factuur te kunnen sturen, of je rekent zelf af met het tegoed wat je zelf kan opwaarderen met onze beveiligde app op je mobiel. Bij facturatie aan het eind van de maand, gebruiken wij je naw-gegevens, telefoonnummer, factuuradres, e-mailadres en betalingsgegevens. Deze hebben wij nodig vanwege het contract die we met je sluiten. Wij bewaren deze informatie tot twee jaar nadat je bestelling is afgerond en zeven jaar daarna (dat is de wettelijke bewaarplicht). Bij zelfstandig opwaarderen via je mobiele app, worden geen gegevens van onze klanten gebruikt. Deze zijn volledig in eigen beheer van onze klanten in samenwerking met PayPal en/of Ideal.</span></p>
<p><span>Toegang portaal</span></p>
<p><span>Met ons portaal krijg je toegang tot een beheeromgeving waar je zelf dingen kunt instellen, opgeven en wijzigen voor de dienst. Je kunt zelf bijhouden wat je gedaan hebt en wanneer, zodat daar bewijs van is. In het afgeschermde portaal kan je zelf klanten invoegen met eigen NAW gegevens, e-mail adres en telefoonnummer. Wij doen niets met deze gegevens. Onze klanten kunnen hun eigen subklanten invoeren. Voor de gegevens van deze (subklanten) bedrijven zijn onze klanten verantwoordelijk voor het beschermen van deze gegevens. EW Supply wijst haar klanten op deze verantwoordelijkheid dmv een omschrijvende email. EW Supply heeft inzage in de ingevoerde persoons/bedrijfsgegevens van onze klanten op de portaal. Deze bedrijfsgegevens en de activiteiten worden automatisch bijgehouden in statistieken. Deze statistieken hebben als doel om de dienstverlening naar onze klanten te verbeteren, bijvoorbeeld in de vorm van meer vulpunten in de buurt van onze klanten.</span></p>
<p><span>Contactformulier</span></p>
<p><span>Met het contactformulier op onze website kun je ons vragen stellen of aanvragen doen. Hiervoor gebruiken wij je telefoonnummer, e-mailadres, voornaam, achternaam en bedrijfsnaam. Hier hebben wij een legitiem belang bij. Wij bewaren deze informatie totdat we zeker weten dat je tevreden bent met onze reactie en zes maanden daarna. Zo kunnen we bij vervolgvragen de informatie er makkelijk bij pakken.</span></p>
<p><span>Andere partijen</span></p>
<p><span>Wij geven de persoons/bedrijfsgegevens niet aan andere bedrijven of instanties.</span></p>
<p><span>Informatie</span></p>
<p><span>Wij willen jou graag op de hoogte houden van eventuele storingen en nieuwe vulpunten. Dit doen wij:<br /> &bull; per post <br /> &bull; per e-mail <br /> &bull; per telefoon <br /> &bull; per sms</span></p>
<p><span>Je kunt op ieder moment bezwaar maken tegen deze informatie. Elke e-mail bevat een afmeldlink. Je kunt ons blokkeren of de afmeldoptie gebruiken. Je kunt dit aangeven als je wordt gebeld. Via de</span></p>
<p><span>website kun je dit ook doorgeven. Via het portaal kun je dit ook doorgeven. Of via een mail naar info@easywatersupply.nl</span></p>
<p><span>Beveiliging</span></p>
<p><span>Beveiliging van persoonsgegevens is voor ons van groot belang. Om je privacy te beschermen, nemen wij de volgende maatregelen: <br /> &bull; Toegang tot persoonsgegevens wordt afgeschermd met een gebruikersnaam en wachtwoord <br /> &bull; De gegevens worden na ontvangst veilig opgeslagen in een afgeschermd systeem. De wachtwoorden worden hierbij versleuteld. <br /> &bull; Wij maken gebruik van beveiligde verbindingen (Secure Sockets Layer of SSL) waarmee alle informatie tussen jou en onze portal/website wordt afgeschermd wanneer je persoons/bedrijfsgegevens invoert.</span></p>
<p><span>Wijzigingen in deze privacyverklaring</span></p>
<p><span>Wanneer onze dienst wijzigt, moeten wij natuurlijk ook de privacyverklaring aanpassen. Let dus altijd op de datum hierboven en kijk regelmatig of er nieuwe versies zijn. Wij zullen ons best doen wijzigingen ook apart aan te kondigen.</span></p>
<p><span>Inzage en wijzigen van je gegevens</span></p>
<p><span>Als je vragen hebt of wilt weten welke persoonsgegevens wij van jou hebben, kun je altijd contact met ons opnemen. Zie de contactgegevens hieronder. Je hebt de volgende rechten: <br /> &bull; Uitleg krijgen over welke persoonsgegevens we hebben en wat we daarmee doen. <br /> &bull; Inzage in de precieze persoonsgegevens die we hebben. <br /> &bull; Het laten corrigeren van fouten. <br /> &bull; Het laten verwijderen van verouderde persoonsgegevens. <br /> &bull; Intrekken van toestemming. <br /> &bull; Bezwaar maken tegen een bepaald gebruik.</span></p>
<p><span>Let op dat je altijd duidelijk aangeeft wie je bent en van welk bedrijf, zodat we zeker weten dat we geen gegevens van de verkeerde persoon en/of bedrijf aanpassen of verwijderen.</span></p>
					</div>
			</div>
		</ContentWrapper>
)


export default PrivacyStatement;