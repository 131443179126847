const actions = {
	USER_UPDATE: 'USER_UPDATE',
	USER_UPDATE_SUCCESS: 'USER_UPDATE_SUCCESS',
	SET_USER_PROFILE : 'SET_USER_PROFILE',
	FETCH_USER_PROPERTIES : 'FETCH_USER_PROPERTIES',
	SET_USER_PROPERTIES: 'SET_USER_PROPERTIES',
	USER_ADD: 'USER_ADD',
	SUB_USER_UPDATE : 'SUB_USER_UPDATE',
	USER_ADD_SUCCESS : 'USER_ADD_SUCCESS',
	SET_SUB_USERS : 'SET_SUB_USERS',
	GET_SUB_USERS : 'GET_SUB_USERS',
	REMOVE_LOGO : 'REMOVE_LOGO',
	SET_ALL_SUB_USERS : 'SET_ALL_SUB_USERS',
	PROCESS_FUELINGHISTORY_PER_USER : 'PROCESS_FUELINGHISTORY_PER_USER',
	PROCESS_PAYMENTHISTORY_PER_USER : 'PROCESS_FUELINGHISTORY_PER_USER',
	UPDATE_SUB_USER_FUELING_DATA : 'UPDATE_SUB_USER_FUELING_DATA',
	PROCESS_SUB_USERS_FOR_ALL_USERS : 'PROCESS_SUB_USERS_FOR_ALL_USERS',
	SET_SUB_USERS_FOR_ALL_USERS : 'SET_SUB_USERS_FOR_ALL_USERS',
	SET_USER_SUB_USERS : 'SET_USER_SUB_USERS',
	PROCESS_USER_SUB_USERS : 'PROCESS_USER_SUB_USERS',
	PARSE_SUB_USERS : 'PARSE_SUB_USERS',
	FAILURE : 'FAILURE',
	USER_REGISTER : 'USER_REGISTER',
	GET_USER_CREDIT : 'GET_USER_CREDIT',
	GET_USER_PAYMENT_HISTORY : 'GET_USER_PAYMENT_HISTORY',
	SET_USER_CREDIT : 'SET_USER_CREDIT',
	SET_USER_PAYMENT_HISTORY : 'SET_USER_PAYMENT_HISTORY',

	failure : () => ({
		type : actions.FAILURE,
	}),

	updateUser: (payload) => ({
	    type: actions.USER_UPDATE,
	    payload: payload
	}),

	processFuelingHistoryPerUser : payload => ({
		type : actions.PROCESS_FUELINGHISTORY_PER_USER,
		payload: payload
	}),

	processPaymentHistoryPerUser : payload => ({
		type : actions.PROCESS_PAYMENTHISTORY_PER_USER,
		payload: payload
	}),

	processUserSubUsers : payload => ({
		type: actions.PROCESS_USER_SUB_USERS,
		payload : payload
	}),

	setUserSubUsers : payload => ({
		type: actions.SET_USER_SUB_USERS,
		payload : payload
	}),

	userRegister : payload => ({
		type : actions.USER_REGISTER,
		payload : payload
	}),


	processFuelingHistoryPerUserAllUsers : payload => ({
		type : actions.PROCESS_FUELINGHISTORY_PER_USER_ALL_USERS,
		payload: payload
	}),

	addUser: (payload) => ({
	    type: actions.USER_ADD,
	    payload: payload
	}),

	updateSubUser : (payload) => ({
		type: actions.SUB_USER_UPDATE,
		payload: payload
	}),

	setUserProperties: (payload) => ({
		type: actions.SET_USER_PROPERTIES,
		payload: payload
	}),

	setSubUsers: (payload) => ({
		type: actions.SET_SUB_USERS,
		payload: payload
	}),
	parseSubUsers : (payload) => ({
		type: actions.PARSE_SUB_USERS,
		payload: payload
	}),

	setAllSubUsers : (payload) => ({
		type: actions.SET_ALL_SUB_USERS,
		payload: payload
	}),

	userUpdated: (payload) => ({
	    type: actions.USER_UPDATE_SUCCESS,
	    payload: payload
	}),

	userAdded: (payload) => ({
	    type: actions.USER_ADD_SUCCESS,
	    payload: payload
	}),

	setUserProfile: (payload) => ({
		type: actions.SET_USER_PROFILE,
		payload: payload,
	}),

	removeLogo: () => ({
		type: actions.REMOVE_LOGO
	}),





};

export default actions;
