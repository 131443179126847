import actions from './actions';

const initState = { data: null };

export default function userTypeReducer(state = initState, action) {
	switch (action.type) {
		case actions.SET_USER_TYPE:
			return { 
				...state, 
				data: {					
					...action.payload
				}
			};			
		case 'LOGOUT':
			return initState;
		default:
			return state;
	}
}
