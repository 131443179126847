import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Table from '../../Table/Table';
import { NavLink } from 'react-router-dom';
import { formatMoney } from '../../../helpers/utility';
import moment from 'moment';

class UserTable extends Component {

    _parseDate = (date) => {
        if(!date)
            return null;

		let dateArray = date.split('-');
		let returnDate = false;
		if ( dateArray[0].length === 4 ) {
    		returnDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
    	} else {
    		returnDate = moment(date, 'DD-MM-YYYY HH:mm:ss');
    	}

    	return returnDate.format('DD-MM-YYYY HH:mm:ss');

    }

	_getFueling = (userId) => {
        
		if ( this.props.subUsers ) {
            
			if ( this.props.subUsers[userId]) {
				return this.props.subUsers[userId].total_fueling
			}else{
                
				if(this.props.allUsers[userId]) {
					return this.props.allUsers.userId.total_fueling
				}
			}
		}
		return 0;
	}

	_getFuelingYTD = (userId) => {
		if ( this.props.subUsers ) {
			if ( this.props.subUsers[userId]) {
				return this.props.subUsers[userId].total_fueling_ytd
			}else{
				if(this.props.allUsers[userId]) {
                    
					return this.props.allUsers[userId].total_fueling_ytd
				}
			}
		}


		return 0;
    }
  
    render() {
      
        const columns = [
            {
                Header: 'ID',
                accessor: 'user_type_value', // String-based value accessors!,
                Cell: props => <span className='number'>{(props.original.usage_type_value == 'small' ? 'KV' :  'GV') + (props.original.id ? props.original.id : '')} </span> // Custom cell components!
            },
            {
                Header: 'Type',
                accessor: 'user_type_type' // String-based value accessors!
            },{
                Header: 'Bedrijfsnaam',
                accessor: 'company' // String-based value accessors!
            },
            {
                Header: 'Klantnummer',
                accessor: 'id' // String-based value accessors!
            }, {
                Header: 'Naam',
                id: 'firstname',
                accessor: 'firstname',
                Cell: props => <span className='number'>{props.original.firstname + ' '+ props.original.lastname} </span> // Custom cell components!
            }, {
                Header: 'Telefoonnummer',
                accessor: 'phone_number' // String-based value accessors!
            },
            {
            	Header : 'E-mailadres',
            	accessor: 'email',
            },{
                Header: 'Postcode',
                accessor: 'zipcode' // String-based value accessors!
            },{
                Header: 'Saldo',
                accessor: 'credit', // String-based value accessors!
                Cell: props => <span className='number'>{props.value ? formatMoney(props.value) : formatMoney(0)}</span>,
                sortMethod: (a, b) => {
                	a = parseFloat(a);
                	b = parseFloat(b);
                    if (a === b) {
                      return a > b ? 1 : -1;
                    }
                    return a > b ? 1 : -1;
                  }
            }, {
                Header: 'Pincode',
                accessor: 'tancode'
            },{
                Header: 'Pincode geldig tot',
                accessor: 'is_valid_till.date',
                Cell: props => this._parseDate(props.value),
                sortMethod: (a, b) => {
            		a = parseInt(moment(a, 'DD-MM-YYYY HH:mm:ss').toDate().getTime());
            		b = parseInt(moment(b, 'DD-MM-YYYY HH:mm:ss').toDate().getTime())
                    if (a === b) {
                      return a > b ? 1 : -1;
                    }
                    return a > b ? 1 : -1;
                  }
            },
            {
                Header : 'Aangemaakt op',
                accessor: 'created_at.date',
                Cell: props => this._parseDate(props.value),
                sortMethod: (a, b) => {
            		a = parseInt(moment(a, 'DD-MM-YYYY HH:mm:ss').toDate().getTime());
            		b = parseInt(moment(b, 'DD-MM-YYYY HH:mm:ss').toDate().getTime())
                    if (a === b) {
                      return a > b ? 1 : -1;
                    }
                    return a > b ? 1 : -1;
                  }
            }

        ];


        if( this.props.displayUsage ) {
        	columns.push({
                Header: 'Totale afname',
                accessor: 'total_fueling',
                Cell: props => <span className='number'>{/*this._getFueling(props.original.firebase_user_id)*/}{props.value ? props.value : 0} liter</span>,
                sortMethod: (a, b) => {
                    if (a === b) {
                      return a > b ? 1 : -1;
                    }
                    return a > b ? 1 : -1;
                  } // String-based value accessors!
            },
            {
                Header: 'Totale afname (YTD)',
                accessor: 'total_fueling_ytd',
                Cell: props => <span className='number'>{/*this._getFuelingYTD(props.original.firebase_user_id)*/}{props.value ? props.value : 0} liter</span>,
                sortMethod: (a, b) => {
                    if (a === b) {
                      return a > b ? 1 : -1;
                    }
                    return a > b ? 1 : -1;
                  }  // String-based value accessors!
            })

        }

        if(this.props.displayParentUser && this.props.allUsers) {
        	columns.push({
                Header: 'Aangemaakt door',
                accessor: 'parent_user.id',
               	Cell: props => <span className='number'>{props.original.parent_user ? <NavLink to={"/edit_user/" + props.original.parent_user.id}>{this.props.allUsers[props.original.parent_user.id]['firstname'] + ' ' + this.props.allUsers[props.original.parent_user.id]['lastname']} </NavLink> : ''}</span>
            })
        }

        if(this.props.displayEditRoute) {
        	columns.push({
                Header: 'Acties',
                id: 'actions',
                Cell: props => <NavLink to={"/edit_user/" + props.original.firebase_user_id}><i className="fa fa-edit"></i></NavLink> // Custom cell components!
            });
        }



        if ( this.props.data !== undefined ) {
        	return (
	            <Table data={this.props.data ? Object.values(this.props.data) : []} columns={columns} />
	        );
        }

        return <Table data={[]} columns={columns} />

    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        allUsers: state.User.all_users,
        subUsers: state.User.sub_users
    }
}



export default withRouter(connect(mapStateToProps)(UserTable))
