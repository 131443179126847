const actions = {
	FETCH_FUELING_LOCATIONS : 'FETCH_FUELING_LOCATIONS',
	SET_FUELING_LOCATIONS : 'SET_FUELING_LOCATIONS',
	PROCESS_FUELING_LOCATIONS : 'PROCESS_FUELING_LOCATIONS',
	INITIALIZE_FUELING : 'INITIALIZE_FUELING',
	SET_FUELING_ACTION_PENDING : 'SET_FUELING_ACTION_PENDING',
	SET_PIN_POPUP_VISIBLE : 'SET_PIN_POPUP_VISIBLE',
	SEND_PIN : 'SEND_PIN',
	SET_FUELING_HISTORY : 'SET_FUELING_HISTORY',
	FETCH_FUELING_HISTORY : 'FETCH_FUELING_HISTORY',
	PROCESS_FUELING_HISTORY : 'PROCESS_FUELING_HISTORY',
	CANCEL_FUELING_ACTION : 'CANCEL_FUELING_ACTION',
	FAILURE : 'FAILURE',
	GET_INVOICE_FILE : 'GET_INVOICE_FILE',
	SET_INVOICE_WORKING : 'SET_INVOICE_WORKING',
	SET_INVOICE_FILE_LINK : 'SET_INVOICE_FILE_LINK',
	
	failure : () => ({
		type : actions.FAILURE,
	}),
	
	initializeFueling : (payload) => ({
		type: actions.INITIALIZE_FUELING,
		payload: payload
	}),

	sendPin : (payload) => ({
		type: actions.SEND_PIN,
		payload: payload
	}),

	setFuelingLocations : (payload) => ({
		type: actions.SET_FUELING_LOCATIONS,
		payload: payload
	}),

	setPinPopupVisible : (payload) => ({
		type: actions.SET_PIN_POPUP_VISIBLE,
		payload: payload
	}),

	setFuelingHistory : (payload) => ({
		type: actions.SET_FUELING_HISTORY,
		payload: payload
	}),

	processFuelingHistory : (payload) => ({
		type: actions.PROCESS_FUELING_HISTORY,
		payload: payload
	}),

	cancelFueling : (payload) => ({
		type: actions.CANCEL_FUELING_ACTION,
		payload: payload
	}),

	getInvoiceFile : ( payload ) => ({
		type : actions.GET_INVOICE_FILE,
		payload : payload
	})
};

export default actions;
