import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ContentWrapper from '../../Layout/ContentWrapper';
import { hasError } from '../../../helpers/formHelper';
import {
    Card,
    CardBody,
    } from 'reactstrap';


import { toast } from 'react-toastify';
import actions from '../../../redux/credit/actions';
import moment from 'moment';
import Table from '../../Table/Table';
import { formatMoney } from '../../../helpers/utility';
import { Link } from 'react-router-dom';
import PaymentHistoryTable from './PaymentHistoryTable';
import FirebaseHelper from '../../../helpers/firebase';

const { rsfFirestore,firebase } = FirebaseHelper;

class PaymentHistorySub extends Component {

	constructor(props) {
		super(props);

		this.state = {
            user_credit: false,
            paymentHistory : [],
            self: true
		}
        this.items = [];
		let paymentHistory = [];
	}


    componentDidUpdate(prevProps, prevState) {

        if ( this.props.match.params ) {
    		if (this.props.match.params.type) {
				
    			if ( this.props.match.params.type === 'sub' ) {

    				if (prevProps.subUsers != this.props.subUsers && this.state.paymentHistory.length <= 0) {

                       this._setPaymentHistory();
					}

    			}else {
    				if ( this.props.allUsers !== prevProps.allUsers ) {
                        if( Object.keys(this.props.allUsers).length ) {
                            this._setPaymentHistory();
                        }

    				}
    			}
    		}
		}

	}

    _setPaymentHistory = () => {

    	let userObject = this.props.user;
        let self = true;

    	if ( this.props.match.params ) {

    		switch (this.props.match.params.type) {
    			case 'all':
					userObject = this.props.allUsers;
					console.log(userObject)
					this._getPaymentHistory(userObject);
					self = false;
    			break;

    			case 'small':
    				userObject = this.props.allUsers;
					this._getPaymentHistory(userObject, 'small');
					self = false;
    			break;

    			case 'large':
    				userObject = this.props.allUsers;
					this._getPaymentHistory(userObject, 'large');
					self = false;
    			break;

    			case 'sub':
					userObject = this.props.subUsers;
					this._getPaymentHistory(userObject);
					self = false;
    			break;
    		}
    	}

    	this.setState({
			userObject : userObject,
			self : self
    	})
    }

    _getPaymentHistory(users, type = false)
	{
        let items = [];
         for ( let userID in users ) {
    	 	if ( users.hasOwnProperty(userID) ) {
    	 		let user = users[userID];

    	        if ( user.paymentHistory ) {
                    user.paymentHistory.forEach((item, i) => {
                        items.push(item);
                    });

                }

    		}
    	}

        this.setState({
			paymentHistory : items
    	})



	}

    render() {
		
        return (

            <ContentWrapper>
                <div className="content-heading">
                    <div>Betaalgeschiedenis</div>
                </div>

                <div className="row">
                 	<div className="col-md-12">
                        <Card className="card-default">
                            <CardBody>
								{this.state.paymentHistory ?  <PaymentHistoryTable  self={this.state.self} data={this.state.paymentHistory ? this.state.paymentHistory : []} /> : null }
                            </CardBody>
                        </Card>
                    </div>
               	</div>
            </ContentWrapper>


            );
    }

}

const mapStateToProps = (state, ownProps) => {

    return {
        user: state.User.user_profile ? state.User.user_profile : false,
        dbProfile: state.User.db_profile,
        userType: state.UserType,
        credit: state.Credit,
        paymentHistory: state.Credit.paymentHistory ? state.Credit.paymentHistory : false,
        allUsers: state.User.all_users,
        subUsers: state.User.sub_users
    }
}


export default withRouter(connect(mapStateToProps)(PaymentHistorySub))
