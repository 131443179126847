
import React, { Component } from 'react';
import { HashRouter } from 'react-router-dom';
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { history } from './redux/store';

// App Routes
import Routes from './Routes';

// Vendor dependencies
import "./Vendor";
// Application Styles
import './styles/bootstrap.scss';
import './styles/app.scss'
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import Boot from "./redux/boot";


class App extends Component {

	componentWillMount() {
		Boot();
	}


    
    render() {

    const basename = process.env.NODE_ENV === 'development' ? '/' : '/';

    return (
    	<Provider store={store}>
    		<ToastContainer />
	        <HashRouter basename={basename} history={history}>
	            <Routes />
	        </HashRouter>
        </Provider>
    );

  }
}

export default App;
