import React, { Component } from 'react';
import { Trans } from 'react-i18next';
import { Row, Col } from 'reactstrap';
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer
} from 'recharts';


class CreditStatistics extends Component {
    render() {
    	if ( this.props.statistics ) {
	    	if ( this.props.statistics.length ) {
		        return (
                    <Col xl={ 6 }>
                        <Row>
                            <Col xl={ 12 }>
                                <div className="card card-default">
                                    <div className="card-header">
                                        <div className="card-title"><Trans i18nKey='dashboard.CREDIT_STATISTICS'></Trans></div>
                                    </div>
                                    <div className="card-body">
										<ResponsiveContainer height={500} width={"100%"}>
											<LineChart  >
											<CartesianGrid strokeDasharray="1 1"/>
											<XAxis dataKey="key" />
											<YAxis dataKey="value" />
											
											<Line isAnimationActive={true} dataKey="value" data={this.props.statistics} />
											</LineChart>
										</ResponsiveContainer>   
                                    </div>
                                </div>
                            </Col>
                          
                        </Row>
                    </Col>
		        );
		    }
		}
		return null;
    }
}

export default CreditStatistics;
