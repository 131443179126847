import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { FormGroup, Input } from 'reactstrap';
import 'react-rangeslider/lib/index.css'
import fuelingActions  from '../../../redux/fueling/actions';

const { sendPin } = fuelingActions;
const { cancelFueling } = fuelingActions;

class PinPopup extends Component {

    constructor(props) {
        super(props);

        this.state = {
        	pin : false
        }
    }

    _updateField = (field, event) => {
    	this.setState({
    		[field] : event.target.value

    	})
    }

    _formSubmit = (e) => {
    	e.preventDefault();
    	this.props.sendPin(this._getFormData());
    }

    _cancelFuelingAction = (e) => {
        e.preventDefault();
        this.props.cancelFueling(this._getFormData());
    }

    _getFormData = () => {
        let formData = new FormData();
        formData.append('location_id', this.props.locationId);
        formData.append('pin', this.state.pin);
        formData.append('firebase_user_id', this.props.firebase_user_id);
        formData.append('api_address', this.props.api_address);

        return formData;
    }

    render() {

        return (
            <div className="popup__backdrop">
            	<div className="popup__wrapper" style={{maxWidth: '480px', width: '100%'}}>
            		<div className="popup__content">
            			<form onSubmit={this._formSubmit}>
                            <FormGroup>
                                <label>Type de code over van het display</label>
                               	<Input onChange={(event) => this._updateField('pin', event)} type="number" placeholder="PIN code" value={this.state.pin}/>
                            </FormGroup>

                            <FormGroup>
                               <button className="btn btn-sm btn-success float-right" type="submit" disabled={this.state.pin <= 0 ? 'disabled' : null}>Tankactie starten</button>
                               <span onClick={(event) => this._cancelFuelingAction(event)} className="btn btn-sm btn-danger float-left" >Tankactie annuleren</span>
                            </FormGroup>
                        </form>
            		</div>
            	</div>
            </div>
            );
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        sendPin: (data) => dispatch(sendPin(data)),
        cancelFueling: (data) => dispatch(cancelFueling(data))
    };
};

const mapStateToProps = (state, ownProps) => {

    return {
       	locations : state.Fueling.fuelingLocations,
       	routes: state.Auth.routes,
       	user: state.User.user_profile ? state.User.user_profile : false,
        dbProfile: state.User.db_profile,
        userType: state.UserType,
        fuelingActionPending : state.Fueling.fuelingActionPending,
        pinPopupVisible : state.Fueling.pinPopupVisible
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PinPopup))
