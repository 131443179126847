import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Input, CustomInput, FormText } from 'reactstrap';
import FormValidator from '../../Forms/FormValidator.js';
import mainLogo from '../../../assets/img/ew-supply-logo.png';
import userActions from '../../../redux/user/actions';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

const { userRegister } = userActions;

const url = window.location.host.split(':');

if(url[0] === 'localhost') {
    localStorage.setItem('clientPath','http://localhost:8000'); 
} else {
    localStorage.setItem('clientPath','https://' + window.location.host.replace('www', '') + '/api/public');
}


class Register extends Component {

    state = {
        formRegister: {
            email: '',
            password: '',
            terms: false
        }
    }

     /**
      * Validate input using onChange event
      * @param  {String} formName The name of the form in the state object
      * @return {Function} a function used for the event
      */
    validateOnChange = event => {
        const input = event.target;
        const value = input.type === 'checkbox' ? input.checked : input.value;

        const result = FormValidator.validate(input);

        this.setState({
            formRegister: {
                ...this.state.formRegister,
                [input.name]: value,
                errors: {
                    ...this.state['formRegister'].errors,
                    [input.name]: result
                }
            }
        });

    }

    onSubmit = e => {
        const form = e.target;
        const inputs = [...form.elements].filter(i => ['INPUT', 'SELECT'].includes(i.nodeName))

        const { errors, hasError } = FormValidator.bulkValidate(inputs)

        this.setState({
            [form.name]: {
                ...this.state[form.name],
                errors
            }
        });

        
        e.preventDefault()

        const data = this.state.formRegister;

        this.props.userRegister(data);

        
    }

    /* Simplify error check */
    hasError = (formName, inputName, method) => {
        return  this.state[formName] &&
                this.state[formName].errors &&
                this.state[formName].errors[inputName] &&
                this.state[formName].errors[inputName][method]
    }

    render() {

        return (
            <div className="block-center mt-4 wd-xl">
                {/* START card */}
                <div className="card card-flat">
                    <div className="card-header text-center bg-light" style={{backgroundColor: 'white'}}>
                        <a href="/">
                            <img style={{maxWidth : '100%'}} className="block-center" src={mainLogo} alt="Logo"/>
                        </a>
                    </div>
                    <div className="card-body">
                        <form className="mb-3" name="formRegister" onSubmit={this.onSubmit}>
                        	<div className="form-group">
                              
                                <div className="input-group with-focus">
                                    <Input type="text"
                                        name="name"
                                        className="border-right-0"
                                        placeholder="Voornaam"
                                        invalid={this.hasError('formRegister','name','required')||this.hasError('formRegister','name','text')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formRegister.name}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-user"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formRegister','name','required') && <span className="invalid-feedback">Veld mag niet leeg zijn</span> }
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="text"
                                        name="lastname"
                                        className="border-right-0"
                                        placeholder="Achternaam"
                                        invalid={this.hasError('formRegister','lastname','required')||this.hasError('formRegister','lastname','text')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formRegister.lastname}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-user"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formRegister','lastname','required') && <span className="invalid-feedback">Veld mag niet leeg zijn</span> }
                                </div>
                            </div>

                             <div className="form-group">
                                <div className="input-group with-focus">
                                    <Input type="text"
                                        name="company"
                                        className="border-right-0"
                                        placeholder="Bedrijf"
                                        invalid={this.hasError('formRegister','company','required')||this.hasError('formRegister','company','text')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formRegister.company}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-user"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formRegister','company','required') && <span className="invalid-feedback">Veld mag niet leeg zijn</span> }
                                </div>
                            </div>

                            <div className="form-group">
                               
                                <div className="input-group with-focus">
                                    <Input type="text"
                                        name="phone"
                                        className="border-right-0"
                                        placeholder="Telefoonnummer"
                                        invalid={this.hasError('formRegister','phone','required')||this.hasError('formRegister','phone','number')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "number"]'
                                        value={this.state.formRegister.phone ? this.state.formRegister.phone : "+31"  }/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-phone"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formRegister','phone','required') && <span className="invalid-feedback">Veld mag niet leeg zijn</span> }
                                    { this.hasError('formRegister','phone','number') && <span className="invalid-feedback">Moet een geldig nummer zijn</span> }
                                </div>
                            </div>
                            <div className="form-group">
                         
                                <div className="input-group with-focus">
                                    <Input type="text"
                                        name="city"
                                        className="border-right-0"
                                        placeholder="Plaats"
                                        invalid={this.hasError('formRegister','name','required')||this.hasError('formRegister','name','text')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formRegister.city}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-building"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formRegister','city','required') && <span className="invalid-feedback">Veld mag niet leeg zijn</span> }
                                </div>
                            </div>
                            <div className="form-group">
                         
                                <div className="input-group with-focus">
                                    <Input type="text"
                                        name="zipcode"
                                        className="border-right-0"
                                        placeholder="Postcode"
                                        invalid={this.hasError('formRegister','zipcode','required')||this.hasError('formRegister','zipcode','text')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formRegister.zipcode}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-building"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formRegister','zipcode','required') && <span className="invalid-feedback">Veld mag niet leeg zijn</span> }
                                </div>
                            </div>
                            <div className="form-group">
                            
                                <div className="input-group with-focus">
                                    <Input type="email"
                                        name="email"
                                        className="border-right-0"
                                        placeholder="E-mailadres"
                                        invalid={this.hasError('formRegister','email','required')||this.hasError('formRegister','email','email')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required", "email"]'
                                        value={this.state.formRegister.email}/>
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-envelope"></em>
                                        </span>
                                    </div>
                                    { this.hasError('formRegister','email','required') && <span className="invalid-feedback">Veld mag niet leeg zijn</span> }
                                    { this.hasError('formRegister','email','email') && <span className="invalid-feedback">Geen geldig e-mail adres</span> }
                                </div>
                            </div>
                            <div className="form-group">
                              
                                <div className="input-group with-focus">
                                    <Input type="password"
                                        id="id-password"
                                        name="password"
                                        className="border-right-0"
                                        placeholder="Wachtwoord"
                                        invalid={this.hasError('formRegister','password','required')}
                                        onChange={this.validateOnChange}
                                        data-validate='["required"]'
                                        value={this.state.formRegister.password}
                                    />
                                    <div className="input-group-append">
                                        <span className="input-group-text text-muted bg-transparent border-left-0">
                                            <em className="fa fa-lock"></em>
                                        </span>
                                    </div>
                                    <span className="invalid-feedback">Veld is verplicht</span>
                                </div>
                            </div>
                            <CustomInput type="checkbox" id="terms"
                                name="terms"
                                label="Ik ga akkoord met de algemene voorwaarden"
                                invalid={this.hasError('formRegister','terms','required')}
                                onChange={this.validateOnChange}
                                data-validate='["required"]'
                                checked={this.state.formRegister.terms}>
                                    <span className="invalid-feedback">Veld is verplicht</span>
                                </CustomInput>
                                
                            <button className="btn btn-block btn-primary mt-3" type="submit">Account aanmaken</button>
                        </form>
                        <p className="pt-3 text-center">Heeft u al een account?</p>
                        <Link to="/login" className="btn btn-block btn-secondary">Login</Link>
                        <FormText><center><Link to="/algemene-voorwaarden">Algemene voorwaarden</Link></center></FormText>
                    </div>
                </div>
            </div>
        );
    }
}




const mapDispatchToProps = (dispatch) => {
    return {
        userRegister: (data) => dispatch(userRegister(data)),
    };
};

export default withRouter(connect(false, mapDispatchToProps)(Register))
