import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import actions from './actions';
import FirebaseHelper from '../../helpers/firebase';
import { getToken } from '../../helpers/utility';
const { rsfFirestore, processFireStoreCollection} = FirebaseHelper;

export function* syncUserTypeCollection() {
	const localUserData = getToken();

	const user_id = localUserData.get('userId');
	

	if ( user_id ) {
		
  		yield fork(rsfFirestore.syncCollection, 'user_types', { successActionCreator: (snap) => actions.setUserTypes(processFireStoreCollection(snap)), failureActionCreator : () => actions.failure() });
  	}
}


export function* fetchUserTypes() {
	yield takeEvery(actions.FETCH_USER_TYPE, function*(action) {	
	
		const snapshot = yield call(rsfFirestore.getCollection, 'user_types');
		let data = processFireStoreCollection(snapshot);
		if(data) {
			yield put({
	      	type: actions.SET_USER_TYPE,
	        payload: data
	      });
		}
	});
}
export function* failure() {
	yield takeEvery(actions.FAILURE, function*(action) {

	
	
	})
}

export default function* rootSaga() {
	yield all([
		fork(fetchUserTypes),
		fork(syncUserTypeCollection),
		fork(failure)
	]);
}
