import Auth from './auth/reducer';
import User from './user/reducer';
import UserType from './userType/reducer';
import Credit from './credit/reducer';
import Location from './location/reducer';
import Fueling from './fueling/reducer';
export default {
	Auth,
	User,
	UserType,
	Credit,
	Location,
	Fueling
};
