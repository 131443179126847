import { Map } from 'immutable';

export function clearToken() {	
  localStorage.removeItem('user_id');
  localStorage.removeItem('user_profile');

}


export function clearStorage() {	
	localStorage.removeItem('user_id');
	localStorage.removeItem('user_profile');
	
  }

export function getToken() {
  try {
    const userId = localStorage.getItem('user_id');

    const userProfile = JSON.parse(localStorage.getItem('user_profile'));
      
    return new Map({ userId, userProfile });
  } catch (err) {

  	//console.log(err);
  	//console.log("CLEARING FROM GETTOKEN");
    //clearToken();
    return new Map();
  }
}

export function getClientPath() {
  try {
    return localStorage.getItem('clientPath');
  } catch (err) {
    
    return false;
  }
}

export function formatMoney(value) {
	

	const formatter = new Intl.NumberFormat('nl-NL', {
	  style: 'currency',
	  currency: 'EUR',
	  
	})

	return formatter.format(parseFloat(value));
}

export function getReference(referenceObject) {
	return new Promise((resolve, reject) => {
		referenceObject.get()
		.then(function(response) {
			return response.data();
		}).then(function(response) {
			resolve(response);
		});	
	}) 


	/*return new Promise((resolve, reject) => {
		const user = FirebaseHelper.auth().currentUser;
		user.updatePassword(newPassword).then((result) => {
			
		    resolve(result);
		}).catch((err) => {
			toast(errorCodes[err.code]['message'], {
		        type: errorCodes[err.code]['type'],
		        position: 'top-right'
		    })
		});
	});*/
}